.package_boxsection {
    width: 95%;
    box-shadow: 0 0 5px 1px #dadada;
    border-radius: 5px;
    margin-bottom: 20px;
    font-family: Montserrat;
    justify-content: space-around; 
    .leftbox {
        width: 70%;
        min-width: 280px;
        padding: 10px;
        box-sizing: border-box;
        .image {
            height: 130px;
            width: 130px;
            border-radius: 5px;
            border: 1px solid #979797;
            margin: auto;
        }
        .coldet_box {
            min-width: 200px;
            width: calc(100% - 150px);
            margin: 10px auto;
            padding-left: 10px;
            box-sizing: border-box;
        }
    }
}
.package {
    padding-top: 80px;
    overflow-x: hidden;

    .welcome_container {
        width: 100%;
        height: 250px;
        background-image: linear-gradient(to right, #FF5722, #CE3407);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;

        .decor {
            width: 220px;
            height: 110px;
            position: absolute;
            top: 0px;
            background-repeat: no-repeat;
            background-size: 80%;
            background-position-y: initial;

            &.left {
                background-position-x: initial;
                background-image: url(../../../assets/decore-left.png);
                left: 0px;
            }

            &.right {
                background-position-x: right;
                background-image: url(../../../assets/decore-right.png);
                right: 0px;
            }
        }

        .box {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .circle {
                background-image: url(../../../assets/badge.png);
                height: 100px;
                width: 100px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: 100%;
            }

            .text {
                font-family: "Quicksand";
                color: #FFFFFF;
                font-weight: bold;
                font-size: 22px;
                margin-top: 10px;
            }

        }
    }

    .header {
        width: 100%;
        text-align: center;
        margin: 50px auto;
        font-family: "Montserrat";
        letter-spacing: 0.3px;
        user-select: none;

        span {
            font-weight: 600;
        }
    }

    .body {
        width: 100%;
        display: flex;
        flex-direction: row;

        @media screen and (max-width: 768px) {
            flex-direction: column;
        }

        .taskbar {
            width: 250px;
            height: 100%;
            padding: 20px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            align-items: center;
            font-family: "Montserrat";
            transition: all 500ms;

            @media screen and (max-width: 768px) {
                margin: auto;
            }

            .header {
                color: #757575;
                font-size: 14px;
                font-weight: 500;
                text-transform: uppercase;
                display: flex;
                flex-direction: row;
                margin: 0px;

                .icon {
                    height: 14px;
                    width: 14px;
                    background-image: url(../../../assets/right-arrow.svg);
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 100%;
                    position: relative;
                    top: 2px;
                    left: 13px;
                }
            }

            .item_box {
                display: flex;
                flex-direction: column;
                padding: 20px 0px;

                .item {
                    text-transform: uppercase;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 30px;
                }
            }

        }

        .data_box {
            width: calc(100% - 250px);
            display: flex;
            flex-direction: column;
            padding: 20px;
            box-sizing: border-box;

            @media screen and (max-width: 768px) {
                width: 100%;
            }

            .top_section {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                // justify-content: space-between;
           

                .box_container {
                    display: flex;
                    flex-direction: column;
                    width: 30%;
                    min-width: 200px;
                    align-items: center;
                    padding: 10px;
                    box-sizing: border-box;

                    @media screen and (max-width: 963px) {
                        flex-direction: row;
                        flex-wrap: wrap;
                        width: 100%;
                    }

                    .box {
                        width: 100%;
                        max-width: 220px;
                        min-width: 200px;
                        padding: 10px;
                        box-sizing: border-box;
                        background-color: #FFFFFF;
                        border: 1px solid #AAAAAA;
                        box-shadow: 0px 0px 10px 3px #cacaca;
                        border-radius: 5px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        font-family: "Montserrat";
                        text-transform: uppercase;
                        margin-bottom: 35px;

                        @media screen and (max-width: 963px) {
                            margin-right: 50px;
                        }

                        &:nth-last-child(1) {
                            @media screen and (max-width: 963px) {
                                margin-right: 0px;
                            }
                        }

                        .header {
                            margin: 10px auto;
                            font-size: 16px;
                            font-weight: 500;
                        }

                        .content {
                            line-height: 20px;
                            margin: 10px 0px;
                        }

                        .button {
                            margin: 10px auto;
                            background-color: #FF5722;
                            border-radius: 100px;
                            color: #FFFFFF;
                            padding: 7px 15px;
                            font-size: 12px;
                            font-weight: 600;

                        }

                        &.second {
                            .icon {
                                height: 60px;
                                width: 60px;
                                margin: 5px auto;
                                background-image: url(../../../assets/engineerICON.svg);
                                background-position: center;
                                background-repeat: no-repeat;
                                background-size: 100%;
                            }

                            .container {
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
                                width: 100%;
                                margin: 10px 0px;
                                align-items: center;

                                .text {
                                    text-transform: none;
                                    font-size: 12px;
                                    width: 70%;
                                    font-weight: 500;

                                }

                                .checkbox {
                                    display: none;
                                }

                                .checkbox+label {
                                    font-size: 13px;
                                    user-select: none;
                                    cursor: pointer;
                                }

                                .checkbox+label:before {
                                    content: "\2714";
                                    // box-shadow: 0px 0px 2px 1px #dadada;
                                    border-radius: 0.2em;
                                    display: inline-block;
                                    height: 7px;
                                    width: 7px;
                                    padding: 0px 6px 10px 4px;
                                    margin-right: 0.7em;
                                    vertical-align: bottom;
                                    color: transparent;
                                    transition: .2s;
                                    background-color: #D8D8D8;
                                }

                                .checkbox+label:active:before {
                                    transform: scale(0);
                                }

                                .checkbox:checked+label {
                                    color: #FF5722;
                                    transition: color 0.2s;
                                }

                                .checkbox:checked+label:before {
                                    background-color: #FF5722;
                                    border-color: #FF5722;
                                    color: #fff;
                                    box-shadow: 0px 0px 0px 0px #dadada;
                                }

                                .checkbox:disabled+label:before {
                                    transform: scale(1);
                                    border-color: #aaa;
                                }

                                .checkbox:checked:disabled+label:before {
                                    transform: scale(1);
                                    background-color: #bfb;
                                    border-color: #bfb;
                                }
                            }
                        }
                    }
                }
            }

            .add_extra_products_container {
                margin: 30px 0px;
                font-family: "Montserrat";
                font-weight: 500;
                font-size: 16px;

                .heading {
                    width: 100%;
                    text-transform: uppercase;
                }

                .data_box_container {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    padding-top: 30px;
                    padding-right: 30px;
                    box-sizing: border-box;
                    flex-wrap: wrap;

                    .data_box {
                        min-width: 400px;
                        width: 45%;
                        height: 300px;
                        margin-bottom: 50px;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        padding: 5px;
                        box-sizing: border-box;

                        &:nth-last-child(1),
                        &:nth-last-child(2) {
                            margin-bottom: 0px;
                        }

                        .image {
                            height: 80px;
                            width: 80px;
                            background-color: #D8D8D8;
                            border-radius: 2px;
                            border: 1px solid #cacaca;
                        }

                        .details_box {
                            display: flex;
                            flex-direction: column;
                            // background-color: cyan;
                            width: calc(100% - 100px);
                            margin-left: 8px;

                            .heading_box {
                                width: 100%;
                                display: flex;
                                flex-direction: row;
                                align-items: center;

                                .content {
                                    display: flex;
                                    flex-direction: column;
                                    padding: 0px 10px;
                                    box-sizing: border-box;
                                    font-weight: 400;
                                    color: #333333;

                                    .main {
                                        text-transform: uppercase;
                                        font-size: 15px;
                                        margin: 5px 0px;
                                    }

                                    .sub {
                                        font-size: 13px;

                                    }
                                }

                                .count {
                                    padding: 10px;
                                    box-sizing: border-box;
                                    text-align: center;
                                    font-size: 17px;
                                }
                            }

                            .varient_container {
                                display: flex;
                                flex-direction: column;
                                // margin: 5px 0px;
                                padding: 5px;
                                box-sizing: border-box;

                                .varient {
                                    display: flex;
                                    flex-direction: row;
                                    justify-content: space-between;
                                    padding: 0px 5px;
                                    box-sizing: border-box;
                                    margin: 8px 0px;

                                    &.gap {
                                        margin-top: 20px;
                                    }

                                    .name {
                                        font-size: 15px;
                                    }

                                    .name_count {
                                        font-size: 16px;
                                        font-weight: 600;
                                        color: #FF5722;
                                    }

                                    .action_box {
                                        display: flex;
                                        flex-direction: row;
                                        align-items: center;

                                        .btn {
                                            width: 20px;
                                            height: 20px;
                                            line-height: 20px;
                                            text-align: center;
                                            color: #FFFFFF;
                                            border-radius: 10px;
                                            background-color: #FF5722;
                                            margin: 0px 4px;
                                            font-weight: 700;
                                        }

                                        .text {
                                            text-transform: uppercase;
                                            margin: 0px 5px;
                                            font-size: 14px;
                                        }

                                        .total {
                                            text-transform: uppercase;
                                            margin: 0px 5px;
                                            font-size: 16px;
                                            color: #6B6C6F;
                                            font-weight: 600;
                                        }

                                        .button {
                                            color: #FF5722;
                                            font-size: 13px;
                                            font-weight: 500;
                                            background-color: #FFFFFF;
                                            border: 1px solid #FF5722;
                                            padding: 2px 10px;
                                            border-radius: 10px;
                                            margin-left: 15px;
                                        }
                                    }


                                }
                            }
                        }
                    }
                }
            }
        }
    }

}

.package_container {
    display: flex;
    flex-direction: row;
    padding: 10px;
    box-sizing: border-box;
    align-items: center;

    .image {
        height: 120px;
        width: 120px;
        min-width: 120px;
        border-radius: 4px;
        background-color: #D8D8D8;
        border: 1px solid #979797;
    }

    .data_container {
        display: flex;
        flex-direction: column;
        padding: 0px 20px;
        box-sizing: border-box;
        margin-top: 5px;

        .top_section {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;

            .button {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 5px 10px;
                box-sizing: border-box;
                background-color: #FF5722;
                border-radius: 2px;
                color: #FFFFFF;
                font-family: "Montserrat";
                font-size: 13px;

                .icon {
                    height: 15px;
                    width: 15px;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 90%;
                    background-image: url(../../../assets/user.svg);
                    margin-right: 5px;
                }
            }

            .text {
                font-family: "Montserrat";
                margin-left: 10px;
                font-weight: 500;
            }
        }

        .content {
            line-height: 20px;
            font-family: "Montserrat";
            font-size: 13px;
            margin-top: 20px;
            color: #333333;
        }

        .button_box {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            font-family: "Montserrat";
            font-size: 13px;
            align-items: center;
            margin-top: 20px;

            .sq_button {
                padding: 8px 18px;
                border: 1px solid #979797;
                border-radius: 2px;
                color: #333333;
                width: 115px;
                margin-right: 15px;
            }

            .rd_button {
                padding: 7px 18px;
                border-radius: 100px;
                background-color: #FF5722;
                color: #ffffff;
                margin: 15px 0px;
            }
        }
    }
}

.package_details {
    display: flex;
    flex-direction: column;
    width: 70%;
    max-width: 70%;
    margin: auto;
    min-width: 380px;

    @media screen and (max-width: 768px) {
        width: 95%;
        max-width: 95%;
        margin: auto;
    }



    .package_include_section {
        display: flex;
        flex-direction: column;

        .header {
            text-transform: uppercase;
            width: 100%;
            display: flex;
            align-items: flex-start;
            font-weight: 500;
        }

        .body_container {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            padding-right: 15px;
            box-sizing: border-box;

            .box {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: 0px 0px 20px 0px;

                .image {
                    height: 60px;
                    width: 60px;
                    min-width: 60px;
                    background-color: #D8D8D8;
                    border: 1px solid #6B6C6F;
                    border-radius: 2px;
                }

                .content {
                    display: flex;
                    flex-direction: column;

                    .text {
                        font-family: "Montserrat";
                        line-height: 20px;
                        padding: 0px 7px;
                        box-sizing: border-box;
                        font-size: 13px;

                        &.header {
                            margin: 0px;
                        }
                    }
                }
            }
        }
    }
}

.add_extra_products_container {
    margin: 30px 0px;
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 16px;

    .heading {
        width: 100%;
        text-transform: uppercase;
    }

    .data_box_container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-top: 30px;
        padding-right: 30px;
        box-sizing: border-box;
        flex-wrap: wrap;

        .data_box {
            min-width: 400px;
            width: 45%;
            height: 300px;
            margin-bottom: 50px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 5px;
            box-sizing: border-box;

            &:nth-last-child(1),
            &:nth-last-child(2) {
                margin-bottom: 0px;
            }

            .image {
                height: 80px;
                width: 80px;
                background-color: #D8D8D8;
                border-radius: 2px;
                border: 1px solid #cacaca;
            }

            .details_box {
                display: flex;
                flex-direction: column;
                // background-color: cyan;
                width: calc(100% - 100px);
                margin-left: 8px;

                .heading_box {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .content {
                        display: flex;
                        flex-direction: column;
                        padding: 0px 10px;
                        box-sizing: border-box;
                        font-weight: 400;
                        color: #333333;

                        .main {
                            text-transform: uppercase;
                            font-size: 15px;
                            margin: 5px 0px;
                        }

                        .sub {
                            font-size: 13px;

                        }
                    }

                    .count {
                        padding: 10px;
                        box-sizing: border-box;
                        text-align: center;
                        font-size: 17px;
                    }
                }

                .varient_container {
                    display: flex;
                    flex-direction: column;
                    // margin: 5px 0px;
                    padding: 5px;
                    box-sizing: border-box;

                    .varient {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        padding: 0px 5px;
                        box-sizing: border-box;
                        margin: 8px 0px;

                        &.gap {
                            margin-top: 20px;
                        }

                        .name {
                            font-size: 15px;
                        }

                        .name_count {
                            font-size: 16px;
                            font-weight: 600;
                            color: #FF5722;
                        }

                        .action_box {
                            display: flex;
                            flex-direction: row;
                            align-items: center;

                            .btn {
                                width: 20px;
                                height: 20px;
                                line-height: 20px;
                                text-align: center;
                                color: #FFFFFF;
                                border-radius: 10px;
                                background-color: #FF5722;
                                margin: 0px 4px;
                                font-weight: 700;
                            }

                            .text {
                                text-transform: uppercase;
                                margin: 0px 5px;
                                font-size: 14px;
                            }

                            .total {
                                text-transform: uppercase;
                                margin: 0px 5px;
                                font-size: 16px;
                                color: #6B6C6F;
                                font-weight: 600;
                            }

                            .button {
                                color: #FF5722;
                                font-size: 13px;
                                font-weight: 500;
                                background-color: #FFFFFF;
                                border: 1px solid #FF5722;
                                padding: 2px 10px;
                                border-radius: 10px;
                                margin-left: 15px;
                            }
                        }


                    }
                }
            }
        }
    }
}

.varientme {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 5px;
    box-sizing: border-box;
    margin: 8px 0;
    .name {
        font-size: 15px;
    }
    .action_box {
        display: flex;
        flex-direction: row;
        align-items: center;

        .btn {
            width: 20px;
            height: 20px;
            line-height: 20px;
            text-align: center;
            color: #FFFFFF;
            border-radius: 10px;
            background-color: #FF5722;
            margin: 0px 4px;
            font-weight: 700;
        }

        .text {
            text-transform: uppercase;
            margin: 0px 5px;
            font-size: 14px;
        }

        .total {
            text-transform: uppercase;
            margin: 0px 5px;
            font-size: 16px;
            color: #6B6C6F;
            font-weight: 600;
        }

        .button {
            color: #FF5722;
            font-size: 13px;
            font-weight: 500;
            background-color: #FFFFFF;
            border: 1px solid #FF5722;
            padding: 2px 10px;
            border-radius: 10px;
            margin-left: 15px;
        }
    }
}

.mybtn{
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    color: #fff;
    border-radius: 10px;
    background-color: #ff5722;
    margin: 0 4px;
    font-weight: 700;
    cursor: pointer;
}
@import url("https://fonts.googleapis.com/css?family=Montserrat+Alternates:400,500,700|Montserrat:400,500,600,700|Quicksand:400,500,700|Roboto:400,500,700");
/* * {
  outline: none;
  user-select: none;
  
  
} */
/* * { 
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
} */
input, textarea {
  -webkit-user-select: text !important;
  -khtml-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important;
  user-select: text !important;
}
select {
  font-weight: 500;
  padding: 4px 10px;
  background-color: #FFFFFF;
  border-radius: 3px;
  cursor: pointer;
}
.package .body .data_box .top_section .package_details .package_container .data_container .button_box .sq_button::-webkit-inner-spin-button, 
.package .body .data_box .top_section .package_details .package_container .data_container .button_box .sq_button::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
}

::-webkit-scrollbar-track {
  background-color: #ffffff;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: #ffffff;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #919191;
}

.checkout3 .checkout_container .text, .checkout3 .payment_container .text, .checkout2 .checkout_container .text, .checkout2 .payment_container .text, .checkout1 .checkout_container .text, .checkout1 .payment_container .text {
  font-weight: 500;
  font-family: "Montserrat";
  font-size: 19px;
}

body {
  margin: 0px;
  padding: 0px;
  background-color: #FFFFFF;
height: 100%;
}

#container {
  height:100%;
  min-height:100%;
  position:relative;
}

.nav_bar {
  height: 80px;
  width: 100%;
  background-color: #FFFFFF;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow: hidden;
  -webkit-box-shadow: 0px 0px 3px 1px #bababa;
          box-shadow: 0px 0px 3px 1px #bababa;
  position: fixed;
  z-index: 100;
}

.nav_bar .left_section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.nav_bar .left_section .logo {
  height: 55px;
  width: 80px;
  /* background-color: #FF5722; */
  margin-right: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.nav_bar .left_section .logo .icon {
  height: 10px;
  width: 10px;
  border: 2px solid #FFFFFF;
  border-radius: 50%;
  background-color: #FF5722;
  position: relative;
  overflow: hidden;
}

.nav_bar .left_section .logo .icon::after {
  content: '';
  position: absolute;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background-color: #FFFFFF;
  top: 2px;
  left: 2px;
  -webkit-clip-path: inset(0px 2.5px 0px 0px);
          clip-path: inset(0px 2.5px 0px 0px);
}

.nav_bar .left_section .text {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.2px;
}

.nav_bar .right_section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-weight: 500;
}
.nav_bar .right_section_mob {
  display: none;
}

@media screen and (max-width: 768px) {
  .nav_bar .right_section {
    display: none;
  }
  .nav_bar .right_section_mob {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-weight: 500;
  }
  .right_section_mob .item {
    /* margin-right: 20px; */
    font-family: "Montserrat";
    font-size: 20px;
    letter-spacing: 0.1px;
    color: #222222;
    font-weight: 500;
    cursor: pointer;
    text-align: center;
    margin-top: 15px;
  }
  
  .right_section_mob .button {
    cursor: pointer;
    -webkit-user-select: non;
       -moz-user-select: non;
        -ms-user-select: non;
            user-select: non;
    padding: 5px 10px;
    background-color: #FF5722;
    color: #FFFFFF;
    font-family: "Montserrat";
    border-radius: 50px;
    font-size: 20px;
    text-align: center;
    /* margin-left: 50px;
    margin-right: 50px; */
    width: fit-content;
    margin: auto;
    margin-top: 15px;

  }
  .toggle_button {
    width: 28px;
    height: 30px;
    margin: 10px auto;
  }
  .toggle_button_div {
    width: 100%;
    height: 4px;
    background: rgb(255, 87, 34);
    margin: 4px auto;
    transition: all 0.3s;
    backface-visibility: hidden;
    
  }
  .toggle_button_div_2 {
    width: 100%;
    height: 4px;
    background: rgb(255, 87, 34);
    margin: 4px auto;
    transition: all 0.3s;
    backface-visibility: hidden;
    transform: rotate(45deg) translate(5px, 5px);
  }
  .toggle_button_div_3 {
    opacity: 0;
  }
  .toggle_button_div_4 {
    width: 100%;
    height: 4px;
    background: rgb(255, 87, 34);
    margin: 4px auto;
    transition: all 0.3s;
    backface-visibility: hidden;
    transform: rotate(-45deg) translate(1.5px, -1px);
  }
  .handbar_div {
 
    /* height: calc(100% - 80px); */
    width: 50%;
    padding: 20px 10px 40px 10px;
    box-sizing: border-box;
    box-shadow: inset 0px 0px 2px 1px #dadada;
    z-index: 999;
    border-bottom-left-radius:7px; 
    min-width: 250px;
    position: fixed;
    top: 80px;
    right: 0px;
    background-color: white;
    /* transition: all 1s; */

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-weight: 500;
  }
}

.nav_bar .right_section .item {
  margin-right: 20px;
  font-family: "Montserrat";
  font-size: 14px;
  letter-spacing: 0.1px;
  color: #222222;
  /* font-weight: 500; */
  cursor: pointer;
}
.landing .add_p_box .circle_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-bottom: 50px;
}

.landing .add_p_box .circle_box .text {
  width: 100%;
  text-align: center;
  margin-top: 5px;
  font-size: 14px;
  font-weight: 600;
}
.nav_bar .right_section .button {
  cursor: pointer;
  -webkit-user-select: non;
     -moz-user-select: non;
      -ms-user-select: non;
          user-select: non;
  padding: 10px 20px;
  background-color: #FF5722;
  color: #FFFFFF;
  font-family: "Montserrat";
  border-radius: 50px;
  font-size: 14px;
  margin-left: 20px;
}

.checkout3 {
  padding-top: 80px;
}

.checkout3 .welcome_container {
  width: 100%;
  height: 250px;
  background-image: -webkit-gradient(linear, left top, right top, from(#FF5722), to(#CE3407));
  background-image: linear-gradient(to right, #FF5722, #CE3407);
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

.checkout3 .welcome_container .decor {
  width: 220px;
  height: 110px;
  position: absolute;
  top: 0px;
  background-repeat: no-repeat;
  background-size: 80%;
  background-position-y: initial;
}

.checkout3 .welcome_container .decor.left {
  background-position-x: initial;
  background-image: url(../../assets/decore-left.png);
  left: 0px;
}

.checkout3 .welcome_container .decor.right {
  background-position-x: right;
  background-image: url(../../assets/decore-right.png);
  right: 0px;
}

.checkout3 .welcome_container .box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.checkout3 .welcome_container .box .circle {
  background-image: url(../../assets/badge.png);
  height: 100px;
  width: 100px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.checkout3 .welcome_container .box .text {
  font-family: "Montserrat";
  color: #FFFFFF;
  font-weight: bold;
  font-size: 22px;
  margin-top: 10px;
}

.checkout3 .checkout_container {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding-top: 70px;
}

.checkout3 .checkout_container .circle_container {
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding-top: 50px;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

.checkout3 .checkout_container .circle_container .circle {
  color: #FFFFFF;
  padding: 10px;
  background-color: #FFFFFF;
  height: 30px;
  width: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
  font-size: 22px;
  font-weight: bold;
  font-family: "Montserrat";
  position: relative;
}

.checkout3 .checkout_container .circle_container .circle::after {
  content: "";
  height: 4px;
  width: 500px;
  position: absolute;
  background-color: #FFFFFF;
  top: 24px;
  right: 0px;
  z-index: -1;
}

.checkout3 .checkout_container .circle_container .circle._1, .checkout3 .checkout_container .circle_container .circle._2, .checkout3 .checkout_container .circle_container .circle._3 {
  background-color: #FF5722;
}

.checkout3 .checkout_container .circle_container .circle._1::after, .checkout3 .checkout_container .circle_container .circle._2::after, .checkout3 .checkout_container .circle_container .circle._3::after {
  background-color: #FF5722;
}

.checkout3 .payment_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 70px;
}

.checkout3 .payment_container .payment_row {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  margin-top: 50px;
  margin-bottom: 50px;
}

.checkout3 .payment_container .payment_row .payment_option_box, .checkout3 .payment_container .payment_row .card_box, .checkout3 .payment_container .payment_row .price_detail_box {
  width: 33.33%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media screen and (max-width: 768px) {
  .checkout3 .payment_container .payment_row .payment_option_box, .checkout3 .payment_container .payment_row .card_box, .checkout3 .payment_container .payment_row .price_detail_box {
    margin: auto;
    width: 90%;
  }
}

.checkout3 .payment_container .payment_row .payment_option_box, .checkout3 .payment_container .payment_row .price_detail_box {
  -webkit-transition: all 200ms;
  transition: all 200ms;
  padding-top: 40px;
}

.checkout3 .payment_container .payment_row .payment_option_box .center_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.checkout3 .payment_container .payment_row .payment_option_box .center_box .header {
  font-family: "Montserrat";
  font-size: 17px;
  margin-bottom: 20px;
}

.checkout3 .payment_container .payment_row .payment_option_box .center_box .item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.checkout3 .payment_container .payment_row .payment_option_box .center_box .item .radio {
  display: none;
}

.checkout3 .payment_container .payment_row .payment_option_box .center_box .item .radio + label {
  margin-top: 15px;
  font-family: "Montserrat";
  position: relative;
  font-size: 16px;
  left: 20px;
  cursor: pointer;
}

.checkout3 .payment_container .payment_row .payment_option_box .center_box .item .radio + label:active:before {
  -webkit-transform: scale(0);
          transform: scale(0);
}

.checkout3 .payment_container .payment_row .payment_option_box .center_box .item .radio + label::before {
  content: '';
  position: absolute;
  left: -25px;
  top: 0px;
  height: 16px;
  width: 16px;
  border: 1px solid #aaaaaa;
  border-radius: 50%;
}

.checkout3 .payment_container .payment_row .payment_option_box .center_box .item .radio + label::after {
  content: '';
  position: absolute;
  left: -21px;
  top: 4px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #FFFFFF;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.checkout3 .payment_container .payment_row .payment_option_box .center_box .item .radio:checked + label::after {
  background-color: #FF5722;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.checkout3 .payment_container .payment_row .card_box {
  -webkit-transition: all 200ms;
  transition: all 200ms;
}

@media screen and (max-width: 768px) {
  .checkout3 .payment_container .payment_row .card_box {
    margin-top: 50px;
  }
}

.checkout3 .payment_container .payment_row .card_box .box {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  border: 1px solid #aaaaaa;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.checkout3 .payment_container .payment_row .card_box .box .card_no_box .text, .checkout3 .payment_container .payment_row .card_box .box .expiry_box .column .text {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
}

.checkout3 .payment_container .payment_row .card_box .box .card_no_box input, .checkout3 .payment_container .payment_row .card_box .box .expiry_box .column input {
  font-family: "Montserrat";
  border-radius: 2px;
  border: 1px solid #aaaaaa;
  margin-top: 10px;
  font-size: 15px;
  padding: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.checkout3 .payment_container .payment_row .card_box .box .card_no_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

@media screen and (max-width: 768px) {
  .checkout3 .payment_container .payment_row .card_box .box .card_no_box {
    margin-top: 30px;
  }
}

.checkout3 .payment_container .payment_row .card_box .box .card_no_box input {
  width: 100%;
}

.checkout3 .payment_container .payment_row .card_box .box .card_no_box.top_gap {
  margin-top: 20px;
}

.checkout3 .payment_container .payment_row .card_box .box .expiry_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.checkout3 .payment_container .payment_row .card_box .box .expiry_box .column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  margin-top: 20px;
  margin-right: 20px;
}

.checkout3 .payment_container .payment_row .card_box .box .expiry_box .column input {
  width: 130px;
}

@media screen and (max-width: 768px) {
  .checkout3 .payment_container .payment_row .card_box .box .expiry_box .column input {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .checkout3 .payment_container .payment_row .price_detail_box {
    margin-top: 30px;
  }
}

.checkout3 .payment_container .payment_row .price_detail_box .center_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 90%;
  -webkit-box-shadow: 0px 1px 7px 1px #dadada;
          box-shadow: 0px 1px 7px 1px #dadada;
  border-radius: 5px;
  padding: 15px 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: "Montserrat";
}

.checkout3 .payment_container .payment_row .price_detail_box .center_box .header {
  font-weight: 500;
  margin-bottom: 5px;
  font-size: 17px;
}

.checkout3 .payment_container .payment_row .price_detail_box .center_box .data {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 20px;
  font-size: 14px;
  font-weight: 500;
}

.checkout3 .payment_container .payment_row .price_detail_box .center_box .data .right.orange {
  color: #FF5722;
}

.checkout3 .payment_container .payment_row .price_detail_box .center_box .line {
  height: 0px;
  width: 100%;
  background-color: #eaeaea;
  height: 1px;
  margin-top: 20px;
}

.checkout3 .buy_button_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.checkout3 .buy_button_container {
  margin-bottom: 70px;
}

.checkout3 .buy_button_container .button {
  background-color: #FF5722;
  color: #FFFFFF;
  font-family: "Montserrat";
  padding: 10px 70px;
  font-size: 13px;
  border-radius: 3px;
  cursor: pointer;
}

.checkout2 {
  padding-top: 80px;
}

.checkout2 .welcome_container {
  width: 100%;
  height: 250px;
  background-image: -webkit-gradient(linear, left top, right top, from(#FF5722), to(#CE3407));
  background-image: linear-gradient(to right, #FF5722, #CE3407);
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

.checkout2 .welcome_container .decor {
  width: 220px;
  height: 110px;
  position: absolute;
  top: 0px;
  background-repeat: no-repeat;
  background-size: 80%;
  background-position-y: initial;
}

.checkout2 .welcome_container .decor.left {
  background-position-x: initial;
  background-image: url(../../assets/decore-left.png);
  left: 0px;
}

.checkout2 .welcome_container .decor.right {
  background-position-x: right;
  background-image: url(../../assets/decore-right.png);
  right: 0px;
}

.checkout2 .welcome_container .box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.checkout2 .welcome_container .box .circle {
  background-image: url(../../assets/badge.png);
  height: 100px;
  width: 100px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.checkout2 .welcome_container .box .text {
  font-family: "Montserrat";
  color: #FFFFFF;
  font-weight: bold;
  font-size: 22px;
  margin-top: 10px;
}

.checkout2 .checkout_container {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding-top: 70px;
}

.checkout2 .checkout_container .circle_container {
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding-top: 50px;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

.checkout2 .checkout_container .circle_container .circle {
  color: #FFFFFF;
  padding: 10px;
  background-color: #FFFFFF;
  height: 30px;
  width: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
  font-size: 22px;
  font-weight: bold;
  font-family: "Montserrat";
  position: relative;
}

.checkout2 .checkout_container .circle_container .circle::after {
  content: "";
  height: 4px;
  width: 500px;
  position: absolute;
  background-color: #FFFFFF;
  top: 24px;
  right: 0px;
  z-index: -1;
}

.checkout2 .checkout_container .circle_container .circle._1, .checkout2 .checkout_container .circle_container .circle._2 {
  background-color: #FF5722;
}

.checkout2 .checkout_container .circle_container .circle._3 {
  height: 22px;
  width: 22px;
  line-height: 22px;
  color: #999999;
  border: 4px solid #999999;
}

.checkout2 .checkout_container .circle_container .circle._1::after, .checkout2 .checkout_container .circle_container .circle._2::after {
  background-color: #FF5722;
}

.checkout2 .payment_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 70px;
}

.checkout2 .payment_container .address_box {
  -webkit-transition: all 300ms;
  transition: all 300ms;
  width: 90%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-shadow: 0px 0px 6px 1px #dadada;
          box-shadow: 0px 0px 6px 1px #dadada;
  margin: 30px 0px;
  border-radius: 5px;
  padding: 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow-x: hidden;
}

.checkout2 .payment_container .address_box .left_section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 50%;
  min-width: 400px;
}

@media screen and (max-width: 500px) {
  .checkout2 .payment_container .address_box .left_section {
    width: 100%;
    min-width: 0px;
  }
}

.checkout2 .payment_container .address_box .input_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  width: 100;
  margin-bottom: 20px;
}

.checkout2 .payment_container .address_box .input_box .text {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
  width: 100%;
  max-width: 400px;
}

.checkout2 .payment_container .address_box .input_box .input {
  font-family: "Montserrat";
  letter-spacing: 0.3px;
  font-size: 15px;
  border-radius: 2px;
  padding: 7px 10px;
  width: 100%;
  max-width: 400px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: 1px solid #AAAAAA;
}

.checkout2 .buy_button_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.checkout2 .buy_button_container {
  margin-bottom: 70px;
}

.checkout2 .buy_button_container .button {
  background-color: #FF5722;
  color: #FFFFFF;
  font-family: "Montserrat";
  padding: 10px 70px;
  font-size: 13px;
  border-radius: 3px;
  font-weight: 600;
}

.checkout1 {
  padding-top: 80px;
}

.checkout1 .welcome_container {
  width: 100%;
  height: 250px;
  background-image: -webkit-gradient(linear, left top, right top, from(#FF5722), to(#CE3407));
  background-image: linear-gradient(to right, #FF5722, #CE3407);
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

.checkout1 .welcome_container .decor {
  width: 220px;
  height: 110px;
  position: absolute;
  top: 0px;
  background-repeat: no-repeat;
  background-size: 80%;
  background-position-y: initial;
}

.checkout1 .welcome_container .decor.left {
  background-position-x: initial;
  background-image: url(../../assets/decore-left.png);
  left: 0px;
}

.checkout1 .welcome_container .decor.right {
  background-position-x: right;
  background-image: url(../../assets/decore-right.png);
  right: 0px;
}

.checkout1 .welcome_container .box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.checkout1 .welcome_container .box .circle {
  background-image: url(../../assets/badge.png);
  height: 100px;
  width: 100px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.checkout1 .welcome_container .box .text {
  font-family: "Montserrat";
  color: #FFFFFF;
  font-weight: bold;
  font-size: 22px;
  margin-top: 10px;
}

.checkout1 .checkout_container {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding-top: 70px;
}

.checkout1 .checkout_container .circle_container {
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding-top: 50px;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

.checkout1 .checkout_container .circle_container .circle {
  color: #FFFFFF;
  padding: 10px;
  background-color: #FFFFFF;
  height: 30px;
  width: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
  font-size: 22px;
  font-weight: bold;
  font-family: "Montserrat";
  position: relative;
}

.checkout1 .checkout_container .circle_container .circle::after {
  content: "";
  height: 4px;
  width: 500px;
  position: absolute;
  background-color: #FFFFFF;
  top: 24px;
  right: 0px;
  z-index: -1;
}

.checkout1 .checkout_container .circle_container .circle._1 {
  background-color: #FF5722;
}

.checkout1 .checkout_container .circle_container .circle._3, .checkout1 .checkout_container .circle_container .circle._2 {
  height: 22px;
  width: 22px;
  line-height: 22px;
  color: #999999;
  border: 4px solid #999999;
}

.checkout1 .checkout_container .circle_container .circle._1::after {
  background-color: #FF5722;
}

.checkout1 .payment_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 70px;
}

.checkout1 .payment_container .container_box {
  width: 100%;
  margin: 30px 0px;
  padding: 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

.checkout1 .payment_container .container_box .box {
  height: 200px;
  width: 200px;
  border-radius: 7px;
  background-color: #D8D8D8;
  border: 1px solid #979797;
  margin: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.checkout1 .payment_container .container_box .box .white {
  height: 80px;
  width: 80px;
  background-color: #FFFFFF;
  border-radius: 4px;
  border: 1px solid #cacaca;
  margin-top: 30px;
  background-size: cover;
  
}

.checkout1 .payment_container .container_box .box .text {
  font-weight: 500;
  font-size: 13px;
  margin-top: 25px;
}

.checkout1 .payment_container .container_box .box .button {
  font-family: "Montserrat";
  background-color: #FF5722;
  color: #FFFFFF;
  font-size: 11px;
  font-weight: 500;
  margin-top: 10px;
  padding: 5px 25px;
  border-radius: 100px;
  cursor: pointer;
}

.checkout1 .buy_button_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.checkout1 .buy_button_container {
  margin-bottom: 70px;
}

.checkout1 .buy_button_container .button {
  background-color: #FF5722;
  color: #FFFFFF;
  font-family: "Montserrat";
  padding: 10px 70px;
  font-size: 13px;
  border-radius: 3px;
  font-weight: 600;
  cursor: pointer;
}

.login {
  margin: auto;
  padding-top: 80px;
}

.login .flex_row {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.login .flex_row .image1 {
  width: 50%;
  /* opacity: 0.1; */
  min-height: 400px;
  display: flex;
  /* justify-content:center; */
  /* background-image: url(../../assets/login.png); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
  min-width: 300px;
  margin: auto;
  -webkit-transition: all 400ms;
  transition: all 400ms;
  align-items: center;
  font-family: "Montserrat";
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px
}
.login .flex_row .image {
  width: 50%;
  /* opacity: 0.1; */
  min-height: 400px;
  display: flex;
  /* justify-content:center; */
  background-image: url(../../assets/login.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
  min-width: 300px;
  margin: auto;
  -webkit-transition: all 400ms;
  transition: all 400ms;
  align-items: center;
  font-family: "Montserrat";
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px
}
.login .flex_row .image2 {
  width: 50%;
  /* opacity: 0.1; */
  min-height: 400px;
  display: flex;
  /* justify-content:center; */
  background-image: url(../../assets/store.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
  min-width: 300px;
  margin: auto;
  -webkit-transition: all 400ms;
  transition: all 400ms;
  align-items: center;
  font-family: "Montserrat";
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px
}
.venalign {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  
}

@media screen and (max-width: 728px) {
  .login .flex_row .image1 {
    display: block;

  }
}

@media screen and (max-width: 600px) {
  .login .flex_row .image {
    width: 80%;
    background-size: 70%;
  }
}

.login .flex_row .login_section {
  width: 40%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 50px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  min-width: 300px;
  margin: auto;
  -webkit-transition: all 400ms;
  transition: all 400ms;
}

@media screen and (max-width: 600px) {
  .login .flex_row .login_section {
    width: 80%;
    background-size: 70%;
  }
}

.login .flex_row .login_section .header {
  font-weight: 600;
  font-family: "Montserrat";
  font-size: 18px;
  margin-bottom: 20px;
}

.login .flex_row .login_section .social_media_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  padding: 0px 0px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.login .flex_row .login_section .social_media_box .box {
  width: 40%;
  min-width: 200px;
  height: 30px;
  border-radius: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0px 15px 0px 7px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 10px auto 0px auto;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.login .flex_row .login_section .social_media_box .box .icon {
  height: 20px;
  width: 20px;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 5px;
}

.login .flex_row .login_section .social_media_box .box .icon.facebook {
  background-size: 70%;
  background-image: url(../../assets/facebook.svg);
}

.login .flex_row .login_section .social_media_box .box .icon.google {
  background-image: url(../../assets/google-plus.svg);
  background-size: 80%;
}

.login .flex_row .login_section .social_media_box .box .text {
  color: #FFFFFF;
  font-family: "Montserrat";
  font-size: 13px;
  font-weight: 500;
}

.login .flex_row .login_section .social_media_box .box.google {
  background-color: #DF4930;
  cursor: pointer;
}

.login .flex_row .login_section .social_media_box .box.facebook {
  background-color: #507CC0;
  cursor: pointer;
}

.login .flex_row .login_section .center {
  width: 100%;
  text-align: center;
  font-family: "Montserrat";
  margin: 20px auto;
  font-weight: 600;
  font-size: 15px;
}

.login .flex_row .login_section .input_box {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  font-family: "Montserrat";
}

.login .flex_row .login_section .input_box .text {
  font-weight: 600;
  font-size: 14px;
  color: #6B6C6F;
}

.login .flex_row .login_section .input_box input {
  padding: 10px 20px;
  border-radius: 100px;
  border: 1px solid #AAAAAA;
  margin-top: 10px;
  font-family: "Montserrat";
  font-size: 15px;
  letter-spacing: 0.4px;
}

.login .flex_row .login_section .input_box.top_gap {
  margin-top: 20px;
}

.login .flex_row .login_section .button_container {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-top: 30px;
}

.login .flex_row .login_section .button_container .button {
  width: 40%;
  padding: 12px 15px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 100px;
  text-align: center;
  min-width: 150px;
  font-family: "Montserrat";
  font-size: 13px;
  margin: 10px auto 0px auto;
}

.login .flex_row .login_section .button_container .button.reg {
  border: 2px solid #FF5722;
  color: #FF5722;
  font-weight: 500;
  cursor: pointer;
}

.login .flex_row .login_section .button_container .button.log {
  border: 2px solid #FF5722;
  background-color: #FF5722;
  color: #FFFFFF;
  font-weight: 500;
  -webkit-box-shadow: 1px 7px 10px 1px rgba(228, 30, 38, 0.18);
          box-shadow: 1px 7px 10px 1px rgba(228, 30, 38, 0.18);
          cursor: pointer;
}


.package {
  padding-top: 80px;
  overflow: hidden;
}

.package .welcome_container {
  width: 100%;
  height: 250px;
  background-image: -webkit-gradient(linear, left top, right top, from(#FF5722), to(#CE3407));
  background-image: linear-gradient(to right, #FF5722, #CE3407);
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

.package .welcome_container .decor {
  width: 220px;
  height: 110px;
  position: absolute;
  top: 0px;
  background-repeat: no-repeat;
  background-size: 80%;
  background-position-y: initial;
}

.package .welcome_container .decor.left {
  background-position-x: initial;
  background-image: url(../../assets/decore-left.png);
  left: 0px;
}

.package .welcome_container .decor.right {
  background-position-x: right;
  background-image: url(../../assets/decore-right.png);
  right: 0px;
}

.package .welcome_container .box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.package .welcome_container .box .circle {
  background-image: url(../../assets/badge.png);
  height: 100px;
  width: 100px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.package .welcome_container .box .text {
  font-family: "Montserrat";
  color: #FFFFFF;
  font-weight: bold;
  font-size: 22px;
  margin-top: 10px;
}

.package .header {
  width: 100%;
  text-align: center;
  margin: 50px auto;
  font-family: "Montserrat";
  letter-spacing: 0.3px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.package .header span {
  font-weight: 600;
}

.package .body {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

@media screen and (max-width: 768px) {
  .package .body {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

.package .body .taskbar {
  width: 250px;
  height: 100%;
  padding: 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-family: "Montserrat";
  -webkit-transition: all 500ms;
  transition: all 500ms;
}


.package .body .taskbar.mob{
  display: none;
 }
 

 
 
 @media screen and (max-width: 768px) {
   .package .body .taskbar {
     margin: auto;
     display: none;
 
   }
   .package .body .taskbar.mob{
     
     display: flex;
    }
 }


.package .body .taskbar .header {
  color: #757575;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0px;
}

.package .body .taskbar .header .icon {
  height: 14px;
  width: 14px;
  background-image: url(../../assets/right-arrow.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  position: relative;
  top: 2px;
  left: 13px;
}

.package .body .taskbar .item_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 20px 0px;
}

.package .body .taskbar .item_box .item {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 15px;
  line-height: 30px;
  cursor: pointer;
}

.package .body .data_box {
  width: calc(100% - 250px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

@media screen and (max-width: 768px) {
  .package .body .data_box {
    width: 100%;
  }
}

.package .body .data_box .top_section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.package .body .data_box .top_section .package_details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 70%;
  max-width: 70%;
  /* margin: auto; */
}

@media screen and (max-width: 768px) {
  .package .body .data_box .top_section .package_details {
    width: 95%;
    max-width: 95%;
    margin: auto;
  }
}

.package .body .data_box .top_section .package_details .package_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  padding: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media screen and (max-width: 600px) {
  .package .body .data_box .top_section .package_details .package_container {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}
@media screen and (max-width: 600px) {
  .imagesetme {
    height: 270px;
    width: 270px;
  }
}
.imagesetme {
  min-height: 220px;
  min-width: 220px;
  border-radius: 4px;
  background-color: #D8D8D8;
  border: 1px solid #979797;
}
.package .body .data_box .top_section .package_details .package_container .image {
  height: 155px;
  width: 206px;
  min-width: 151px;
  border-radius: 4px;
  border: 1px solid #979797;
  margin: auto;
  margin-bottom: 30px;
}

.package .body .data_box .top_section .package_details .package_container .data_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 0px 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin-top: 5px;
}

.package .body .data_box .top_section .package_details .package_container .data_container .top_section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  justify-content: space-between;
}

.package .body .data_box .top_section .package_details .package_container .data_container .top_section .button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 5px 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-color: #FF5722;
  border-radius: 2px;
  color: #FFFFFF;
  font-family: "Montserrat";
  font-size: 13px;
  /* margin: auto; */
}

@media screen and (max-width: 321px) {
  .package .body .data_box .top_section .package_details .package_container .data_container .top_section .button {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 842px) and (min-width: 770px) {
  .package .body .data_box .top_section .package_details .package_container .data_container .top_section .button {
    margin-bottom: 10px;
  }

}

.package .body .data_box .top_section .package_details .package_container .data_container .top_section .button .icon {
  height: 15px;
  width: 15px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 90%;
  background-image: url(../../assets/user.svg);
  margin-right: 5px;
}

.package .body .data_box .top_section .package_details .package_container .data_container .top_section .text {
  font-family: "Montserrat";
  margin-left: 10px;
  font-weight: 500;
  /* margin: auto; */
}

.package .body .data_box .top_section .package_details .package_container .data_container .content {
  line-height: 20px;
  font-family: "Montserrat";
  font-size: 13px;
  margin-top: 20px;
  color: #333333;
}

.package .body .data_box .top_section .package_details .package_container .data_container .button_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  font-family: "Montserrat";
  font-size: 13px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 20px;
}

.package .body .data_box .top_section .package_details .package_container .data_container .button_box .sq_button {
  padding: 8px 18px;
  border: 1px solid #979797;
  border-radius: 2px;
  color: #333333;
  width: 115px;
  margin-right: 15px;
}

.package .body .data_box .top_section .package_details .package_container .data_container .button_box .rd_button {
  padding: 7px 18px;
  border-radius: 100px;
  background-color: #FF5722;
  color: #ffffff;
  margin: 15px 0px;
  cursor: pointer;
}

.package .body .data_box .top_section .package_details .package_include_section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.package .body .data_box .top_section .package_details .package_include_section .header {
  text-transform: uppercase;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  font-weight: 500;
}

.package .body .data_box .top_section .package_details .package_include_section .body_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-right: 15px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.package .body .data_box .top_section .package_details .package_include_section .body_container .box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0px 0px 20px 0px;
}

.package .body .data_box .top_section .package_details .package_include_section .body_container .box .image {
  height: 60px;
  width: 60px;
  min-width: 60px;
  /* background-color: #D8D8D8; */
  background-image: url(../../assets/birthday1.jpg);
  border: 1px solid #6B6C6F;
  border-radius: 2px;
}

.package .body .data_box .top_section .package_details .package_include_section .body_container .box .content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.package .body .data_box .top_section .package_details .package_include_section .body_container .box .content .text {
  font-family: "Montserrat";
  line-height: 20px;
  padding: 0px 7px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: 13px;
}

.package .body .data_box .top_section .package_details .package_include_section .body_container .box .content .text.header {
  margin: 0px;
}

.package .body .data_box .top_section .box_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 30%;
  min-width: 200px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

@media screen and (max-width: 963px) {
  .package .body .data_box .top_section .box_container {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    width: 100%;
  }
  .hidemob {
    display: none !important;
  }
}

.package .body .data_box .top_section .box_container .box {
  width: 100%;
  max-width: 220px;
  min-width: 200px;
  padding: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-color: #FFFFFF;
  border: 1px solid #AAAAAA;
  -webkit-box-shadow: 0px 0px 10px 3px #cacaca;
          box-shadow: 0px 0px 10px 3px #cacaca;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-family: "Montserrat";
  text-transform: uppercase;
  margin: 35px auto 0px auto;
}

.package .body .data_box .top_section .box_container .box .header {
  margin: 10px auto;
  font-size: 16px;
  font-weight: 500;
}

.package .body .data_box .top_section .box_container .box .content {
  line-height: 20px;
  margin: 10px 0px;
}

.package .body .data_box .top_section .box_container .box .button {
  margin: 10px auto;
  background-color: #FF5722;
  border-radius: 100px;
  color: #FFFFFF;
  padding: 7px 15px;
  font-size: 12px;
  font-weight: 600;
}

.package .body .data_box .top_section .box_container .box.second .icon {
  height: 60px;
  width: 60px;
  margin: 5px auto;
  background-image: url(../../assets/engineerICON.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.package .body .data_box .top_section .box_container .box.second .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
  margin: 10px 0px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.package .body .data_box .top_section .box_container .box.second .container .text {
  text-transform: none;
  font-size: 12px;
  width: 70%;
  font-weight: 500;
}

.package .body .data_box .top_section .box_container .box.second .container .checkbox {
  display: none;
}

.package .body .data_box .top_section .box_container .box.second .container .checkbox + label {
  font-size: 13px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
}

.package .body .data_box .top_section .box_container .box.second .container .checkbox + label:before {
  content: "\2714";
  border-radius: 0.2em;
  display: inline-block;
  height: 7px;
  width: 7px;
  padding: 0px 6px 10px 4px;
  margin-right: 0.7em;
  vertical-align: bottom;
  color: transparent;
  -webkit-transition: .2s;
  transition: .2s;
  background-color: #D8D8D8;
}

.package .body .data_box .top_section .box_container .box.second .container .checkbox + label:active:before {
  -webkit-transform: scale(0);
          transform: scale(0);
}

.package .body .data_box .top_section .box_container .box.second .container .checkbox:checked + label {
  color: #FF5722;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}

.package .body .data_box .top_section .box_container .box.second .container .checkbox:checked + label:before {
  background-color: #FF5722;
  border-color: #FF5722;
  color: #fff;
  -webkit-box-shadow: 0px 0px 0px 0px #dadada;
          box-shadow: 0px 0px 0px 0px #dadada;
}

.package .body .data_box .top_section .box_container .box.second .container .checkbox:disabled + label:before {
  -webkit-transform: scale(1);
          transform: scale(1);
  border-color: #aaa;
}

.package .body .data_box .top_section .box_container .box.second .container .checkbox:checked:disabled + label:before {
  -webkit-transform: scale(1);
          transform: scale(1);
  background-color: #bfb;
  border-color: #bfb;
}

.package .body .data_box .add_extra_products_container {
  margin: 30px 0px;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px;
}

.package .body .data_box .add_extra_products_container .heading {
  width: 100%;
  text-transform: uppercase;
}

.package .body .data_box .add_extra_products_container .data_box_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-top: 30px;
  padding-right: 30px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.package .body .data_box .add_extra_products_container .data_box_container .data_box {
  width: 45%;
  margin-right: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 5px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 0px auto;
  margin-bottom: 50px;
}

.package .body .data_box .add_extra_products_container .data_box_container .data_box .image {
  height: 80px;
  width: 80px;
  /* background-color: #D8D8D8; */
  background-image: url(../../assets/birthday1.jpg);
  border-radius: 2px;
  border: 1px solid #cacaca;
  /* margin: auto; */
  -webkit-transition: all 300ms;
  transition: all 300ms;
  margin-top: 10px;
}

@media screen and (max-width: 476px) {
  .package .body .data_box .add_extra_products_container .data_box_container .data_box .image {
    margin-bottom: 20px;
    height: 180px;
    width: 180px;
  }
}

.package .body .data_box .add_extra_products_container .data_box_container .data_box .details_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: calc(100% - 100px);
  margin: auto;
  padding: 5px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  min-width: 300px;
}

.package .body .data_box .add_extra_products_container .data_box_container .data_box .details_box .heading_box {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.package .body .data_box .add_extra_products_container .data_box_container .data_box .details_box .heading_box .content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 0px 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-weight: 400;
  color: #333333;
}

.package .body .data_box .add_extra_products_container .data_box_container .data_box .details_box .heading_box .content .main {
  text-transform: uppercase;
  font-size: 15px;
  margin: 5px 0px;
}

.package .body .data_box .add_extra_products_container .data_box_container .data_box .details_box .heading_box .content .sub {
  font-size: 13px;
}

.package .body .data_box .add_extra_products_container .data_box_container .data_box .details_box .heading_box .count {
  padding: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  text-align: center;
  font-size: 17px;
}

.package .body .data_box .add_extra_products_container .data_box_container .data_box .details_box .varient_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 5px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.package .body .data_box .add_extra_products_container .data_box_container .data_box .details_box .varient_container .varient {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 0px 5px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 8px 0px;
}

.package .body .data_box .add_extra_products_container .data_box_container .data_box .details_box .varient_container .varient.gap {
  margin-top: 20px;
}

.package .body .data_box .add_extra_products_container .data_box_container .data_box .details_box .varient_container .varient .name {
  font-size: 15px;
}

.package .body .data_box .add_extra_products_container .data_box_container .data_box .details_box .varient_container .varient .name_count {
  font-size: 16px;
  font-weight: 600;
  color: #FF5722;
}

.package .body .data_box .add_extra_products_container .data_box_container .data_box .details_box .varient_container .varient .action_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.package .body .data_box .add_extra_products_container .data_box_container .data_box .details_box .varient_container .varient .action_box .btn {
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  color: #FFFFFF;
  border-radius: 10px;
  background-color: #FF5722;
  margin: 0px 4px;
  font-weight: 700;
  cursor: pointer;
}

.package .body .data_box .add_extra_products_container .data_box_container .data_box .details_box .varient_container .varient .action_box .text {
  text-transform: uppercase;
  margin: 0px 5px;
  font-size: 14px;
  min-width: 20px;
  text-align: center;
}

.package .body .data_box .add_extra_products_container .data_box_container .data_box .details_box .varient_container .varient .action_box .total {
  text-transform: uppercase;
  margin: 0px 5px;
  font-size: 16px;
  color: #6B6C6F;
  font-weight: 600;
}

.package .body .data_box .add_extra_products_container .data_box_container .data_box .details_box .varient_container .varient .action_box .button {
  color: #FF5722;
  font-size: 13px;
  font-weight: 500;
  background-color: #FFFFFF;
  border: 1px solid #FF5722;
  padding: 2px 10px;
  border-radius: 10px;
  margin-left: 15px;
  cursor: pointer;
}


.detail {
  padding-top: 80px;
  overflow-x: hidden;
}

.detail .row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-transition: all 300ms;
  transition: all 300ms;
}

.detail .row.wrap {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.detail .row.center {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.detail .row.justify_between {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.detail .row.justify_around {
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

.detail .row.align_center {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.detail .row.justify_end {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.detail .col {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-transition: all 300ms;
  transition: all 300ms;
}

.detail .col.wrap {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.detail .col.center {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.detail .col.justify_end {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.detail .col.align_center {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.detail .col.justify_start {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.detail .container {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: all 300ms;
  transition: all 300ms;
}

.detail .container.full {
  width: 100%;
}

.detail .pad_100 {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 100px;
}

.detail .pad_50 {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 50px;
}

.detail .pad_50_100 {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 50px 100px;
}

@media screen and (max-width: 768px) {
  .detail .pad_50_100 {
    padding: 50px 50px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  
}

@media screen and (max-width: 768px) {
  .detail .sm_20 {
    padding: 20px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
}

.detail .upper {
  text-transform: uppercase;
}

.detail .lower {
  text-transform: none;
}

.detail .sub_header {
  font-weight: 500;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 16px;
}

.detail .sub_header.light {
  color: #FFFFFF;
}

.detail .main_header {
  font-size: 25px;
  font-weight: 600;
  margin-top: 15px;
  margin-bottom: 15px;
}

.detail .main_header.light {
  color: #FFFFFF;
}

.detail .txt {
  font-family: "Montserrat";
  font-size: 16px;
}

.detail .txt.small {
  font-size: 13px;
}

.detail .txt.strong {
  font-weight: 600;
}

.detail .txt.light {
  color: #FFFFFF;
}

.detail .btn {
  padding: 10px 20px;
}

.detail .btn.color {
  background-color: #FF5722;
  color: #FFFFFF;
}

.detail .btn.round {
  border-radius: 200px;
}

.detail .btn.top_20 {
  margin-top: 20px;
}

.detail .bg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.detail .welcome_container {
  width: 100%;
  height: 250px;
  background-image: -webkit-gradient(linear, left top, right top, from(#FF5722), to(#CE3407));
  background-image: linear-gradient(to right, #FF5722, #CE3407);
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

.detail .welcome_container .decor {
  width: 220px;
  height: 110px;
  position: absolute;
  top: 0px;
  background-repeat: no-repeat;
  background-size: 80%;
  background-position-y: initial;
}

.detail .welcome_container .decor.left {
  background-position-x: initial;
  background-image: url(../../assets/decore-left.png);
  left: 0px;
}

.detail .welcome_container .decor.right {
  background-position-x: right;
  background-image: url(../../assets/decore-right.png);
  right: 0px;
}

.detail .welcome_container .box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.detail .welcome_container .box .circle {
  background-image: url(../../assets/badge.png);
  height: 100px;
  width: 100px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.detail .welcome_container .box .text {
  font-family: "Montserrat";
  color: #FFFFFF;
  font-weight: bold;
  font-size: 22px;
  margin-top: 10px;
}

.detail .header {
  width: 100%;
  text-align: center;
  margin: 50px auto;
  font-family: "Montserrat";
  letter-spacing: 0.3px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.detail .header span {
  font-weight: 600;
}

.detail .body {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

@media screen and (max-width: 768px) {
  .detail .body {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

.detail .body .taskbar {
  width: 250px;
  height: 100%;
  padding: 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-family: "Montserrat";
  -webkit-transition: all 500ms;
  transition: all 500ms;
}






.detail .body .taskbar.mob{
  display: none;
 }
 

 
 
 @media screen and (max-width: 768px) {
   .detail .body .taskbar {
     margin: auto;
     display: none;
 
   }
   .detail .body .taskbar.mob{
     
     display: flex;
    }
 }








.detail .body .taskbar .header {
  color: #757575;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0px;
}

.detail .body .taskbar .header .icon {
  cursor: pointer;
  transform: rotate(180deg);
  height: 14px;
  width: 14px;
  background-image: url(../../assets/right-arrow.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  position: relative;
  top: 2px;
  right: 8px;
}

.detail .body .taskbar .item_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 20px 0px;
}

.detail .body .taskbar .item_box .item {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 15px;
  line-height: 30px;
  cursor: pointer;
}

.detail .body .data_box {
  width: calc(100% - 250px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin-bottom: 70px;
}

@media screen and (max-width: 768px) {
  .detail .body .data_box {
    width: 100%;
  }
}

.detail .body .data_box .top_section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.detail .body .data_box .top_section .package_details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 70%;
  max-width: 70%;
  margin: auto;
  min-width: 380px;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .detail .body .data_box .top_section .package_details {
    width: 95%;
    max-width: 95%;
    margin: auto;
  }
}

.detail .body .data_box .top_section .package_details .package_box {
  width: 95%;
  -webkit-box-shadow: 0px 0px 5px 1px #dadada;
          box-shadow: 0px 0px 5px 1px #dadada;
  border-radius: 5px;
  margin-bottom: 20px;
  font-family: "Montserrat";
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

.detail .body .data_box .top_section .package_details .package_box .l_box {
  /* width: 70%; */
  min-width: 200px;
  padding: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

@media screen and (max-width: 1050px) {
  .detail .body .data_box .top_section .package_details .package_box .l_box {
    width: 90%;
  }
}

.detail .body .data_box .top_section .package_details .package_box .l_box .image {
  height: 130px;
  width: 130px;
  border-radius: 5px;
  /* background-color: #D8D8D8; */
  /* background-image: url(../../assets/birthday1.jpg); */
  border: 1px solid #979797;
  margin: auto;
}

.detail .body .data_box .top_section .package_details .package_box .l_box .det_box {
  min-width: 200px;
  width: calc(100% - 150px);
  margin-left: 10px;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
@media screen and (max-width:480px) {
  .detail .body .data_box .top_section .package_details .package_box .l_box .det_box {
    width: 100%;  
    margin-left: 20px;
  }
}


.detail .body .data_box .top_section .package_details .package_box .l_box .det_box .heading_main {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 15px;
  line-height: 27px;
}

.detail .body .data_box .top_section .package_details .package_box .l_box .det_box .heading_sub {
  font-size: 13px;
  font-weight: 500;
  line-height: 25px;
}

.detail .body .data_box .top_section .package_details .package_box .l_box .det_box .heading_sub span {
  color: #FF5722;
}

.detail .body .data_box .top_section .package_details .package_box .l_box .det_box .content {
  font-family: "Montserrat";
  color: #666666;
  font-size: 11px;
  line-height: 17px;
  padding-right: 10px;
  text-align: justify;
  letter-spacing: 0.3px;
}

.detail .body .data_box .top_section .package_details .package_box .r_box {
  width: 30%;
  min-width: 200px;
  padding: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  cursor: pointer;
}

.detail .body .data_box .top_section .package_details .package_box .r_box .upto_box {
  background-color: #FF5722;
  color: #FFFFFF;
  padding: 3px 10px;
  border-radius: 2px;
  margin-top: 5px;
}

.detail .body .data_box .top_section .package_details .package_box .r_box .upto_box .icon {
  background-image: url(../../assets/user.svg);
  height: 10px;
  width: 10px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  margin-right: 5px;
}

.detail .body .data_box .top_section .package_details .package_box .r_box .upto_box .txt {
  font-size: 11px;
  font-weight: 500;
}

.detail .body .data_box .top_section .package_details .package_box .r_box .price {
  margin: 25px auto 10px auto;
  font-weight: 500;
  font-family: "Montserrat";
  font-size: 20px;
}

.detail .body .data_box .top_section .package_details .package_box .r_box .know {
  padding: 7px 15px;
  background-color: #EDEDED;
  border-radius: 3px;
  font-size: 11px;
  font-weight: 500;
  margin-bottom: 10px;
}

.detail .body .data_box .top_section .package_details .package_box .buy {
  text-transform: uppercase;
  padding: 8px 20px;
  font-size: 12px;
  color: #FFFFFF;
  font-weight: 500;
  background-color: #FF5722;
  border-radius: 100px;
}

.detail .body .data_box .top_section .box_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 30%;
  min-width: 200px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

@media screen and (max-width: 963px) {
  .detail .body .data_box .top_section .box_container {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    width: 100%;
  }
}

.detail .body .data_box .top_section .box_container .box {
  margin: auto;
  width: 100%;
  max-width: 220px;
  min-width: 200px;
  padding: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-color: #FFFFFF;
  border: 1px solid #AAAAAA;
  -webkit-box-shadow: 0px 0px 10px 3px #cacaca;
          box-shadow: 0px 0px 10px 3px #cacaca;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          /* align-items: center; */
  font-family: "Montserrat";
  text-transform: uppercase;
}

.detail .body .data_box .top_section .box_container .box .icon {
  height: 70px;
  width: 70px;
  background-image: url(../../assets/writing.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 70%;
  margin-top: 10px;
}

.detail .body .data_box .top_section .box_container .box .content {
  line-height: 20px;
  margin: 10px 0px 0px 0px;
  font-size: 13px;
  font-weight: 500;
}

.detail .body .data_box .top_section .box_container .box .button {
  margin: 10px auto;
  background-color: #FF5722;
  border-radius: 100px;
  color: #FFFFFF;
  padding: 7px 15px;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.landing {
  width: 100%;
  padding-top: 80px;
  font-family: "Montserrat";
}

.landing .row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-transition: all 300ms;
  transition: all 300ms;
}

.landing .row.wrap {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.landing .row.center {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.landing .row.justify_between {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.landing .row.justify_around {
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

.landing .row.align_center {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.landing .row.justify_end {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.landing .col {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-transition: all 300ms;
  transition: all 300ms;
}

.landing .col.wrap {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.landing .col.center {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.landing .col.justify_end {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.landing .col.align_center {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.landing .container {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: all 300ms;
  transition: all 300ms;
}

.landing .container.full {
  width: 100%;
}

.landing .pad_100 {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 100px;
}

.landing .pad_50 {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 50px;
}

.landing .pad_50_100 {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 50px 100px;
}

@media screen and (max-width: 768px) {
  .landing .pad_50_100 {
    padding: 50px 50px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
}

@media screen and (max-width: 768px) {
  .landing .sm_20 {
    padding: 20px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
}

.landing .upper {
  text-transform: uppercase;
}

.landing .lower {
  text-transform: none;
}

.landing .sub_header {
  font-weight: 500;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 16px;
}

.landing .sub_header.light {
  color: #FFFFFF;
}

.landing .main_header {
  font-size: 25px;
  font-weight: 600;
  margin-top: 15px;
  margin-bottom: 15px;
}

.landing .main_header.light {
  color: #FFFFFF;
}

.landing .txt {
  font-family: "Montserrat";
  font-size: 16px;
}

.landing .txt.small {
  font-size: 13px;
}

.landing .txt.strong {
  font-weight: 600;
}

.landing .txt.light {
  color: #FFFFFF;
}

.landing .btn {
  padding: 10px 20px;
}

.landing .btn.color {
  background-color: #FF5722;
  color: #FFFFFF;
}

.landing .btn.round {
  border-radius: 200px;
}

.landing .btn.top_20 {
  margin-top: 20px;
}

.landing .bg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.landing .slider {
  width: 100%;
  /* background-color: black; */
  /* background-image: linear-gradient(to bottom, transparent 50%, #000000); */
  
  padding: 200px 100px 100px 100px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
          /* background: url('../../assets/banner.jpg'); */
          /* background-position:center;
          -webkit-transition: all .5s ease;
          -moz-transition: all .5s ease;
          -ms-transition: all .5s ease;
          -o-transition: all .5s ease;
          transition: all .5s ease; */
}

.imagebg {
	width:90%;
  height:400px;
	background:linear-gradient(to bottom, rgba(48, 61, 134, 0.52), rgba(48, 44, 47, 0.73)), url("../../assets/banner.jpg");
	background-position:center;
  -webkit-transition: all .5s ease;
  -moz-transition: all .5s ease;
  -ms-transition: all .5s ease;
  -o-transition: all .5s ease;
  transition: all .5s ease;
  display: flex;
  align-items: center;
  padding-left: 10%;
  color:white;
  font-size: 25px;
  font-weight: bold;
  /* opacity: 0.9; */
  
} 
.imagebg:hover {  
  -webkit-transform: scale(1.01);
  -moz-transform: scale(1.01);
  -o-transform: scale(1.01);
  -ms-transform: scale(1.01); /* IE 9 */ 
  transform: scale(1.01);
} 


.landing .slider .slider_main_header {
  font-size: 30px;
  color: #FFFFFF;
  font-weight: 500;
  margin-top: 15px;
  margin-bottom: 15px;
  word-break: break-all;
}

.landing .slider .slider_main_header .strong {
  color: #FF5722;
  font-weight: 700;
}

.landing .slider .button_box {
  margin-top: 20px;
  padding: 10px 15px;
  background-color: #FFFFFF;
  border-radius: 2px;
  margin-right: 20px;
  cursor: pointer;
}

.landing .slider .down_arrow {
  height: 20px;
  width: 20px;
  margin-left: 10px;
  background-image: url(../../assets/down-arrow.svg);
  background-size: 50%;
}

.landing .slider .calendar {
  height: 20px;
  width: 20px;
  margin-left: 10px;
  background-image: url(../../assets/calendar.svg);
}

.landing .description_section .txt_box {
  width: calc(100% - 110px);
  line-height: 27px;
}

.landing .description_section .round {
  width: 90px;
  min-width: 90px;
  height: 90px;
  line-height: 90px;
  background-color: #FF5722;
  color: #FFFFFF;
  border-radius: 50%;
  /* border: 1px solid #979797; */
  text-align: center;
  font-size: 17px;
  font-weight: 700;
}

.landing .theme_box {
  width: 100%;
}

.landing .theme_box .box_item {
  height: 250px;
  min-width: 200px;
  width: 21%;
  /* background-color: cyan; */
  margin-bottom: 15px;
  margin-left: 20px;
  border-radius: 7px;
  
  margin: 0px auto 15px auto;

  background-position:center;
  -webkit-transition: all .5s ease;
  -moz-transition: all .5s ease;
  -ms-transition: all .5s ease;
  -o-transition: all .5s ease;
  transition: all .5s ease;

}
.landing .theme_box .box_item:hover {

  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1); /* IE 9 */ 
  transform: scale(1.1);
}

.landing .theme_box .box_item .overlay {
  padding: 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 100%;
  width: 100%;
  border-radius: 7px;
  /* background-image: -webkit-gradient(linear, left top, left bottom, color-stop(50%, transparent), to(#000000)); */
  background-image: linear-gradient(to bottom, transparent 50%, #000000);
  cursor: pointer;
}

.landing .add_p_box .circle {
  height: 130px;
  width: 130px;
  border-radius: 50%;
  margin-bottom: 10px;
  /* background-image: url(../../assets/mic.jpeg); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.landing .book_box {
  background-image: -webkit-gradient(linear, left top, right top, from(#FF5722), to(#CE3407));
  background-image: linear-gradient(to right, #FF5722, #CE3407);
  margin-bottom: 50px;
}

.landing .book_box .txt {
  width: 80%;
  line-height: 27px;
  margin-top: 10px;
}

@media screen and (max-width: 768px) {
  .landing .book_box .txt {
    width: 100%;
  }
}

.landing .book_box .button_box {
  width: 20%;
  min-width: 100px;
}

@media screen and (max-width: 768px) {
  .landing .book_box .button_box {
    width: 100%;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .landing .slider {
    width: 100%;
    background-color: black;
    padding: 100px 50px 100px 50px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
}

.landing .book_box .button_box .button {
  padding: 9px 15px;
  color: #FF5722;
  background-color: #FFFFFF;
  border-radius: 100px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  /* -webkit-box-shadow: 0px 4px 7px 4px #BC340D;
          box-shadow: 0px 4px 7px 4px #BC340D; */
  min-width: 100px;
  text-align: center;
  margin-top: 10px;
  cursor: pointer;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
}
.landing .book_box .button_box .button:hover {
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19)
}

.contact {
  font-family: "Montserrat";
  padding-top: 80px;
}

.contact .welcome_container {
  width: 100%;
  height: 180px;
  background-image: -webkit-gradient(linear, left top, right top, from(#FF5722), to(#CE3407));
  background-image: linear-gradient(to right, #FF5722, #CE3407);
  position: relative;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.contact .welcome_container .box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  margin-right: 15%;
  font-size: 27px;
}

.contact .welcome_container .box .text {
  text-transform: uppercase;
  font-family: "Montserrat";
  color: #FFFFFF;
  font-weight: bold;
  font-size: 35px;
  margin-top: 10px;
}

.contact .heading_container {
  -webkit-transition: all 300ms;
  transition: all 300ms;
  width: 100%;
  margin: 50px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  padding: 50px 100px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

@media screen and (max-width: 500px) {
  .contact .heading_container {
    padding: 50px;
  }
  .landing .theme_box .box_item {
    height: 250px;
    min-width: 200px;
    width: 100%;
    /* background-color: cyan; */
    margin-bottom: 15px;
    margin-left: 20px;
    border-radius: 7px;
    
    margin: 0px auto 15px auto;
  
    background-position:center;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease;
  
  }
}

.contact .heading_container .main {
  font-size: 25px;
}

.contact .heading_container .sub {
  margin-top: 5px;
  color: #979797;
  font-size: 18px;
  line-height: 27px;
}

.contact .body_container {
  /* overflow-x: hidden; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 0px 100px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin-bottom: 50px;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .contact .body_container {
    padding: 0px 10px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1000px) {
  .contact .body_container {
    padding: 0px 50px;
  }
}

.contact .body_container .l_section {
  width: 50%;
  min-width: 300px;
  margin: auto;
  padding: 5px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin-bottom: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: inherit;
}

.contact .body_container .l_section.map {
  margin-top: 0px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media screen and (max-width: 810px) {
  .contact .body_container .l_section {
    width: 90%;
  }
}

.contact .body_container .input_container {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  margin-bottom: 15px;
}

.contact .body_container .input_container .text {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
  color: #333333;
}

.contact .body_container .input_container .input {
  width: 100%;
  max-width: 400px;
  padding: 7px 15px;
  border: 1px solid #AAAAAA;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: "Montserrat";
  font-size: 15px;
  letter-spacing: 0.3px;
}

@media secreen and (max-width: 500px) {
  .contact .body_container .input_container .input {
    max-width: 0px;
    width: 80%;
  }
}

.contact .body_container .input_container .text_area {
  width: 100%;
  max-width: 400px;
  padding: 7px 15px;
  border: 1px solid #AAAAAA;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: "Montserrat";
  font-size: 15px;
  letter-spacing: 0.3px;
  resize: vertical;
  min-height: 120px;
  line-height: 25px;
}

@media secreen and (max-width: 500px) {
  .contact .body_container .input_container .text_area {
    max-width: 0px;
    width: 80%;
  }
}

.contact .body_container .button {
  background-color: #FF5722;
  font-weight: 600;
  font-size: 14px;
  padding: 9px 27px;
  color: #FFFFFF;
  border-radius: 2px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: max-content;
  cursor: pointer;
}

.contact .body_container .map_box {
  width: 80%;
  max-width: 500px;
  min-width: 300px;
  min-height: 300px;
  height: inherit;
  background-color: #D8D8D8;
  border: 1px solid #AAAAAA;
  border-radius: 2px;
  margin: 0px auto;
}

.about {
  font-family: "Montserrat";
  padding-top: 80px;
}

.about .welcome_container {
  width: 100%;
  height: 180px;
  background-image: -webkit-gradient(linear, left top, right top, from(#FF5722), to(#CE3407));
  background-image: linear-gradient(to right, #FF5722, #CE3407);
  position: relative;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.about .welcome_container .box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  margin-right: 15%;
  font-size: 27px;
}

.about .welcome_container .box .text {
  text-transform: uppercase;
  font-family: "Montserrat";
  color: #FFFFFF;
  font-weight: bold;
  font-size: 35px;
  margin-top: 10px;
}

.about .heading_container {
  -webkit-transition: all 300ms;
  transition: all 300ms;
  width: 100%;
  margin: 0px 0px 5px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  padding: 50px 100px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

@media screen and (max-width: 500px) {
  .about .heading_container {
    padding: 50px;
  }
}

.about .heading_container .main {
  font-size: 25px;
}

.about .heading_container .sub {
  margin-top: 5px;
  color: #979797;
  font-size: 14px;
  line-height: 27px;
}

.about .slider_section {
  width: 90%;
  margin: 40px auto 0px auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

.about .slider_section .slider {
  /* transition: all 3s; */
  height: 300px;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  object-fit: cover;
  
}

.about .slider_section .nav_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.about .slider_section .nav_container .x_box {
  width: 25px;
}

.about .slider_section .nav_container .dot_box {
  height: 8px;
  width: 8px;
  background-color: #FFFFFF;
  -webkit-box-shadow: 0px 0px 2px 1px #dadada;
          box-shadow: 0px 0px 2px 1px #dadada;
  border-radius: 50%;
  margin-right: 10px;
  position: relative;
  cursor: pointer;
}

.about .slider_section .nav_container .dot_box:hover {
  -webkit-box-shadow: 0px 0px 4px 1px #dadada;
          box-shadow: 0px 0px 4px 1px #dadada;
  height: 10px;
  width: 10px;
}

.about .slider_section .nav_container .dot_box:hover::after {
  height: 8px;
  width: 8px;
}

.about .slider_section .nav_container .dot_box::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: 6px;
  width: 6px;
  background-color: #919191;
  border-radius: 50%;
}

.about .slider_section .nav_container .dot_box.active {
  height: 12px;
  width: 12px;
  -webkit-box-shadow: 0px 0px 5px 1px #dadada;
          box-shadow: 0px 0px 5px 1px #dadada;
}

.about .slider_section .nav_container .dot_box.active::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: 8px;
  width: 8px;
  background-color: #666666;
  border-radius: 50%;
}

.about .slider_section .nav_container .dot_box:nth-last-child(1) {
  margin-right: 0px;
}

.footer {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 10px 50px 20px 50px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
          position:absolute;
          /* margin-top: 50px; */
          bottom:30;
          width:100%;
          height:30px;   /* Height of the footer */
          /* background:#6cf; */
}

.footer .first, .footer .second, .footer .third {
  width: 33.3%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  font-family: "Montserrat";
}

@media screen and (max-width: 768px) {
  .footer .first, .footer .second, .footer .third {
    margin: auto;
    width: 90%;
  }
}

.footer .first {
  padding: 0px 50px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.footer .first .logo_section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.footer .first .logo_section .logo {
  height: 40px;
  /* width: 50px; */
  /* background-color: #FF5722; */
  margin-right: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.footer .first .logo_section .logo .icon {
  height: 10px;
  width: 10px;
  border: 2px solid #FFFFFF;
  border-radius: 50%;
  background-color: #FF5722;
  position: relative;
  overflow: hidden;
}

.footer .first .logo_section .logo .icon::after {
  content: '';
  position: absolute;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background-color: #FFFFFF;
  top: 2px;
  left: 2px;
  -webkit-clip-path: inset(0px 2.5px 0px 0px);
          clip-path: inset(0px 2.5px 0px 0px);
}

.footer .first .logo_section .text {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.2px;
}

.footer .first .content {
  margin-top: 30px;
  font-family: "Montserrat";
  font-size: 14px;
  line-height: 27px;
}

.footer .second {
  padding: 0px 50px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.footer .second .header {
  font-weight: 600;
  line-height: 40px;
}

.footer .second .item {
  font-size: 14px;
  line-height: 25px;
}

.footer .third {
  padding: 0px 50px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.footer .third .header {
  font-weight: 600;
  line-height: 40px;
}

.footer .third .content {
  font-family: "Montserrat";
  font-size: 14px;
  line-height: 22px;
}

.footer .third .phone {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin-top: 20px;
  letter-spacing: 0.5px;
  font-family: "Montserrat";
}

@media screen and (max-width: 768px) {
  .footer .second,
  .footer .third {
    margin-top: 30px;
  }
}

.footer .first,
.footer .second,
.footer .third {
  -webkit-transition: all 500ms;
  transition: all 500ms;
}
.error_text {
  height: 30px;
  line-height: 30px;
  width: 100%;
  color: red;
  margin-top: 15px;
  text-align: center;
  font-family: "Montserrat";
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.3px;
}
@media screen and (max-width: 768px) {
  .footer .first,
  .footer .second,
  .footer .third {
    padding: 0px;
  }
}
.landing_top_fet {
  display: flex;
  justify-content: center;
  width: 80%;
}
.landing_top_fet_sec {
  flex: 1 1;
  display: flex;
  justify-content: center;
}
.landing_top_fet_sec {
  flex: 1 1;
  display: flex;
  justify-content: center;
}
.landing_top_fet_sec_col_cent, .landing_top_fet_sec_col_cent_img {
  display: flex;
  justify-content: center;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 600;
  color: #4d694c;
  text-align: center;
}
.landing_top_fet_sec_col_cent {
  padding-bottom: 20px;
}
.landing_top_fet_sec_col_cent_last {
  display: flex;
  justify-content: center;
  font-family: Montserrat;
  font-size: 11px;
  font-weight: 600;
  color: #878787;
  text-align: center;
  padding: 5px 40px;
}

@media screen and (max-width: 768px){
  .landing .sm_20 {
    padding: 20px;
    box-sizing: border-box;
}
.landing_top_fet {
  display: block!important;
  justify-content: center;
  width: 80%;
}
}

.datepickerpac {
  width: 70px !important;
}
.vend {
  font-weight: 600;
  font-family: "Montserrat";
  font-size: 14px;
  margin-bottom: 20px;
  margin-top: 12px;
}
.image-box {
  margin: 35px;
  width: 500px;
  height: 300px;
  overflow: hidden;
}

.image-box h4 {
  
    background-color: rgba(174, 0, 0, 0.51);
    position: absolute;
    bottom: 30px;
    left: 30px;
    width: 300px;
    padding: 20px;
    display: block;
    color: white;
    font-size: 24px;
  
}

.hover06 figure img {
	width: 62px;
	height: auto;
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}
.hover06 figure:hover img {
  width: 70px;
}
figure {
	width: 75px;
	height: 75px;
	margin: 0;
	padding: 0;
	background: #fff;
	overflow: hidden;
}


.inputbox-proof {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.btnproof {
  border: 2px solid gray;
  color: gray;
  background-color: white;
  padding: 25px 25px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
}

.inputbox-proof input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.counterbutton {
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  color: #fff;
  border-radius: 10px;
  background-color: #ff5722;
  margin: 0 4px;
  font-weight: 700;
  cursor: pointer;
}

.addbutton {
  color: #FF5722;
  font-size: 13px;
  font-weight: 500;
  background-color: #FFFFFF;
  border: 1px solid #FF5722;
  padding: 2px 10px;
  border-radius: 10px;
  margin-left: 15px;
  cursor: pointer;
}

.borderreup {
  background-color: white !important;
  color: black !important;
  border-width: 1 !important;
  border-color: #ff5722 !important;
  border-style: solid !important;
}


.myprice_detail_box {
  transition: all 200ms;
  padding-top: 40px;
}
.myprice_detail_box .center_box {
  display: flex;
  flex-direction: column;
  width: 90%;
  box-shadow: 0px 1px 7px 1px #dadada;
  border-radius: 5px;
  padding: 15px 20px;
  box-sizing: border-box;
  font-family: "Montserrat";
}

.myprice_detail_box .center_box .header {
  font-weight: 500;
  margin-bottom: 5px;
  font-size: 17px;
}
.myprice_detail_box .center_box .data {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
  font-size: 14px;
  font-weight: 500;
}

.boxme {
  margin: auto;
  width: 100%;
  max-width: 220px; 
  min-width: 200px;
  padding: 10px;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #aaa;
  box-shadow: 0 0 10px 3px #cacaca;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Montserrat;
  text-transform: uppercase;
}

.detailme .header {
  width: 100%;
  text-align: center;
  margin: 50px auto;
  font-family: Montserrat;
  letter-spacing: .3px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.boxme .content {
  line-height: 20px;
  margin: 10px 0 0;
  font-size: 13px;
  font-weight: 500;
}

.boxme .button {
  margin: 10px auto;
  background-color: #ff5722;
  border-radius: 100px;
  color: #fff;
  padding: 7px 15px;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.packagemainbox {
  display: flex;
  flex-direction: row;
  width:100%;
}

@media screen and (max-width: 900px) {
  .packagemainbox {
    display: flex;
    flex-direction: column;
    width:100%;
    margin-left: 15px;
  }
}
/* Absolute Center Spinner */
.loadingme {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loadingme:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
    background: radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0, .8));

  background: -webkit-radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0,.8));
}

/* :not(:required) hides these rules from IE9 and below */
.loadingme:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loadingme:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 150ms infinite linear;
  -moz-animation: spinner 150ms infinite linear;
  -ms-animation: spinner 150ms infinite linear;
  -o-animation: spinner 150ms infinite linear;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(255,255,255, 0.75) 1.5em 0 0 0, rgba(255,255,255, 0.75) 1.1em 1.1em 0 0, rgba(255,255,255, 0.75) 0 1.5em 0 0, rgba(255,255,255, 0.75) -1.1em 1.1em 0 0, rgba(255,255,255, 0.75) -1.5em 0 0 0, rgba(255,255,255, 0.75) -1.1em -1.1em 0 0, rgba(255,255,255, 0.75) 0 -1.5em 0 0, rgba(255,255,255, 0.75) 1.1em -1.1em 0 0;
box-shadow: rgba(255,255,255, 0.75) 1.5em 0 0 0, rgba(255,255,255, 0.75) 1.1em 1.1em 0 0, rgba(255,255,255, 0.75) 0 1.5em 0 0, rgba(255,255,255, 0.75) -1.1em 1.1em 0 0, rgba(255,255,255, 0.75) -1.5em 0 0 0, rgba(255,255,255, 0.75) -1.1em -1.1em 0 0, rgba(255,255,255, 0.75) 0 -1.5em 0 0, rgba(255,255,255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.table-main {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.table-header {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.table-section {

}

.table-image {
  width: 100px;
  height:auto;
}

.section-space {
  margin-left: 20px;
  margin-right: 20px;
}

.table-section2 {
  display: flex;
  flex-direction: column;
}
.table-section3 {

}

.table-section5 {

}

.myth{
  font-weight: normal;
}

.tdtable {
  padding-top: 10px;
  padding-bottom:10px;
 }
 .modalset {
   width: 500px;
 }

 @media screen and (max-width: 480px) {
  .modalset {
    width: 417 px;
  }
}

.boxsu {
  display: flex;
  flex-wrap: wrap;
}
.boxsu>* {
  flex: 1 1 160px;
}

table td {word-wrap:break-word;}
/* .respi {
  width: 417 px !important;
}

@media screen and (max-width: 480px) {
  .respi {
    width: 417 px;
  }
} */
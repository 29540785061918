@import url('https://fonts.googleapis.com/css?family=Montserrat+Alternates:400,500,700|Quicksand:400,500,700|Roboto:400,500,700');

* {
  outline: none;
  font-family: "Quicksand";
  user-select: none;
}

.table_main_box::-webkit-scrollbar-track {
  border-radius: 0px;
  background-color: #FFFFFF;
}

.table_main_box::-webkit-scrollbar {
  width: 5px;
  background-color: #FFFFFF;
}

.table_main_box::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #FF5722;
}

.checkbox+label {
  display: block;
  margin: 0.2em;
  cursor: pointer;
  padding: 0.2em;
  font-family: "Montserrat Alternates";
  transition: color 0.2s;
}

.wid_50 {
  width: 50px;
}

.checkbox {
  display: none;
}

.checkbox+label:before {
  content: "\2714";
  box-shadow: 0px 0px 2px 1px #dadada;
  border-radius: 0.2em;
  display: inline-block;
  width: 1em;
  height: 1em;
  padding: 0.1em 0.15em 0.2em 0.19em;
  margin-right: 0.7em;
  vertical-align: bottom;
  color: transparent;
  transition: .2s;
}

.checkbox+label:active:before {
  transform: scale(0);
}

.checkbox:checked+label {
  color: #FF5722;
  transition: color 0.2s;
}

.checkbox:checked+label:before {
  background-color: #FF5722;
  border-color: #FF5722;
  color: #fff;
  box-shadow: 0px 0px 0px 0px #dadada;
}

.checkbox:disabled+label:before {
  transform: scale(1);
  border-color: #aaa;
}

.checkbox:checked:disabled+label:before {
  transform: scale(1);
  background-color: #bfb;
  border-color: #bfb;
}

.flex_row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.bg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

body {
  background-color: #ffffff;
  margin: 0px;
  padding: 0px;
}

.nav_bar {
  height: 70px;
  width: 100%;
  border-bottom: 1px solid #dadada;
  display: flex;
  padding: 0px 30px;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;
  box-shadow: 0px 0px 3px 1px #dadada;
  position: fixed;
  left: 0px;
  top: 0px;
  background-color: #ffffff;
  z-index: 10;
}

.nav_bar .logout_button {
  padding: 7px 22px;
  color: #ffffff;
  background-color: #FF5722;
  font-size: 13px;
  border-radius: 17px;
  font-weight: 500;
  cursor: pointer;
}

.subcat_button {
  padding: 3px 11px;
  color: #ffffff;
  background-color: #FF5722;
  font-size: 13px;
  border-radius: 17px;
  font-weight: 500;
  cursor: pointer;
}
// .nav_bar .logo {
//   height: 37px;
//   width: 37px;
//   margin-right: 12px;
//   background-image: url(../../assets/logo.png);
// }

.nav_bar .logo_section {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.logo_section .text {
  font-family: "Roboto";
  letter-spacing: 0.2px;
}

.search_bar {
  border: 0;
  box-shadow: 0 0 2px 1px #dadada;
  border-radius: 60px;
  padding: 8px 20px;
  width: 40%;
  font-weight: 500;
  display: block;
  margin-right: 20px;
  text-align: left;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.superbutton {
  background-color: #FF5722; 
  border-radius: 100px;
  border: none;
  color: white;
  padding: 10px 27px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
}

.button2:hover {
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);
}

.body_container {
  // height: calc(100vh - 70px);
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
}

.body_container .taskbar {
  height: 100%;
  width: 250px;
  padding: 40px 30px;
  box-sizing: border-box;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
}

.body_container .taskbar::-webkit-scrollbar-track {
  background-color: #ffffff;
}

.body_container .taskbar::-webkit-scrollbar {
  width: 6px;
  background-color: #ffffff;
}

.body_container .taskbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #919191;
}

.taskbar .item_container {
  width: 100%;
  position: relative;
  margin: 3px 0px;
}

.taskbar .item_container .sub_menu_container {
  background-color: #f1f1f1;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 3px 10px 5px 30px;
  box-sizing: border-box;
  position: relative;
  top: -2px;
  font-size: 14px;
}

.taskbar .item_container .sub_menu_container .sub_item {
  box-sizing: border-box;
  padding: 3px 15px;
  font-family: "Quicksand";
  font-weight: 500;
  border-radius: 5px;
  cursor: pointer;
  transition: all 400ms;
}

.taskbar .item_container .sub_menu_container .sub_item:hover {
  background-color: #FF5722;
  color: #FFFFFF;
  transition: all 400ms;
}
.taskbar .item_container .sub_menu_container .sub_item.active {
  background-color: #FF5722;
  color: #FFFFFF;
  transition: all 400ms;
}

.taskbar .item_container .image {
  position: absolute;
  height: 23px;
  width: 23px;
  /* top: 50%; */
  left: 20px;
  top: 17px;
  transform: translate(-50%, -50%);
  z-index: 2;
  background-position: center;
  background-repeat: no-repeat;
  /* background-color: yellow; */
  background-size: 80%;
}
.add_button_image_img {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 20px;
  height: 30px;
  width: 30px;
  background: url(../../assets/add-icon.svg);
  padding: 30px 0px 0px 0px;

}
.taskbar .item {
  width: 100%;
  margin-top: 3px;
  background-color: #ffffff;
  padding: 7px 0px 7px 45px;
  position: relative;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  user-select: none;
  box-sizing: border-box;
}

.taskbar .item:hover {
  background-color: #f3f3f3;
}

.taskbar .item.active {
  background-color: #FF5722;
  color: #ffffff;
}

/* .taskbar .item.active::after {
  content: "";
  height: 6px;
  width: 6px;
  border: 2px solid #ffffff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translate(-50%, -50%);
} */

/* .taskbar .item::after {
  content: "";
  height: 6px;
  width: 6px;
  border: 2px solid black;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translate(-50%, -50%);
} */

.body_container .inner_body {
  height: calc(100vh - 70px);
  width: calc(100% - 250px);
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 30px;
  box-sizing: border-box;
  position: relative;
}

.body_container .inner_body::-webkit-scrollbar-track {
  background-color: #ffffff;
}

.body_container .inner_body::-webkit-scrollbar {
  width: 6px;
  background-color: #ffffff;
}

.body_container .inner_body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #919191;
}

.table_main_box {
  box-shadow: 0px 0px 3px 1px #cdcdcd;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  overflow-x: hidden;
}

.table_main_box .pagination_container {
  box-sizing: border-box;
  width: 100%;
  padding: 30px 50px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  border-top: 1px solid #dadada;
}

.pagination_container .left_button {
  height: 20px;
  width: 20px;
  background-color: #FF5722;
  border-radius: 3px;
  margin-right: 5px;
  background-image: url(../../assets/down-arrow.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 60%;
  transform: rotate(90deg);
  cursor: pointer;
}

.pagination_container .right_button {
  height: 20px;
  width: 20px;
  background-color: #FF5722;
  border-radius: 3px;
  margin-left: 1px;
  background-image: url(../../assets/down-arrow.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 60%;
  transform: rotate(-90deg);
  cursor: pointer;
}

.pagination_container .button {
  min-height: 20px;
  min-width: 20px;
  background-color: #ffffff;
  border-radius: 50%;
  font-size: 14px;
  text-align: center;
  margin-right: 4px;
  cursor: pointer;
  padding: 5px;
  background-color: #f6f7f8;
  transition: all 500ms;
}

.pagination_container .button:hover {
  background-color: #eaeaea;
  transition: all 500ms;
}

.table_main_box .header_container {
  width: 100%;
  padding: 20px 30px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  border-bottom: 1px solid #dadada;
}

.table_main_box .header_container .button_container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.table_main_box .header_container .header {
  font-size: 14px;
  font-weight: 500;
}

.table_main_box .header_container .add_button {
  padding: 5px 20px 7px 20px;
  color: #ffffff;
  background-color: #FF5722;
  font-size: 13px;
  border-radius: 17px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table_main_box .header_container .delete_button {
  height: 27px;
  width: 27px;
  margin-right: 10px;
  /* background-image: url(../assets/waste-bin.svg); */
  background-position: center;
  background-size: 90%;
  background-repeat: no-repeat;
  cursor: pointer;
  position: relative;
}

.table_main_box .header_container .delete_button .count {
  position: absolute;
  top: -4px;
  right: -4px;
  background-color: #009966;
  padding: 5px;
  border-radius: 50px;
  font-size: 10px;
  color: #FFFFFF;
  min-width: 12px;
  text-align: center;
  transform: scale(0.7);
}

.table_main_box .header_container .search_bar {
  border: 0px;
  box-shadow: 0px 0px 2px 1px #dadada;
  border-radius: 60px;
  padding: 8px 20px;
  width: 40%;
  font-weight: 500;
  display: block;
  margin-right: 20px;
  text-align: left;
}

.table_main_box .header_container .search_bar::placeholder {
  text-align: center;
}

.table_main_box .header_container .add_button span {
  font-size: 17px;
  margin-right: 5px;
}

.inner_table_container {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  font-size: 14px;
}

.inner_table_container .header td {
  padding: 10px 5px;
  font-weight: 500;
  box-sizing: border-box;
  border-collapse: collapse;
}

.inner_table_container .body {
  border-top: 1px solid #dadada;
}

.inner_table_container .body td {
  padding: 20px 5px;
  box-sizing: border-box;
  border-collapse: collapse;
  font-family: "Roboto";
}

.inner_table_container .body:nth-child(1) td {
  border-top: 0px solid #dadada;
}

.inner_table_container .body .button_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.inner_table_container .button {
  background-color: #FF5722;
  padding: 5px 20px 7px 20px;
  margin: 0px 5px;
  color: #ffffff;
  font-weight: 500;
  border-radius: 50px;
  cursor: pointer;
}
.inner_table_container .button_withicon {
  /* background-color: #FF5722; */
  padding: 0px;
  margin: 0px 5px;
  color: #ffffff;
  font-weight: 500;
  border-radius: 50px;
  cursor: pointer;
}

.text-right {
  text-align: right;
}

.table_main_box .input_box_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 20px;
  box-sizing: border-box;
  border-top: 1px solid #dadada;
}

.half_section {
  width: 70%;

  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.half_section.f {
  width: 30%;
}

.flex_end {
  display: flex;
  justify-content: flex-end;
  padding-right: 5px;
}

.flex_start {
  justify-content: flex-start;
  padding-left: 5px;
}

.flex_col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.flex_row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.pad-5-15 {
  padding: 5px 15px;
}

.down-3 {
  position: relative;
  top: 1.5px;
}

.input_box_container .text {
  margin-right: 20px;
  font-weight: 500;
  font-size: 15px;
}

.input_box_container .input_text {
  padding: 5px 10px;
  font-size: 14px;
  border: 0px;
  box-shadow: 0px 0px 2px 1px #dadada;
  font-family: "Roboto";
  letter-spacing: 0.2px;
  border-radius: 2px;
}

select {
  font-weight: 500;
  padding: 4px 10px;
}

.publish_button {
  background-color: #FF5722;
  padding: 5px 20px 6px 20px;
  cursor: pointer;
  color: #ffffff;
  border-radius: 50px;
  font-weight: 500;
  font-size: 15px;
}

// .nav_bar .noti_container {
//   height: 30px;
//   width: 30px;
//   margin-right: 10px;
//   background-image: url(../assets/notification.svg);
//   background-position: center;
//   background-repeat: no-repeat;
//   background-size: 100%;
//   position: relative;
//   transform: scale(0.8);
//   cursor: pointer;
// }

.noti_container .counter {
  background-color: #009966;
  min-width: 15px;
  padding: 5px;
  border-radius: 50%;
  color: #ffffff;
  font-size: 10px;
  font-weight: 500;
  text-align: center;
  position: absolute;
  top: 0px;
  left: 0px;
  transform: scale(0.9);
}

.notification_box_container {
  position: absolute;
  width: 300px;
  background-color: #FFFFFF;
  top: 25px;
  right: 20px;
  box-shadow: 0px 0px 2px 2px #dadada;
  border-radius: 5px;
  padding-top: 15px;
  box-sizing: border-box;
  z-index: 1;

}

.scroll_box {
  height: 300px;
  overflow-x: scroll;
}

.scroll_box::-webkit-scrollbar-track {
  border-radius: 0px;
  background-color: #FFFFFF;
}

.scroll_box::-webkit-scrollbar {
  width: 5px;
  background-color: #FFFFFF;
}

.scroll_box::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #FF5722;
}

.notification_box_container::after {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  background-color: #FFFFFF;
  left: calc(57% - 10px);
  top: -7px;
  transform: translate(-50%, -50%);
  transform: rotate(45deg);
  box-shadow: 0px 0px 2px 2px #dadada;
  clip-path: inset(-3px 5px 5px -3px);
  pointer-events: none;
}

.notification_box_container .notification {
  width: 100%;
  padding: 10px 10px;
  box-sizing: border-box;
  border-bottom: 1px solid #efefef;
  font-size: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

// .notification_box_container .notification .icon {
//   height: 40px;
//   width: 40px;
//   /* background-color: #dadada; */
//   border-radius: 50%;
//   background-image: url(../assets/user.svg);
//   background-position: center;
//   background-repeat: no-repeat;
//   background-size: 100%;
// }

.notification_box_container .notification .data_box {
  display: column;
  align-items: flex-start;
  padding: 3px 10px 3px 20px;;
  width: calc(100% - 80px);
}

.notification_box_container .notification .data_box .title {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.3px;
}

.notification_box_container .notification .data_box .user {
  font-size: 13px;
  color: #aaaaaa;
  letter-spacing: 0.1px;
  font-family: "Roboto";
}

.notification_box_container .notification .tick_box {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notification_box_container .notification .tick_box .tick {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-image: url(../../assets/success.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 87%;
  cursor: pointer;
}

.input_box_container .add_image_box {
  height: 60px;
  width: 50px;
  border-radius: 5px;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px 0px 0px 9px;
  box-sizing: border-box;
  /* box-shadow: 0px 0px 2px 1px #dadada; */
  cursor: pointer;
  transition: box-shadow 200ms;
  border: 1px dotted #aaaaaa;
}

.input_box_container .add_image_box:hover {
  box-shadow: 0px 0px 1px 1px #dadada;
  transition: box-shadow 200ms;
}

.input_box_container .add_image_box .add_button {
  height: 30px;
  width: 30px;
  background-color: #FF5722;
  border-radius: 50%;
  background-image: url(../../assets/add-icon.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
}
.input_box_container .category_main_box {
  width: 90%;
  min-height: 300px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  border: 1px solid #dadada;
}

.category_main_box .half_section {
  width: 50%;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 300px;
  flex-wrap: nowrap;
  box-sizing: border-box;
  padding: 5px 15px;
}

.category_main_box .half_section::-webkit-scrollbar-track {
  border-radius: 0px;
  background-color: #FFFFFF;
}

.category_main_box .half_section::-webkit-scrollbar {
  width: 5px;
  background-color: #FFFFFF;
}

.category_main_box .half_section::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #FF5722;
}



.category_main_box .half_section .search_bar {
  width: 100%;
  padding: 13px 10px;
  box-sizing: border-box;
  background-color: #FFFFFF;
  border: 0px;
  border-bottom: 1px solid #dadada;
  font-weight: 500;
  letter-spacing: 0.2px;
}

.category_main_box .half_section.left {
  border-right: 1px solid #dadada;
  align-items: flex-start;
}

.category_main_box .half_section .cat_box {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  background-color: #fafafa;
  font-family: "Montserrat Alternates";
  margin: 2px 0px;
}

.category_main_box .half_section .cat_box .cat {
  padding: 2px 5px;
  font-size: 15px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.category_main_box .half_section.left .cat_box .icon {
  height: 30px;
  width: 30px;
  background-color: #999999;
  cursor: pointer;
  transition: background-color 300ms;
  background-image: url(../../assets/add-icon.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 35%;
}

.category_main_box .half_section.left .cat_box .icon:hover {
  background-color: #555555;
  transition: background-color 300ms;
}
.category_main_box .half_section.left .cat_box .icon_active {
  background-color: #555555;
  transition: background-color 300ms;
}


.category_main_box .half_section.right .cat_box .icon {
  height: 30px;
  width: 30px;
  background-color: #999999;
  cursor: pointer;
  transition: background-color 300ms;
  background-image: url(../../assets/cross-icon.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 35%;
}

.category_main_box .half_section.right .cat_box .icon:hover {
  background-color: red;
  transition: background-color 300ms;
}

select {
  font-weight: 500;
  padding: 4px 10px;
}

.publish_button {
  background-color: #FF5722;
  padding: 5px 20px 6px 20px;
  cursor: pointer;
  color: #ffffff;
  border-radius: 50px;
  font-weight: 500;
  font-size: 15px;
}
.product_table {
  /* min-height: 176px; */
  min-width: 50%;
  text-align: center;
}
.product_table_tr {
  height: 45px;
}
.product-image-list {
  width: 35px;
  height: 33px;
  border-radius: 50%
}
.link_underline {
  text-decoration: none;
  color: #000102;
}
.radio_box {
  display: none;
  position: relative;
}

.radio_box+label {
  position: relative;
  padding-left: 27px;
  cursor: pointer;
  font-size: 15px;
  font-family: "Montserrat Alternates"
}

.radio_box+label:before {
  content: "";
  height: 20px;
  width: 20px;
  background-color: #FF5722;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 50%;
  vertical-align: baseline;
}

.radio_box:checked+label:after {
  content: "";
  height: 10px;
  width: 10px;
  background-color: #FFFFFF;
  position: absolute;
  top: 5px;
  left: 5px;
  border-radius: 50%;
}

.radio_box:checked+label {
  color: #FF5722;
}

.alert_container {
 
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 10px 4px #bdbdbd;
  z-index: 10;
  border-radius: 2px;
  padding: 40px 60px;
  box-sizing: border-box;
}

.alert_container .text {
  font-weight: 500;
  width: 100%;
}

.alert_container .button_container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 50px;
}

.alert_container .button_container .button {
  padding: 7px 15px;
  background-color: #FF5722;
  color: #FFFFFF;
  font-family: "Montserrat Alternates";
  border: 0px;
  border-radius: 2px;
  font-size: 15px;
  cursor: pointer;
  font-weight: 500;
}

.alert_container .button_container .button.cancel {
  background-color: #009966;
  margin-right: 10px;
}
select {
  font-weight: 500;
  padding: 4px 10px;
  background-color: #FFFFFF;
  border-radius: 3px;
  cursor: pointer;
}


.order_status_icons {
  width: 32px;
  height: 24px;
}
.order_status_icons_del {
  width: 32px;
  height: 18px;
}






.flip-box {
  background-color: transparent;
  width: 80px;
  height: 60px;
  /* border: 1px solid #f1f1f1; */
  margin-left: 10px;
  perspective: 1000px;
}

.flip-box-inner {
  position: relative;
  width: 80px;
  height: 60px;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-box:hover .flip-box-inner {
  transform: rotateY(180deg);
}

.flip-box-front, .flip-box-back {
  position: absolute;
  width: 80px;
  height: 60px;
  backface-visibility: hidden;
}

.flip-box-front {
  /* background-color: #bbb; */
  color: black;
  border-radius: 10px;
}

.flip-box-back {
  background-color: #555;
  /* color: white; */
  border-radius: 10px;
  transform: rotateY(180deg);
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.error_text {
  /* height: 30px; */
  /* line-height: 30px; */
  /* width: 100%; */
  color: red;
  /* margin-top: 15px; */
  text-align: center;
  font-family: "Quicksand";
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.3px;
}

.inner_body .dashboard_container {
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: column;
}

.dashboard_container .top_flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.dashboard_container .top_flex .item_box {
  display: flex;
  flex-direction: column;
  min-width: 310px;
  min-height: 200px;
  background-color: #FFFFFF;
  border-radius: 1px;
  box-shadow: 0px 0px 5px 1px rgba(20, 20, 20, 0.2);
  padding: 7px;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.dashboard_container .top_flex .item_box .header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-family: "Quicksand";
  color: #888888;
  font-size: 15px;
  height: 30px;
  align-items: center;
  padding-right: 10px;
  box-sizing: border-box;
  font-weight: 500;
}

.dashboard_container .top_flex .item_box .header span {
  color: #FF5722;
  margin-left: 5px;
}

.dashboard_container .top_flex .item_box .content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 90px;
}

.dashboard_container .top_flex .item_box .content .count {
  color: #333333;
  font-family: "Quicksand";
  /* font-weight: 500; */
  font-size: 42px;
  margin-bottom: 5px;
}

.dashboard_container .top_flex .item_box .content .text {
  color: #AAAAAA;
  font-family: "Quicksand";
  font-size: 13px;
  font-weight: 500;
}

.dashboard_container .top_flex .item_box .footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-end;
  height: 70px;
}

.dashboard_container .top_flex .item_box .footer .box {
  padding: 5px;
  box-sizing: border-box;
  text-align: center;
}

.dashboard_container .top_flex .item_box .footer .box .text {
  color: #AAAAAA;
  font-weight: 500;
  font-family: "Quicksand";
  font-size: 13px; 
}

.dashboard_container .top_flex .item_box .footer .box .count {
  color: #666666;
  font-weight: 500;
  font-family: "Quicksand";
  font-size: 13px; 
  margin-top: 4px;
}

.dashboard_container .graph_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 30px;
}

.dashboard_container .graph_container .box_container {
  padding: 20px;
  box-shadow: 0px 0px 10px 2px rgba(50, 50, 50, 0.1);
  box-sizing: border-box;
}
@import url(https://fonts.googleapis.com/css?family=Montserrat+Alternates:400,500,700|Montserrat:400,500,600,700|Quicksand:400,500,700|Roboto:400,500,700);
@import url(https://fonts.googleapis.com/css?family=Montserrat+Alternates:400,500,700|Montserrat:400,500,600,700|Quicksand:400,500,700|Roboto:300,400,500,700);
@import url(https://fonts.googleapis.com/css?family=Montserrat+Alternates:400,500,700|Quicksand:400,500,700|Roboto:400,500,700);
/* * {
  outline: none;
  user-select: none;
  
  
} */
/* * { 
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
} */
input, textarea {
  -webkit-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important;
  user-select: text !important;
}
select {
  font-weight: 500;
  padding: 4px 10px;
  background-color: #FFFFFF;
  border-radius: 3px;
  cursor: pointer;
}
.package .body .data_box .top_section .package_details .package_container .data_container .button_box .sq_button::-webkit-inner-spin-button, 
.package .body .data_box .top_section .package_details .package_container .data_container .button_box .sq_button::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
}

::-webkit-scrollbar-track {
  background-color: #ffffff;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: #ffffff;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #919191;
}

.checkout3 .checkout_container .text, .checkout3 .payment_container .text, .checkout2 .checkout_container .text, .checkout2 .payment_container .text, .checkout1 .checkout_container .text, .checkout1 .payment_container .text {
  font-weight: 500;
  font-family: "Montserrat";
  font-size: 19px;
}

body {
  margin: 0px;
  padding: 0px;
  background-color: #FFFFFF;
height: 100%;
}

#container {
  height:100%;
  min-height:100%;
  position:relative;
}

.nav_bar {
  height: 80px;
  width: 100%;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  overflow: hidden;
  box-shadow: 0px 0px 3px 1px #bababa;
  position: fixed;
  z-index: 100;
}

.nav_bar .left_section {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.nav_bar .left_section .logo {
  height: 55px;
  width: 80px;
  /* background-color: #FF5722; */
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav_bar .left_section .logo .icon {
  height: 10px;
  width: 10px;
  border: 2px solid #FFFFFF;
  border-radius: 50%;
  background-color: #FF5722;
  position: relative;
  overflow: hidden;
}

.nav_bar .left_section .logo .icon::after {
  content: '';
  position: absolute;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background-color: #FFFFFF;
  top: 2px;
  left: 2px;
  -webkit-clip-path: inset(0px 2.5px 0px 0px);
          clip-path: inset(0px 2.5px 0px 0px);
}

.nav_bar .left_section .text {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.2px;
}

.nav_bar .right_section {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 500;
}
.nav_bar .right_section_mob {
  display: none;
}

@media screen and (max-width: 768px) {
  .nav_bar .right_section {
    display: none;
  }
  .nav_bar .right_section_mob {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 500;
  }
  .right_section_mob .item {
    /* margin-right: 20px; */
    font-family: "Montserrat";
    font-size: 20px;
    letter-spacing: 0.1px;
    color: #222222;
    font-weight: 500;
    cursor: pointer;
    text-align: center;
    margin-top: 15px;
  }
  
  .right_section_mob .button {
    cursor: pointer;
    -webkit-user-select: non;
       -moz-user-select: non;
        -ms-user-select: non;
            user-select: non;
    padding: 5px 10px;
    background-color: #FF5722;
    color: #FFFFFF;
    font-family: "Montserrat";
    border-radius: 50px;
    font-size: 20px;
    text-align: center;
    /* margin-left: 50px;
    margin-right: 50px; */
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
    margin-top: 15px;

  }
  .toggle_button {
    width: 28px;
    height: 30px;
    margin: 10px auto;
  }
  .toggle_button_div {
    width: 100%;
    height: 4px;
    background: rgb(255, 87, 34);
    margin: 4px auto;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    
  }
  .toggle_button_div_2 {
    width: 100%;
    height: 4px;
    background: rgb(255, 87, 34);
    margin: 4px auto;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-transform: rotate(45deg) translate(5px, 5px);
            transform: rotate(45deg) translate(5px, 5px);
  }
  .toggle_button_div_3 {
    opacity: 0;
  }
  .toggle_button_div_4 {
    width: 100%;
    height: 4px;
    background: rgb(255, 87, 34);
    margin: 4px auto;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-transform: rotate(-45deg) translate(1.5px, -1px);
            transform: rotate(-45deg) translate(1.5px, -1px);
  }
  .handbar_div {
 
    /* height: calc(100% - 80px); */
    width: 50%;
    padding: 20px 10px 40px 10px;
    box-sizing: border-box;
    box-shadow: inset 0px 0px 2px 1px #dadada;
    z-index: 999;
    border-bottom-left-radius:7px; 
    min-width: 250px;
    position: fixed;
    top: 80px;
    right: 0px;
    background-color: white;
    /* transition: all 1s; */
    align-items: center;
    font-weight: 500;
  }
}

.nav_bar .right_section .item {
  margin-right: 20px;
  font-family: "Montserrat";
  font-size: 14px;
  letter-spacing: 0.1px;
  color: #222222;
  /* font-weight: 500; */
  cursor: pointer;
}
.landing .add_p_box .circle_box {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
}

.landing .add_p_box .circle_box .text {
  width: 100%;
  text-align: center;
  margin-top: 5px;
  font-size: 14px;
  font-weight: 600;
}
.nav_bar .right_section .button {
  cursor: pointer;
  -webkit-user-select: non;
     -moz-user-select: non;
      -ms-user-select: non;
          user-select: non;
  padding: 10px 20px;
  background-color: #FF5722;
  color: #FFFFFF;
  font-family: "Montserrat";
  border-radius: 50px;
  font-size: 14px;
  margin-left: 20px;
}

.checkout3 {
  padding-top: 80px;
}

.checkout3 .welcome_container {
  width: 100%;
  height: 250px;
  background-image: -webkit-gradient(linear, left top, right top, from(#FF5722), to(#CE3407));
  background-image: -webkit-linear-gradient(left, #FF5722, #CE3407);
  background-image: linear-gradient(to right, #FF5722, #CE3407);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

.checkout3 .welcome_container .decor {
  width: 220px;
  height: 110px;
  position: absolute;
  top: 0px;
  background-repeat: no-repeat;
  background-size: 80%;
  background-position-y: 0;
  background-position-y: initial;
}

.checkout3 .welcome_container .decor.left {
  background-position-x: 0;
  background-position-x: initial;
  background-image: url(/static/media/decore-left.8d502445.png);
  left: 0px;
}

.checkout3 .welcome_container .decor.right {
  background-position-x: right;
  background-image: url(/static/media/decore-right.2d8a112b.png);
  right: 0px;
}

.checkout3 .welcome_container .box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.checkout3 .welcome_container .box .circle {
  background-image: url(/static/media/badge.9f54380e.png);
  height: 100px;
  width: 100px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.checkout3 .welcome_container .box .text {
  font-family: "Montserrat";
  color: #FFFFFF;
  font-weight: bold;
  font-size: 22px;
  margin-top: 10px;
}

.checkout3 .checkout_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding-top: 70px;
}

.checkout3 .checkout_container .circle_container {
  width: 100%;
  box-sizing: border-box;
  padding-top: 50px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.checkout3 .checkout_container .circle_container .circle {
  color: #FFFFFF;
  padding: 10px;
  background-color: #FFFFFF;
  height: 30px;
  width: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
  font-size: 22px;
  font-weight: bold;
  font-family: "Montserrat";
  position: relative;
}

.checkout3 .checkout_container .circle_container .circle::after {
  content: "";
  height: 4px;
  width: 500px;
  position: absolute;
  background-color: #FFFFFF;
  top: 24px;
  right: 0px;
  z-index: -1;
}

.checkout3 .checkout_container .circle_container .circle._1, .checkout3 .checkout_container .circle_container .circle._2, .checkout3 .checkout_container .circle_container .circle._3 {
  background-color: #FF5722;
}

.checkout3 .checkout_container .circle_container .circle._1::after, .checkout3 .checkout_container .circle_container .circle._2::after, .checkout3 .checkout_container .circle_container .circle._3::after {
  background-color: #FF5722;
}

.checkout3 .payment_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 70px;
}

.checkout3 .payment_container .payment_row {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 50px;
  margin-bottom: 50px;
}

.checkout3 .payment_container .payment_row .payment_option_box, .checkout3 .payment_container .payment_row .card_box, .checkout3 .payment_container .payment_row .price_detail_box {
  width: 33.33%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .checkout3 .payment_container .payment_row .payment_option_box, .checkout3 .payment_container .payment_row .card_box, .checkout3 .payment_container .payment_row .price_detail_box {
    margin: auto;
    width: 90%;
  }
}

.checkout3 .payment_container .payment_row .payment_option_box, .checkout3 .payment_container .payment_row .price_detail_box {
  -webkit-transition: all 200ms;
  transition: all 200ms;
  padding-top: 40px;
}

.checkout3 .payment_container .payment_row .payment_option_box .center_box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.checkout3 .payment_container .payment_row .payment_option_box .center_box .header {
  font-family: "Montserrat";
  font-size: 17px;
  margin-bottom: 20px;
}

.checkout3 .payment_container .payment_row .payment_option_box .center_box .item {
  display: flex;
  flex-direction: row;
}

.checkout3 .payment_container .payment_row .payment_option_box .center_box .item .radio {
  display: none;
}

.checkout3 .payment_container .payment_row .payment_option_box .center_box .item .radio + label {
  margin-top: 15px;
  font-family: "Montserrat";
  position: relative;
  font-size: 16px;
  left: 20px;
  cursor: pointer;
}

.checkout3 .payment_container .payment_row .payment_option_box .center_box .item .radio + label:active:before {
  -webkit-transform: scale(0);
          transform: scale(0);
}

.checkout3 .payment_container .payment_row .payment_option_box .center_box .item .radio + label::before {
  content: '';
  position: absolute;
  left: -25px;
  top: 0px;
  height: 16px;
  width: 16px;
  border: 1px solid #aaaaaa;
  border-radius: 50%;
}

.checkout3 .payment_container .payment_row .payment_option_box .center_box .item .radio + label::after {
  content: '';
  position: absolute;
  left: -21px;
  top: 4px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #FFFFFF;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.checkout3 .payment_container .payment_row .payment_option_box .center_box .item .radio:checked + label::after {
  background-color: #FF5722;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.checkout3 .payment_container .payment_row .card_box {
  -webkit-transition: all 200ms;
  transition: all 200ms;
}

@media screen and (max-width: 768px) {
  .checkout3 .payment_container .payment_row .card_box {
    margin-top: 50px;
  }
}

.checkout3 .payment_container .payment_row .card_box .box {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  border: 1px solid #aaaaaa;
  box-sizing: border-box;
  padding: 30px;
  display: flex;
  flex-direction: column;
}

.checkout3 .payment_container .payment_row .card_box .box .card_no_box .text, .checkout3 .payment_container .payment_row .card_box .box .expiry_box .column .text {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
}

.checkout3 .payment_container .payment_row .card_box .box .card_no_box input, .checkout3 .payment_container .payment_row .card_box .box .expiry_box .column input {
  font-family: "Montserrat";
  border-radius: 2px;
  border: 1px solid #aaaaaa;
  margin-top: 10px;
  font-size: 15px;
  padding: 10px;
  box-sizing: border-box;
}

.checkout3 .payment_container .payment_row .card_box .box .card_no_box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@media screen and (max-width: 768px) {
  .checkout3 .payment_container .payment_row .card_box .box .card_no_box {
    margin-top: 30px;
  }
}

.checkout3 .payment_container .payment_row .card_box .box .card_no_box input {
  width: 100%;
}

.checkout3 .payment_container .payment_row .card_box .box .card_no_box.top_gap {
  margin-top: 20px;
}

.checkout3 .payment_container .payment_row .card_box .box .expiry_box {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.checkout3 .payment_container .payment_row .card_box .box .expiry_box .column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
  margin-right: 20px;
}

.checkout3 .payment_container .payment_row .card_box .box .expiry_box .column input {
  width: 130px;
}

@media screen and (max-width: 768px) {
  .checkout3 .payment_container .payment_row .card_box .box .expiry_box .column input {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .checkout3 .payment_container .payment_row .price_detail_box {
    margin-top: 30px;
  }
}

.checkout3 .payment_container .payment_row .price_detail_box .center_box {
  display: flex;
  flex-direction: column;
  width: 90%;
  box-shadow: 0px 1px 7px 1px #dadada;
  border-radius: 5px;
  padding: 15px 20px;
  box-sizing: border-box;
  font-family: "Montserrat";
}

.checkout3 .payment_container .payment_row .price_detail_box .center_box .header {
  font-weight: 500;
  margin-bottom: 5px;
  font-size: 17px;
}

.checkout3 .payment_container .payment_row .price_detail_box .center_box .data {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
  font-size: 14px;
  font-weight: 500;
}

.checkout3 .payment_container .payment_row .price_detail_box .center_box .data .right.orange {
  color: #FF5722;
}

.checkout3 .payment_container .payment_row .price_detail_box .center_box .line {
  height: 0px;
  width: 100%;
  background-color: #eaeaea;
  height: 1px;
  margin-top: 20px;
}

.checkout3 .buy_button_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.checkout3 .buy_button_container {
  margin-bottom: 70px;
}

.checkout3 .buy_button_container .button {
  background-color: #FF5722;
  color: #FFFFFF;
  font-family: "Montserrat";
  padding: 10px 70px;
  font-size: 13px;
  border-radius: 3px;
  cursor: pointer;
}

.checkout2 {
  padding-top: 80px;
}

.checkout2 .welcome_container {
  width: 100%;
  height: 250px;
  background-image: -webkit-gradient(linear, left top, right top, from(#FF5722), to(#CE3407));
  background-image: -webkit-linear-gradient(left, #FF5722, #CE3407);
  background-image: linear-gradient(to right, #FF5722, #CE3407);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

.checkout2 .welcome_container .decor {
  width: 220px;
  height: 110px;
  position: absolute;
  top: 0px;
  background-repeat: no-repeat;
  background-size: 80%;
  background-position-y: 0;
  background-position-y: initial;
}

.checkout2 .welcome_container .decor.left {
  background-position-x: 0;
  background-position-x: initial;
  background-image: url(/static/media/decore-left.8d502445.png);
  left: 0px;
}

.checkout2 .welcome_container .decor.right {
  background-position-x: right;
  background-image: url(/static/media/decore-right.2d8a112b.png);
  right: 0px;
}

.checkout2 .welcome_container .box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.checkout2 .welcome_container .box .circle {
  background-image: url(/static/media/badge.9f54380e.png);
  height: 100px;
  width: 100px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.checkout2 .welcome_container .box .text {
  font-family: "Montserrat";
  color: #FFFFFF;
  font-weight: bold;
  font-size: 22px;
  margin-top: 10px;
}

.checkout2 .checkout_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding-top: 70px;
}

.checkout2 .checkout_container .circle_container {
  width: 100%;
  box-sizing: border-box;
  padding-top: 50px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.checkout2 .checkout_container .circle_container .circle {
  color: #FFFFFF;
  padding: 10px;
  background-color: #FFFFFF;
  height: 30px;
  width: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
  font-size: 22px;
  font-weight: bold;
  font-family: "Montserrat";
  position: relative;
}

.checkout2 .checkout_container .circle_container .circle::after {
  content: "";
  height: 4px;
  width: 500px;
  position: absolute;
  background-color: #FFFFFF;
  top: 24px;
  right: 0px;
  z-index: -1;
}

.checkout2 .checkout_container .circle_container .circle._1, .checkout2 .checkout_container .circle_container .circle._2 {
  background-color: #FF5722;
}

.checkout2 .checkout_container .circle_container .circle._3 {
  height: 22px;
  width: 22px;
  line-height: 22px;
  color: #999999;
  border: 4px solid #999999;
}

.checkout2 .checkout_container .circle_container .circle._1::after, .checkout2 .checkout_container .circle_container .circle._2::after {
  background-color: #FF5722;
}

.checkout2 .payment_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 70px;
}

.checkout2 .payment_container .address_box {
  -webkit-transition: all 300ms;
  transition: all 300ms;
  width: 90%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-shadow: 0px 0px 6px 1px #dadada;
  margin: 30px 0px;
  border-radius: 5px;
  padding: 20px;
  box-sizing: border-box;
  overflow-x: hidden;
}

.checkout2 .payment_container .address_box .left_section {
  display: flex;
  flex-direction: column;
  padding: 10px;
  box-sizing: border-box;
  width: 50%;
  min-width: 400px;
}

@media screen and (max-width: 500px) {
  .checkout2 .payment_container .address_box .left_section {
    width: 100%;
    min-width: 0px;
  }
}

.checkout2 .payment_container .address_box .input_box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100;
  margin-bottom: 20px;
}

.checkout2 .payment_container .address_box .input_box .text {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
  width: 100%;
  max-width: 400px;
}

.checkout2 .payment_container .address_box .input_box .input {
  font-family: "Montserrat";
  letter-spacing: 0.3px;
  font-size: 15px;
  border-radius: 2px;
  padding: 7px 10px;
  width: 100%;
  max-width: 400px;
  box-sizing: border-box;
  border: 1px solid #AAAAAA;
}

.checkout2 .buy_button_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.checkout2 .buy_button_container {
  margin-bottom: 70px;
}

.checkout2 .buy_button_container .button {
  background-color: #FF5722;
  color: #FFFFFF;
  font-family: "Montserrat";
  padding: 10px 70px;
  font-size: 13px;
  border-radius: 3px;
  font-weight: 600;
}

.checkout1 {
  padding-top: 80px;
}

.checkout1 .welcome_container {
  width: 100%;
  height: 250px;
  background-image: -webkit-gradient(linear, left top, right top, from(#FF5722), to(#CE3407));
  background-image: -webkit-linear-gradient(left, #FF5722, #CE3407);
  background-image: linear-gradient(to right, #FF5722, #CE3407);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

.checkout1 .welcome_container .decor {
  width: 220px;
  height: 110px;
  position: absolute;
  top: 0px;
  background-repeat: no-repeat;
  background-size: 80%;
  background-position-y: 0;
  background-position-y: initial;
}

.checkout1 .welcome_container .decor.left {
  background-position-x: 0;
  background-position-x: initial;
  background-image: url(/static/media/decore-left.8d502445.png);
  left: 0px;
}

.checkout1 .welcome_container .decor.right {
  background-position-x: right;
  background-image: url(/static/media/decore-right.2d8a112b.png);
  right: 0px;
}

.checkout1 .welcome_container .box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.checkout1 .welcome_container .box .circle {
  background-image: url(/static/media/badge.9f54380e.png);
  height: 100px;
  width: 100px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.checkout1 .welcome_container .box .text {
  font-family: "Montserrat";
  color: #FFFFFF;
  font-weight: bold;
  font-size: 22px;
  margin-top: 10px;
}

.checkout1 .checkout_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding-top: 70px;
}

.checkout1 .checkout_container .circle_container {
  width: 100%;
  box-sizing: border-box;
  padding-top: 50px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.checkout1 .checkout_container .circle_container .circle {
  color: #FFFFFF;
  padding: 10px;
  background-color: #FFFFFF;
  height: 30px;
  width: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
  font-size: 22px;
  font-weight: bold;
  font-family: "Montserrat";
  position: relative;
}

.checkout1 .checkout_container .circle_container .circle::after {
  content: "";
  height: 4px;
  width: 500px;
  position: absolute;
  background-color: #FFFFFF;
  top: 24px;
  right: 0px;
  z-index: -1;
}

.checkout1 .checkout_container .circle_container .circle._1 {
  background-color: #FF5722;
}

.checkout1 .checkout_container .circle_container .circle._3, .checkout1 .checkout_container .circle_container .circle._2 {
  height: 22px;
  width: 22px;
  line-height: 22px;
  color: #999999;
  border: 4px solid #999999;
}

.checkout1 .checkout_container .circle_container .circle._1::after {
  background-color: #FF5722;
}

.checkout1 .payment_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 70px;
}

.checkout1 .payment_container .container_box {
  width: 100%;
  margin: 30px 0px;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.checkout1 .payment_container .container_box .box {
  height: 200px;
  width: 200px;
  border-radius: 7px;
  background-color: #D8D8D8;
  border: 1px solid #979797;
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.checkout1 .payment_container .container_box .box .white {
  height: 80px;
  width: 80px;
  background-color: #FFFFFF;
  border-radius: 4px;
  border: 1px solid #cacaca;
  margin-top: 30px;
  background-size: cover;
  
}

.checkout1 .payment_container .container_box .box .text {
  font-weight: 500;
  font-size: 13px;
  margin-top: 25px;
}

.checkout1 .payment_container .container_box .box .button {
  font-family: "Montserrat";
  background-color: #FF5722;
  color: #FFFFFF;
  font-size: 11px;
  font-weight: 500;
  margin-top: 10px;
  padding: 5px 25px;
  border-radius: 100px;
  cursor: pointer;
}

.checkout1 .buy_button_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.checkout1 .buy_button_container {
  margin-bottom: 70px;
}

.checkout1 .buy_button_container .button {
  background-color: #FF5722;
  color: #FFFFFF;
  font-family: "Montserrat";
  padding: 10px 70px;
  font-size: 13px;
  border-radius: 3px;
  font-weight: 600;
  cursor: pointer;
}

.login {
  margin: auto;
  padding-top: 80px;
}

.login .flex_row {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.login .flex_row .image1 {
  width: 50%;
  /* opacity: 0.1; */
  min-height: 400px;
  display: flex;
  /* justify-content:center; */
  /* background-image: url(../../assets/login.png); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
  min-width: 300px;
  margin: auto;
  -webkit-transition: all 400ms;
  transition: all 400ms;
  align-items: center;
  font-family: "Montserrat";
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px
}
.login .flex_row .image {
  width: 50%;
  /* opacity: 0.1; */
  min-height: 400px;
  display: flex;
  /* justify-content:center; */
  background-image: url(/static/media/login.8bffe369.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
  min-width: 300px;
  margin: auto;
  -webkit-transition: all 400ms;
  transition: all 400ms;
  align-items: center;
  font-family: "Montserrat";
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px
}
.login .flex_row .image2 {
  width: 50%;
  /* opacity: 0.1; */
  min-height: 400px;
  display: flex;
  /* justify-content:center; */
  background-image: url(/static/media/store.47a4d4fe.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
  min-width: 300px;
  margin: auto;
  -webkit-transition: all 400ms;
  transition: all 400ms;
  align-items: center;
  font-family: "Montserrat";
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px
}
.venalign {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  
}

@media screen and (max-width: 728px) {
  .login .flex_row .image1 {
    display: block;

  }
}

@media screen and (max-width: 600px) {
  .login .flex_row .image {
    width: 80%;
    background-size: 70%;
  }
}

.login .flex_row .login_section {
  width: 40%;
  display: flex;
  box-sizing: border-box;
  padding: 50px;
  flex-direction: column;
  min-width: 300px;
  margin: auto;
  -webkit-transition: all 400ms;
  transition: all 400ms;
}

@media screen and (max-width: 600px) {
  .login .flex_row .login_section {
    width: 80%;
    background-size: 70%;
  }
}

.login .flex_row .login_section .header {
  font-weight: 600;
  font-family: "Montserrat";
  font-size: 18px;
  margin-bottom: 20px;
}

.login .flex_row .login_section .social_media_box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0px 0px;
  box-sizing: border-box;
}

.login .flex_row .login_section .social_media_box .box {
  width: 40%;
  min-width: 200px;
  height: 30px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 15px 0px 7px;
  box-sizing: border-box;
  margin: 10px auto 0px auto;
  flex-wrap: wrap;
}

.login .flex_row .login_section .social_media_box .box .icon {
  height: 20px;
  width: 20px;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 5px;
}

.login .flex_row .login_section .social_media_box .box .icon.facebook {
  background-size: 70%;
  background-image: url(/static/media/facebook.cceee645.svg);
}

.login .flex_row .login_section .social_media_box .box .icon.google {
  background-image: url(/static/media/google-plus.fe189785.svg);
  background-size: 80%;
}

.login .flex_row .login_section .social_media_box .box .text {
  color: #FFFFFF;
  font-family: "Montserrat";
  font-size: 13px;
  font-weight: 500;
}

.login .flex_row .login_section .social_media_box .box.google {
  background-color: #DF4930;
  cursor: pointer;
}

.login .flex_row .login_section .social_media_box .box.facebook {
  background-color: #507CC0;
  cursor: pointer;
}

.login .flex_row .login_section .center {
  width: 100%;
  text-align: center;
  font-family: "Montserrat";
  margin: 20px auto;
  font-weight: 600;
  font-size: 15px;
}

.login .flex_row .login_section .input_box {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: "Montserrat";
}

.login .flex_row .login_section .input_box .text {
  font-weight: 600;
  font-size: 14px;
  color: #6B6C6F;
}

.login .flex_row .login_section .input_box input {
  padding: 10px 20px;
  border-radius: 100px;
  border: 1px solid #AAAAAA;
  margin-top: 10px;
  font-family: "Montserrat";
  font-size: 15px;
  letter-spacing: 0.4px;
}

.login .flex_row .login_section .input_box.top_gap {
  margin-top: 20px;
}

.login .flex_row .login_section .button_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 30px;
}

.login .flex_row .login_section .button_container .button {
  width: 40%;
  padding: 12px 15px;
  box-sizing: border-box;
  border-radius: 100px;
  text-align: center;
  min-width: 150px;
  font-family: "Montserrat";
  font-size: 13px;
  margin: 10px auto 0px auto;
}

.login .flex_row .login_section .button_container .button.reg {
  border: 2px solid #FF5722;
  color: #FF5722;
  font-weight: 500;
  cursor: pointer;
}

.login .flex_row .login_section .button_container .button.log {
  border: 2px solid #FF5722;
  background-color: #FF5722;
  color: #FFFFFF;
  font-weight: 500;
  box-shadow: 1px 7px 10px 1px rgba(228, 30, 38, 0.18);
          cursor: pointer;
}


.package {
  padding-top: 80px;
  overflow: hidden;
}

.package .welcome_container {
  width: 100%;
  height: 250px;
  background-image: -webkit-gradient(linear, left top, right top, from(#FF5722), to(#CE3407));
  background-image: -webkit-linear-gradient(left, #FF5722, #CE3407);
  background-image: linear-gradient(to right, #FF5722, #CE3407);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

.package .welcome_container .decor {
  width: 220px;
  height: 110px;
  position: absolute;
  top: 0px;
  background-repeat: no-repeat;
  background-size: 80%;
  background-position-y: 0;
  background-position-y: initial;
}

.package .welcome_container .decor.left {
  background-position-x: 0;
  background-position-x: initial;
  background-image: url(/static/media/decore-left.8d502445.png);
  left: 0px;
}

.package .welcome_container .decor.right {
  background-position-x: right;
  background-image: url(/static/media/decore-right.2d8a112b.png);
  right: 0px;
}

.package .welcome_container .box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.package .welcome_container .box .circle {
  background-image: url(/static/media/badge.9f54380e.png);
  height: 100px;
  width: 100px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.package .welcome_container .box .text {
  font-family: "Montserrat";
  color: #FFFFFF;
  font-weight: bold;
  font-size: 22px;
  margin-top: 10px;
}

.package .header {
  width: 100%;
  text-align: center;
  margin: 50px auto;
  font-family: "Montserrat";
  letter-spacing: 0.3px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.package .header span {
  font-weight: 600;
}

.package .body {
  width: 100%;
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 768px) {
  .package .body {
    flex-direction: column;
  }
}

.package .body .taskbar {
  width: 250px;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Montserrat";
  -webkit-transition: all 500ms;
  transition: all 500ms;
}


.package .body .taskbar.mob{
  display: none;
 }
 

 
 
 @media screen and (max-width: 768px) {
   .package .body .taskbar {
     margin: auto;
     display: none;
 
   }
   .package .body .taskbar.mob{
     
     display: flex;
    }
 }


.package .body .taskbar .header {
  color: #757575;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  margin: 0px;
}

.package .body .taskbar .header .icon {
  height: 14px;
  width: 14px;
  background-image: url(/static/media/right-arrow.0ad5d6e7.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  position: relative;
  top: 2px;
  left: 13px;
}

.package .body .taskbar .item_box {
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
}

.package .body .taskbar .item_box .item {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 15px;
  line-height: 30px;
  cursor: pointer;
}

.package .body .data_box {
  width: calc(100% - 250px);
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
}

@media screen and (max-width: 768px) {
  .package .body .data_box {
    width: 100%;
  }
}

.package .body .data_box .top_section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.package .body .data_box .top_section .package_details {
  display: flex;
  flex-direction: column;
  width: 70%;
  max-width: 70%;
  /* margin: auto; */
}

@media screen and (max-width: 768px) {
  .package .body .data_box .top_section .package_details {
    width: 95%;
    max-width: 95%;
    margin: auto;
  }
}

.package .body .data_box .top_section .package_details .package_container {
  display: flex;
  flex-direction: row;
  padding: 10px;
  box-sizing: border-box;
  align-items: center;
}

@media screen and (max-width: 600px) {
  .package .body .data_box .top_section .package_details .package_container {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 600px) {
  .imagesetme {
    height: 270px;
    width: 270px;
  }
}
.imagesetme {
  min-height: 220px;
  min-width: 220px;
  border-radius: 4px;
  background-color: #D8D8D8;
  border: 1px solid #979797;
}
.package .body .data_box .top_section .package_details .package_container .image {
  height: 155px;
  width: 206px;
  min-width: 151px;
  border-radius: 4px;
  border: 1px solid #979797;
  margin: auto;
  margin-bottom: 30px;
}

.package .body .data_box .top_section .package_details .package_container .data_container {
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
  box-sizing: border-box;
  margin-top: 5px;
}

.package .body .data_box .top_section .package_details .package_container .data_container .top_section {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.package .body .data_box .top_section .package_details .package_container .data_container .top_section .button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 10px;
  box-sizing: border-box;
  background-color: #FF5722;
  border-radius: 2px;
  color: #FFFFFF;
  font-family: "Montserrat";
  font-size: 13px;
  /* margin: auto; */
}

@media screen and (max-width: 321px) {
  .package .body .data_box .top_section .package_details .package_container .data_container .top_section .button {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 842px) and (min-width: 770px) {
  .package .body .data_box .top_section .package_details .package_container .data_container .top_section .button {
    margin-bottom: 10px;
  }

}

.package .body .data_box .top_section .package_details .package_container .data_container .top_section .button .icon {
  height: 15px;
  width: 15px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 90%;
  background-image: url(/static/media/user.14f55162.svg);
  margin-right: 5px;
}

.package .body .data_box .top_section .package_details .package_container .data_container .top_section .text {
  font-family: "Montserrat";
  margin-left: 10px;
  font-weight: 500;
  /* margin: auto; */
}

.package .body .data_box .top_section .package_details .package_container .data_container .content {
  line-height: 20px;
  font-family: "Montserrat";
  font-size: 13px;
  margin-top: 20px;
  color: #333333;
}

.package .body .data_box .top_section .package_details .package_container .data_container .button_box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-family: "Montserrat";
  font-size: 13px;
  align-items: center;
  margin-top: 20px;
}

.package .body .data_box .top_section .package_details .package_container .data_container .button_box .sq_button {
  padding: 8px 18px;
  border: 1px solid #979797;
  border-radius: 2px;
  color: #333333;
  width: 115px;
  margin-right: 15px;
}

.package .body .data_box .top_section .package_details .package_container .data_container .button_box .rd_button {
  padding: 7px 18px;
  border-radius: 100px;
  background-color: #FF5722;
  color: #ffffff;
  margin: 15px 0px;
  cursor: pointer;
}

.package .body .data_box .top_section .package_details .package_include_section {
  display: flex;
  flex-direction: column;
}

.package .body .data_box .top_section .package_details .package_include_section .header {
  text-transform: uppercase;
  width: 100%;
  display: flex;
  align-items: flex-start;
  font-weight: 500;
}

.package .body .data_box .top_section .package_details .package_include_section .body_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-right: 15px;
  box-sizing: border-box;
}

.package .body .data_box .top_section .package_details .package_include_section .body_container .box {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px 0px 20px 0px;
}

.package .body .data_box .top_section .package_details .package_include_section .body_container .box .image {
  height: 60px;
  width: 60px;
  min-width: 60px;
  /* background-color: #D8D8D8; */
  background-image: url(/static/media/birthday1.2ebefa11.jpg);
  border: 1px solid #6B6C6F;
  border-radius: 2px;
}

.package .body .data_box .top_section .package_details .package_include_section .body_container .box .content {
  display: flex;
  flex-direction: column;
}

.package .body .data_box .top_section .package_details .package_include_section .body_container .box .content .text {
  font-family: "Montserrat";
  line-height: 20px;
  padding: 0px 7px;
  box-sizing: border-box;
  font-size: 13px;
}

.package .body .data_box .top_section .package_details .package_include_section .body_container .box .content .text.header {
  margin: 0px;
}

.package .body .data_box .top_section .box_container {
  display: flex;
  flex-direction: column;
  width: 30%;
  min-width: 200px;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
}

@media screen and (max-width: 963px) {
  .package .body .data_box .top_section .box_container {
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
  .hidemob {
    display: none !important;
  }
}

.package .body .data_box .top_section .box_container .box {
  width: 100%;
  max-width: 220px;
  min-width: 200px;
  padding: 10px;
  box-sizing: border-box;
  background-color: #FFFFFF;
  border: 1px solid #AAAAAA;
  box-shadow: 0px 0px 10px 3px #cacaca;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Montserrat";
  text-transform: uppercase;
  margin: 35px auto 0px auto;
}

.package .body .data_box .top_section .box_container .box .header {
  margin: 10px auto;
  font-size: 16px;
  font-weight: 500;
}

.package .body .data_box .top_section .box_container .box .content {
  line-height: 20px;
  margin: 10px 0px;
}

.package .body .data_box .top_section .box_container .box .button {
  margin: 10px auto;
  background-color: #FF5722;
  border-radius: 100px;
  color: #FFFFFF;
  padding: 7px 15px;
  font-size: 12px;
  font-weight: 600;
}

.package .body .data_box .top_section .box_container .box.second .icon {
  height: 60px;
  width: 60px;
  margin: 5px auto;
  background-image: url(/static/media/engineerICON.48353e2b.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.package .body .data_box .top_section .box_container .box.second .container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin: 10px 0px;
  align-items: center;
}

.package .body .data_box .top_section .box_container .box.second .container .text {
  text-transform: none;
  font-size: 12px;
  width: 70%;
  font-weight: 500;
}

.package .body .data_box .top_section .box_container .box.second .container .checkbox {
  display: none;
}

.package .body .data_box .top_section .box_container .box.second .container .checkbox + label {
  font-size: 13px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
}

.package .body .data_box .top_section .box_container .box.second .container .checkbox + label:before {
  content: "\2714";
  border-radius: 0.2em;
  display: inline-block;
  height: 7px;
  width: 7px;
  padding: 0px 6px 10px 4px;
  margin-right: 0.7em;
  vertical-align: bottom;
  color: transparent;
  -webkit-transition: .2s;
  transition: .2s;
  background-color: #D8D8D8;
}

.package .body .data_box .top_section .box_container .box.second .container .checkbox + label:active:before {
  -webkit-transform: scale(0);
          transform: scale(0);
}

.package .body .data_box .top_section .box_container .box.second .container .checkbox:checked + label {
  color: #FF5722;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}

.package .body .data_box .top_section .box_container .box.second .container .checkbox:checked + label:before {
  background-color: #FF5722;
  border-color: #FF5722;
  color: #fff;
  box-shadow: 0px 0px 0px 0px #dadada;
}

.package .body .data_box .top_section .box_container .box.second .container .checkbox:disabled + label:before {
  -webkit-transform: scale(1);
          transform: scale(1);
  border-color: #aaa;
}

.package .body .data_box .top_section .box_container .box.second .container .checkbox:checked:disabled + label:before {
  -webkit-transform: scale(1);
          transform: scale(1);
  background-color: #bfb;
  border-color: #bfb;
}

.package .body .data_box .add_extra_products_container {
  margin: 30px 0px;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px;
}

.package .body .data_box .add_extra_products_container .heading {
  width: 100%;
  text-transform: uppercase;
}

.package .body .data_box .add_extra_products_container .data_box_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  flex-wrap: wrap;
}

.package .body .data_box .add_extra_products_container .data_box_container .data_box {
  width: 45%;
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 5px;
  box-sizing: border-box;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 0px auto;
  margin-bottom: 50px;
}

.package .body .data_box .add_extra_products_container .data_box_container .data_box .image {
  height: 80px;
  width: 80px;
  /* background-color: #D8D8D8; */
  background-image: url(/static/media/birthday1.2ebefa11.jpg);
  border-radius: 2px;
  border: 1px solid #cacaca;
  /* margin: auto; */
  -webkit-transition: all 300ms;
  transition: all 300ms;
  margin-top: 10px;
}

@media screen and (max-width: 476px) {
  .package .body .data_box .add_extra_products_container .data_box_container .data_box .image {
    margin-bottom: 20px;
    height: 180px;
    width: 180px;
  }
}

.package .body .data_box .add_extra_products_container .data_box_container .data_box .details_box {
  display: flex;
  flex-direction: column;
  width: calc(100% - 100px);
  margin: auto;
  padding: 5px;
  box-sizing: border-box;
  min-width: 300px;
}

.package .body .data_box .add_extra_products_container .data_box_container .data_box .details_box .heading_box {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.package .body .data_box .add_extra_products_container .data_box_container .data_box .details_box .heading_box .content {
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
  box-sizing: border-box;
  font-weight: 400;
  color: #333333;
}

.package .body .data_box .add_extra_products_container .data_box_container .data_box .details_box .heading_box .content .main {
  text-transform: uppercase;
  font-size: 15px;
  margin: 5px 0px;
}

.package .body .data_box .add_extra_products_container .data_box_container .data_box .details_box .heading_box .content .sub {
  font-size: 13px;
}

.package .body .data_box .add_extra_products_container .data_box_container .data_box .details_box .heading_box .count {
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  font-size: 17px;
}

.package .body .data_box .add_extra_products_container .data_box_container .data_box .details_box .varient_container {
  display: flex;
  flex-direction: column;
  padding: 5px;
  box-sizing: border-box;
}

.package .body .data_box .add_extra_products_container .data_box_container .data_box .details_box .varient_container .varient {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0px 5px;
  box-sizing: border-box;
  margin: 8px 0px;
}

.package .body .data_box .add_extra_products_container .data_box_container .data_box .details_box .varient_container .varient.gap {
  margin-top: 20px;
}

.package .body .data_box .add_extra_products_container .data_box_container .data_box .details_box .varient_container .varient .name {
  font-size: 15px;
}

.package .body .data_box .add_extra_products_container .data_box_container .data_box .details_box .varient_container .varient .name_count {
  font-size: 16px;
  font-weight: 600;
  color: #FF5722;
}

.package .body .data_box .add_extra_products_container .data_box_container .data_box .details_box .varient_container .varient .action_box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.package .body .data_box .add_extra_products_container .data_box_container .data_box .details_box .varient_container .varient .action_box .btn {
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  color: #FFFFFF;
  border-radius: 10px;
  background-color: #FF5722;
  margin: 0px 4px;
  font-weight: 700;
  cursor: pointer;
}

.package .body .data_box .add_extra_products_container .data_box_container .data_box .details_box .varient_container .varient .action_box .text {
  text-transform: uppercase;
  margin: 0px 5px;
  font-size: 14px;
  min-width: 20px;
  text-align: center;
}

.package .body .data_box .add_extra_products_container .data_box_container .data_box .details_box .varient_container .varient .action_box .total {
  text-transform: uppercase;
  margin: 0px 5px;
  font-size: 16px;
  color: #6B6C6F;
  font-weight: 600;
}

.package .body .data_box .add_extra_products_container .data_box_container .data_box .details_box .varient_container .varient .action_box .button {
  color: #FF5722;
  font-size: 13px;
  font-weight: 500;
  background-color: #FFFFFF;
  border: 1px solid #FF5722;
  padding: 2px 10px;
  border-radius: 10px;
  margin-left: 15px;
  cursor: pointer;
}


.detail {
  padding-top: 80px;
  overflow-x: hidden;
}

.detail .row {
  display: flex;
  flex-direction: row;
  -webkit-transition: all 300ms;
  transition: all 300ms;
}

.detail .row.wrap {
  flex-wrap: wrap;
}

.detail .row.center {
  justify-content: center;
  align-items: center;
}

.detail .row.justify_between {
  justify-content: space-between;
}

.detail .row.justify_around {
  justify-content: space-around;
}

.detail .row.align_center {
  align-items: center;
}

.detail .row.justify_end {
  justify-content: flex-end;
}

.detail .col {
  display: flex;
  flex-direction: column;
  -webkit-transition: all 300ms;
  transition: all 300ms;
}

.detail .col.wrap {
  flex-wrap: wrap;
}

.detail .col.center {
  justify-content: center;
  align-items: center;
}

.detail .col.justify_end {
  justify-content: flex-end;
}

.detail .col.align_center {
  align-items: center;
}

.detail .col.justify_start {
  justify-content: flex-start;
}

.detail .container {
  box-sizing: border-box;
  -webkit-transition: all 300ms;
  transition: all 300ms;
}

.detail .container.full {
  width: 100%;
}

.detail .pad_100 {
  box-sizing: border-box;
  padding: 100px;
}

.detail .pad_50 {
  box-sizing: border-box;
  padding: 50px;
}

.detail .pad_50_100 {
  box-sizing: border-box;
  padding: 50px 100px;
}

@media screen and (max-width: 768px) {
  .detail .pad_50_100 {
    padding: 50px 50px;
    box-sizing: border-box;
  }
  
}

@media screen and (max-width: 768px) {
  .detail .sm_20 {
    padding: 20px;
    box-sizing: border-box;
  }
}

.detail .upper {
  text-transform: uppercase;
}

.detail .lower {
  text-transform: none;
}

.detail .sub_header {
  font-weight: 500;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 16px;
}

.detail .sub_header.light {
  color: #FFFFFF;
}

.detail .main_header {
  font-size: 25px;
  font-weight: 600;
  margin-top: 15px;
  margin-bottom: 15px;
}

.detail .main_header.light {
  color: #FFFFFF;
}

.detail .txt {
  font-family: "Montserrat";
  font-size: 16px;
}

.detail .txt.small {
  font-size: 13px;
}

.detail .txt.strong {
  font-weight: 600;
}

.detail .txt.light {
  color: #FFFFFF;
}

.detail .btn {
  padding: 10px 20px;
}

.detail .btn.color {
  background-color: #FF5722;
  color: #FFFFFF;
}

.detail .btn.round {
  border-radius: 200px;
}

.detail .btn.top_20 {
  margin-top: 20px;
}

.detail .bg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.detail .welcome_container {
  width: 100%;
  height: 250px;
  background-image: -webkit-gradient(linear, left top, right top, from(#FF5722), to(#CE3407));
  background-image: -webkit-linear-gradient(left, #FF5722, #CE3407);
  background-image: linear-gradient(to right, #FF5722, #CE3407);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

.detail .welcome_container .decor {
  width: 220px;
  height: 110px;
  position: absolute;
  top: 0px;
  background-repeat: no-repeat;
  background-size: 80%;
  background-position-y: 0;
  background-position-y: initial;
}

.detail .welcome_container .decor.left {
  background-position-x: 0;
  background-position-x: initial;
  background-image: url(/static/media/decore-left.8d502445.png);
  left: 0px;
}

.detail .welcome_container .decor.right {
  background-position-x: right;
  background-image: url(/static/media/decore-right.2d8a112b.png);
  right: 0px;
}

.detail .welcome_container .box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.detail .welcome_container .box .circle {
  background-image: url(/static/media/badge.9f54380e.png);
  height: 100px;
  width: 100px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.detail .welcome_container .box .text {
  font-family: "Montserrat";
  color: #FFFFFF;
  font-weight: bold;
  font-size: 22px;
  margin-top: 10px;
}

.detail .header {
  width: 100%;
  text-align: center;
  margin: 50px auto;
  font-family: "Montserrat";
  letter-spacing: 0.3px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.detail .header span {
  font-weight: 600;
}

.detail .body {
  width: 100%;
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 768px) {
  .detail .body {
    flex-direction: column;
  }
}

.detail .body .taskbar {
  width: 250px;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Montserrat";
  -webkit-transition: all 500ms;
  transition: all 500ms;
}






.detail .body .taskbar.mob{
  display: none;
 }
 

 
 
 @media screen and (max-width: 768px) {
   .detail .body .taskbar {
     margin: auto;
     display: none;
 
   }
   .detail .body .taskbar.mob{
     
     display: flex;
    }
 }








.detail .body .taskbar .header {
  color: #757575;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  margin: 0px;
}

.detail .body .taskbar .header .icon {
  cursor: pointer;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  height: 14px;
  width: 14px;
  background-image: url(/static/media/right-arrow.0ad5d6e7.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  position: relative;
  top: 2px;
  right: 8px;
}

.detail .body .taskbar .item_box {
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
}

.detail .body .taskbar .item_box .item {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 15px;
  line-height: 30px;
  cursor: pointer;
}

.detail .body .data_box {
  width: calc(100% - 250px);
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 70px;
}

@media screen and (max-width: 768px) {
  .detail .body .data_box {
    width: 100%;
  }
}

.detail .body .data_box .top_section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
}

.detail .body .data_box .top_section .package_details {
  display: flex;
  flex-direction: column;
  width: 70%;
  max-width: 70%;
  margin: auto;
  min-width: 380px;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .detail .body .data_box .top_section .package_details {
    width: 95%;
    max-width: 95%;
    margin: auto;
  }
}

.detail .body .data_box .top_section .package_details .package_box {
  width: 95%;
  box-shadow: 0px 0px 5px 1px #dadada;
  border-radius: 5px;
  margin-bottom: 20px;
  font-family: "Montserrat";
  justify-content: space-around;
}

.detail .body .data_box .top_section .package_details .package_box .l_box {
  /* width: 70%; */
  min-width: 200px;
  padding: 10px;
  box-sizing: border-box;
}

@media screen and (max-width: 1050px) {
  .detail .body .data_box .top_section .package_details .package_box .l_box {
    width: 90%;
  }
}

.detail .body .data_box .top_section .package_details .package_box .l_box .image {
  height: 130px;
  width: 130px;
  border-radius: 5px;
  /* background-color: #D8D8D8; */
  /* background-image: url(../../assets/birthday1.jpg); */
  border: 1px solid #979797;
  margin: auto;
}

.detail .body .data_box .top_section .package_details .package_box .l_box .det_box {
  min-width: 200px;
  width: calc(100% - 150px);
  margin-left: 10px;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 10px;
  box-sizing: border-box;
}
@media screen and (max-width:480px) {
  .detail .body .data_box .top_section .package_details .package_box .l_box .det_box {
    width: 100%;  
    margin-left: 20px;
  }
}


.detail .body .data_box .top_section .package_details .package_box .l_box .det_box .heading_main {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 15px;
  line-height: 27px;
}

.detail .body .data_box .top_section .package_details .package_box .l_box .det_box .heading_sub {
  font-size: 13px;
  font-weight: 500;
  line-height: 25px;
}

.detail .body .data_box .top_section .package_details .package_box .l_box .det_box .heading_sub span {
  color: #FF5722;
}

.detail .body .data_box .top_section .package_details .package_box .l_box .det_box .content {
  font-family: "Montserrat";
  color: #666666;
  font-size: 11px;
  line-height: 17px;
  padding-right: 10px;
  text-align: justify;
  letter-spacing: 0.3px;
}

.detail .body .data_box .top_section .package_details .package_box .r_box {
  width: 30%;
  min-width: 200px;
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;
}

.detail .body .data_box .top_section .package_details .package_box .r_box .upto_box {
  background-color: #FF5722;
  color: #FFFFFF;
  padding: 3px 10px;
  border-radius: 2px;
  margin-top: 5px;
}

.detail .body .data_box .top_section .package_details .package_box .r_box .upto_box .icon {
  background-image: url(/static/media/user.14f55162.svg);
  height: 10px;
  width: 10px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  margin-right: 5px;
}

.detail .body .data_box .top_section .package_details .package_box .r_box .upto_box .txt {
  font-size: 11px;
  font-weight: 500;
}

.detail .body .data_box .top_section .package_details .package_box .r_box .price {
  margin: 25px auto 10px auto;
  font-weight: 500;
  font-family: "Montserrat";
  font-size: 20px;
}

.detail .body .data_box .top_section .package_details .package_box .r_box .know {
  padding: 7px 15px;
  background-color: #EDEDED;
  border-radius: 3px;
  font-size: 11px;
  font-weight: 500;
  margin-bottom: 10px;
}

.detail .body .data_box .top_section .package_details .package_box .buy {
  text-transform: uppercase;
  padding: 8px 20px;
  font-size: 12px;
  color: #FFFFFF;
  font-weight: 500;
  background-color: #FF5722;
  border-radius: 100px;
}

.detail .body .data_box .top_section .box_container {
  display: flex;
  flex-direction: column;
  width: 30%;
  min-width: 200px;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
}

@media screen and (max-width: 963px) {
  .detail .body .data_box .top_section .box_container {
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
}

.detail .body .data_box .top_section .box_container .box {
  margin: auto;
  width: 100%;
  max-width: 220px;
  min-width: 200px;
  padding: 10px;
  box-sizing: border-box;
  background-color: #FFFFFF;
  border: 1px solid #AAAAAA;
  box-shadow: 0px 0px 10px 3px #cacaca;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          /* align-items: center; */
  font-family: "Montserrat";
  text-transform: uppercase;
}

.detail .body .data_box .top_section .box_container .box .icon {
  height: 70px;
  width: 70px;
  background-image: url(/static/media/writing.9436ba31.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 70%;
  margin-top: 10px;
}

.detail .body .data_box .top_section .box_container .box .content {
  line-height: 20px;
  margin: 10px 0px 0px 0px;
  font-size: 13px;
  font-weight: 500;
}

.detail .body .data_box .top_section .box_container .box .button {
  margin: 10px auto;
  background-color: #FF5722;
  border-radius: 100px;
  color: #FFFFFF;
  padding: 7px 15px;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.landing {
  width: 100%;
  padding-top: 80px;
  font-family: "Montserrat";
}

.landing .row {
  display: flex;
  flex-direction: row;
  -webkit-transition: all 300ms;
  transition: all 300ms;
}

.landing .row.wrap {
  flex-wrap: wrap;
}

.landing .row.center {
  justify-content: center;
  align-items: center;
}

.landing .row.justify_between {
  justify-content: space-between;
}

.landing .row.justify_around {
  justify-content: space-around;
}

.landing .row.align_center {
  align-items: center;
}

.landing .row.justify_end {
  justify-content: flex-end;
}

.landing .col {
  display: flex;
  flex-direction: column;
  -webkit-transition: all 300ms;
  transition: all 300ms;
}

.landing .col.wrap {
  flex-wrap: wrap;
}

.landing .col.center {
  justify-content: center;
  align-items: center;
}

.landing .col.justify_end {
  justify-content: flex-end;
}

.landing .col.align_center {
  align-items: center;
}

.landing .container {
  box-sizing: border-box;
  -webkit-transition: all 300ms;
  transition: all 300ms;
}

.landing .container.full {
  width: 100%;
}

.landing .pad_100 {
  box-sizing: border-box;
  padding: 100px;
}

.landing .pad_50 {
  box-sizing: border-box;
  padding: 50px;
}

.landing .pad_50_100 {
  box-sizing: border-box;
  padding: 50px 100px;
}

@media screen and (max-width: 768px) {
  .landing .pad_50_100 {
    padding: 50px 50px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 768px) {
  .landing .sm_20 {
    padding: 20px;
    box-sizing: border-box;
  }
}

.landing .upper {
  text-transform: uppercase;
}

.landing .lower {
  text-transform: none;
}

.landing .sub_header {
  font-weight: 500;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 16px;
}

.landing .sub_header.light {
  color: #FFFFFF;
}

.landing .main_header {
  font-size: 25px;
  font-weight: 600;
  margin-top: 15px;
  margin-bottom: 15px;
}

.landing .main_header.light {
  color: #FFFFFF;
}

.landing .txt {
  font-family: "Montserrat";
  font-size: 16px;
}

.landing .txt.small {
  font-size: 13px;
}

.landing .txt.strong {
  font-weight: 600;
}

.landing .txt.light {
  color: #FFFFFF;
}

.landing .btn {
  padding: 10px 20px;
}

.landing .btn.color {
  background-color: #FF5722;
  color: #FFFFFF;
}

.landing .btn.round {
  border-radius: 200px;
}

.landing .btn.top_20 {
  margin-top: 20px;
}

.landing .bg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.landing .slider {
  width: 100%;
  /* background-color: black; */
  /* background-image: linear-gradient(to bottom, transparent 50%, #000000); */
  
  padding: 200px 100px 100px 100px;
  box-sizing: border-box;
          /* background: url('../../assets/banner.jpg'); */
          /* background-position:center;
          -webkit-transition: all .5s ease;
          -moz-transition: all .5s ease;
          -ms-transition: all .5s ease;
          -o-transition: all .5s ease;
          transition: all .5s ease; */
}

.imagebg {
	width:90%;
  height:400px;
	background:-webkit-gradient(linear, left top, left bottom, from(rgba(48, 61, 134, 0.52)), to(rgba(48, 44, 47, 0.73))), url(/static/media/banner.24be5290.jpg);
	background:-webkit-linear-gradient(top, rgba(48, 61, 134, 0.52), rgba(48, 44, 47, 0.73)), url(/static/media/banner.24be5290.jpg);
	background:linear-gradient(to bottom, rgba(48, 61, 134, 0.52), rgba(48, 44, 47, 0.73)), url(/static/media/banner.24be5290.jpg);
	background-position:center;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
  display: flex;
  align-items: center;
  padding-left: 10%;
  color:white;
  font-size: 25px;
  font-weight: bold;
  /* opacity: 0.9; */
  
} 
.imagebg:hover {  
  -webkit-transform: scale(1.01); /* IE 9 */ 
  transform: scale(1.01);
} 


.landing .slider .slider_main_header {
  font-size: 30px;
  color: #FFFFFF;
  font-weight: 500;
  margin-top: 15px;
  margin-bottom: 15px;
  word-break: break-all;
}

.landing .slider .slider_main_header .strong {
  color: #FF5722;
  font-weight: 700;
}

.landing .slider .button_box {
  margin-top: 20px;
  padding: 10px 15px;
  background-color: #FFFFFF;
  border-radius: 2px;
  margin-right: 20px;
  cursor: pointer;
}

.landing .slider .down_arrow {
  height: 20px;
  width: 20px;
  margin-left: 10px;
  background-image: url(/static/media/down-arrow.347ce080.svg);
  background-size: 50%;
}

.landing .slider .calendar {
  height: 20px;
  width: 20px;
  margin-left: 10px;
  background-image: url(/static/media/calendar.42b6b574.svg);
}

.landing .description_section .txt_box {
  width: calc(100% - 110px);
  line-height: 27px;
}

.landing .description_section .round {
  width: 90px;
  min-width: 90px;
  height: 90px;
  line-height: 90px;
  background-color: #FF5722;
  color: #FFFFFF;
  border-radius: 50%;
  /* border: 1px solid #979797; */
  text-align: center;
  font-size: 17px;
  font-weight: 700;
}

.landing .theme_box {
  width: 100%;
}

.landing .theme_box .box_item {
  height: 250px;
  min-width: 200px;
  width: 21%;
  /* background-color: cyan; */
  margin-bottom: 15px;
  margin-left: 20px;
  border-radius: 7px;
  
  margin: 0px auto 15px auto;

  background-position:center;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;

}
.landing .theme_box .box_item:hover {

  -webkit-transform: scale(1.1); /* IE 9 */ 
  transform: scale(1.1);
}

.landing .theme_box .box_item .overlay {
  padding: 20px;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  border-radius: 7px;
  /* background-image: -webkit-gradient(linear, left top, left bottom, color-stop(50%, transparent), to(#000000)); */
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(50%, transparent), to(#000000));
  background-image: -webkit-linear-gradient(top, transparent 50%, #000000);
  background-image: linear-gradient(to bottom, transparent 50%, #000000);
  cursor: pointer;
}

.landing .add_p_box .circle {
  height: 130px;
  width: 130px;
  border-radius: 50%;
  margin-bottom: 10px;
  /* background-image: url(../../assets/mic.jpeg); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.landing .book_box {
  background-image: -webkit-gradient(linear, left top, right top, from(#FF5722), to(#CE3407));
  background-image: -webkit-linear-gradient(left, #FF5722, #CE3407);
  background-image: linear-gradient(to right, #FF5722, #CE3407);
  margin-bottom: 50px;
}

.landing .book_box .txt {
  width: 80%;
  line-height: 27px;
  margin-top: 10px;
}

@media screen and (max-width: 768px) {
  .landing .book_box .txt {
    width: 100%;
  }
}

.landing .book_box .button_box {
  width: 20%;
  min-width: 100px;
}

@media screen and (max-width: 768px) {
  .landing .book_box .button_box {
    width: 100%;
    justify-content: center;
  }
  .landing .slider {
    width: 100%;
    background-color: black;
    padding: 100px 50px 100px 50px;
    box-sizing: border-box;
}
}

.landing .book_box .button_box .button {
  padding: 9px 15px;
  color: #FF5722;
  background-color: #FFFFFF;
  border-radius: 100px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  /* -webkit-box-shadow: 0px 4px 7px 4px #BC340D;
          box-shadow: 0px 4px 7px 4px #BC340D; */
  min-width: 100px;
  text-align: center;
  margin-top: 10px;
  cursor: pointer;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
}
.landing .book_box .button_box .button:hover {
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19)
}

.contact {
  font-family: "Montserrat";
  padding-top: 80px;
}

.contact .welcome_container {
  width: 100%;
  height: 180px;
  background-image: -webkit-gradient(linear, left top, right top, from(#FF5722), to(#CE3407));
  background-image: -webkit-linear-gradient(left, #FF5722, #CE3407);
  background-image: linear-gradient(to right, #FF5722, #CE3407);
  position: relative;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact .welcome_container .box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  margin-right: 15%;
  font-size: 27px;
}

.contact .welcome_container .box .text {
  text-transform: uppercase;
  font-family: "Montserrat";
  color: #FFFFFF;
  font-weight: bold;
  font-size: 35px;
  margin-top: 10px;
}

.contact .heading_container {
  -webkit-transition: all 300ms;
  transition: all 300ms;
  width: 100%;
  margin: 50px 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 50px 100px;
  box-sizing: border-box;
}

@media screen and (max-width: 500px) {
  .contact .heading_container {
    padding: 50px;
  }
  .landing .theme_box .box_item {
    height: 250px;
    min-width: 200px;
    width: 100%;
    /* background-color: cyan; */
    margin-bottom: 15px;
    margin-left: 20px;
    border-radius: 7px;
    
    margin: 0px auto 15px auto;
  
    background-position:center;
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
  
  }
}

.contact .heading_container .main {
  font-size: 25px;
}

.contact .heading_container .sub {
  margin-top: 5px;
  color: #979797;
  font-size: 18px;
  line-height: 27px;
}

.contact .body_container {
  /* overflow-x: hidden; */
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  padding: 0px 100px;
  box-sizing: border-box;
  margin-bottom: 50px;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .contact .body_container {
    padding: 0px 10px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1000px) {
  .contact .body_container {
    padding: 0px 50px;
  }
}

.contact .body_container .l_section {
  width: 50%;
  min-width: 300px;
  margin: auto;
  padding: 5px;
  box-sizing: border-box;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  height: inherit;
}

.contact .body_container .l_section.map {
  margin-top: 0px;
  align-items: center;
}

@media screen and (max-width: 810px) {
  .contact .body_container .l_section {
    width: 90%;
  }
}

.contact .body_container .input_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 15px;
}

.contact .body_container .input_container .text {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
  color: #333333;
}

.contact .body_container .input_container .input {
  width: 100%;
  max-width: 400px;
  padding: 7px 15px;
  border: 1px solid #AAAAAA;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: "Montserrat";
  font-size: 15px;
  letter-spacing: 0.3px;
}

@media secreen and (max-width: 500px) {
  .contact .body_container .input_container .input {
    max-width: 0px;
    width: 80%;
  }
}

.contact .body_container .input_container .text_area {
  width: 100%;
  max-width: 400px;
  padding: 7px 15px;
  border: 1px solid #AAAAAA;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: "Montserrat";
  font-size: 15px;
  letter-spacing: 0.3px;
  resize: vertical;
  min-height: 120px;
  line-height: 25px;
}

@media secreen and (max-width: 500px) {
  .contact .body_container .input_container .text_area {
    max-width: 0px;
    width: 80%;
  }
}

.contact .body_container .button {
  background-color: #FF5722;
  font-weight: 600;
  font-size: 14px;
  padding: 9px 27px;
  color: #FFFFFF;
  border-radius: 2px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  cursor: pointer;
}

.contact .body_container .map_box {
  width: 80%;
  max-width: 500px;
  min-width: 300px;
  min-height: 300px;
  height: inherit;
  background-color: #D8D8D8;
  border: 1px solid #AAAAAA;
  border-radius: 2px;
  margin: 0px auto;
}

.about {
  font-family: "Montserrat";
  padding-top: 80px;
}

.about .welcome_container {
  width: 100%;
  height: 180px;
  background-image: -webkit-gradient(linear, left top, right top, from(#FF5722), to(#CE3407));
  background-image: -webkit-linear-gradient(left, #FF5722, #CE3407);
  background-image: linear-gradient(to right, #FF5722, #CE3407);
  position: relative;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.about .welcome_container .box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  margin-right: 15%;
  font-size: 27px;
}

.about .welcome_container .box .text {
  text-transform: uppercase;
  font-family: "Montserrat";
  color: #FFFFFF;
  font-weight: bold;
  font-size: 35px;
  margin-top: 10px;
}

.about .heading_container {
  -webkit-transition: all 300ms;
  transition: all 300ms;
  width: 100%;
  margin: 0px 0px 5px 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 50px 100px;
  box-sizing: border-box;
}

@media screen and (max-width: 500px) {
  .about .heading_container {
    padding: 50px;
  }
}

.about .heading_container .main {
  font-size: 25px;
}

.about .heading_container .sub {
  margin-top: 5px;
  color: #979797;
  font-size: 14px;
  line-height: 27px;
}

.about .slider_section {
  width: 90%;
  margin: 40px auto 0px auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.about .slider_section .slider {
  /* transition: all 3s; */
  height: 300px;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  object-fit: cover;
  
}

.about .slider_section .nav_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
}

.about .slider_section .nav_container .x_box {
  width: 25px;
}

.about .slider_section .nav_container .dot_box {
  height: 8px;
  width: 8px;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 2px 1px #dadada;
  border-radius: 50%;
  margin-right: 10px;
  position: relative;
  cursor: pointer;
}

.about .slider_section .nav_container .dot_box:hover {
  box-shadow: 0px 0px 4px 1px #dadada;
  height: 10px;
  width: 10px;
}

.about .slider_section .nav_container .dot_box:hover::after {
  height: 8px;
  width: 8px;
}

.about .slider_section .nav_container .dot_box::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: 6px;
  width: 6px;
  background-color: #919191;
  border-radius: 50%;
}

.about .slider_section .nav_container .dot_box.active {
  height: 12px;
  width: 12px;
  box-shadow: 0px 0px 5px 1px #dadada;
}

.about .slider_section .nav_container .dot_box.active::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: 8px;
  width: 8px;
  background-color: #666666;
  border-radius: 50%;
}

.about .slider_section .nav_container .dot_box:nth-last-child(1) {
  margin-right: 0px;
}

.footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px 50px 20px 50px;
  box-sizing: border-box;
          position:absolute;
          /* margin-top: 50px; */
          bottom:30;
          width:100%;
          height:30px;   /* Height of the footer */
          /* background:#6cf; */
}

.footer .first, .footer .second, .footer .third {
  width: 33.3%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: "Montserrat";
}

@media screen and (max-width: 768px) {
  .footer .first, .footer .second, .footer .third {
    margin: auto;
    width: 90%;
  }
}

.footer .first {
  padding: 0px 50px;
  box-sizing: border-box;
}

.footer .first .logo_section {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.footer .first .logo_section .logo {
  height: 40px;
  /* width: 50px; */
  /* background-color: #FF5722; */
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer .first .logo_section .logo .icon {
  height: 10px;
  width: 10px;
  border: 2px solid #FFFFFF;
  border-radius: 50%;
  background-color: #FF5722;
  position: relative;
  overflow: hidden;
}

.footer .first .logo_section .logo .icon::after {
  content: '';
  position: absolute;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background-color: #FFFFFF;
  top: 2px;
  left: 2px;
  -webkit-clip-path: inset(0px 2.5px 0px 0px);
          clip-path: inset(0px 2.5px 0px 0px);
}

.footer .first .logo_section .text {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.2px;
}

.footer .first .content {
  margin-top: 30px;
  font-family: "Montserrat";
  font-size: 14px;
  line-height: 27px;
}

.footer .second {
  padding: 0px 50px;
  box-sizing: border-box;
}

.footer .second .header {
  font-weight: 600;
  line-height: 40px;
}

.footer .second .item {
  font-size: 14px;
  line-height: 25px;
}

.footer .third {
  padding: 0px 50px;
  box-sizing: border-box;
}

.footer .third .header {
  font-weight: 600;
  line-height: 40px;
}

.footer .third .content {
  font-family: "Montserrat";
  font-size: 14px;
  line-height: 22px;
}

.footer .third .phone {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin-top: 20px;
  letter-spacing: 0.5px;
  font-family: "Montserrat";
}

@media screen and (max-width: 768px) {
  .footer .second,
  .footer .third {
    margin-top: 30px;
  }
}

.footer .first,
.footer .second,
.footer .third {
  -webkit-transition: all 500ms;
  transition: all 500ms;
}
.error_text {
  height: 30px;
  line-height: 30px;
  width: 100%;
  color: red;
  margin-top: 15px;
  text-align: center;
  font-family: "Montserrat";
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.3px;
}
@media screen and (max-width: 768px) {
  .footer .first,
  .footer .second,
  .footer .third {
    padding: 0px;
  }
}
.landing_top_fet {
  display: flex;
  justify-content: center;
  width: 80%;
}
.landing_top_fet_sec {
  flex: 1 1;
  display: flex;
  justify-content: center;
}
.landing_top_fet_sec {
  flex: 1 1;
  display: flex;
  justify-content: center;
}
.landing_top_fet_sec_col_cent, .landing_top_fet_sec_col_cent_img {
  display: flex;
  justify-content: center;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 600;
  color: #4d694c;
  text-align: center;
}
.landing_top_fet_sec_col_cent {
  padding-bottom: 20px;
}
.landing_top_fet_sec_col_cent_last {
  display: flex;
  justify-content: center;
  font-family: Montserrat;
  font-size: 11px;
  font-weight: 600;
  color: #878787;
  text-align: center;
  padding: 5px 40px;
}

@media screen and (max-width: 768px){
  .landing .sm_20 {
    padding: 20px;
    box-sizing: border-box;
}
.landing_top_fet {
  display: block!important;
  justify-content: center;
  width: 80%;
}
}

.datepickerpac {
  width: 70px !important;
}
.vend {
  font-weight: 600;
  font-family: "Montserrat";
  font-size: 14px;
  margin-bottom: 20px;
  margin-top: 12px;
}
.image-box {
  margin: 35px;
  width: 500px;
  height: 300px;
  overflow: hidden;
}

.image-box h4 {
  
    background-color: rgba(174, 0, 0, 0.51);
    position: absolute;
    bottom: 30px;
    left: 30px;
    width: 300px;
    padding: 20px;
    display: block;
    color: white;
    font-size: 24px;
  
}

.hover06 figure img {
	width: 62px;
	height: auto;
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}
.hover06 figure:hover img {
  width: 70px;
}
figure {
	width: 75px;
	height: 75px;
	margin: 0;
	padding: 0;
	background: #fff;
	overflow: hidden;
}


.inputbox-proof {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.btnproof {
  border: 2px solid gray;
  color: gray;
  background-color: white;
  padding: 25px 25px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
}

.inputbox-proof input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.counterbutton {
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  color: #fff;
  border-radius: 10px;
  background-color: #ff5722;
  margin: 0 4px;
  font-weight: 700;
  cursor: pointer;
}

.addbutton {
  color: #FF5722;
  font-size: 13px;
  font-weight: 500;
  background-color: #FFFFFF;
  border: 1px solid #FF5722;
  padding: 2px 10px;
  border-radius: 10px;
  margin-left: 15px;
  cursor: pointer;
}

.borderreup {
  background-color: white !important;
  color: black !important;
  border-width: 1 !important;
  border-color: #ff5722 !important;
  border-style: solid !important;
}


.myprice_detail_box {
  -webkit-transition: all 200ms;
  transition: all 200ms;
  padding-top: 40px;
}
.myprice_detail_box .center_box {
  display: flex;
  flex-direction: column;
  width: 90%;
  box-shadow: 0px 1px 7px 1px #dadada;
  border-radius: 5px;
  padding: 15px 20px;
  box-sizing: border-box;
  font-family: "Montserrat";
}

.myprice_detail_box .center_box .header {
  font-weight: 500;
  margin-bottom: 5px;
  font-size: 17px;
}
.myprice_detail_box .center_box .data {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
  font-size: 14px;
  font-weight: 500;
}

.boxme {
  margin: auto;
  width: 100%;
  max-width: 220px; 
  min-width: 200px;
  padding: 10px;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #aaa;
  box-shadow: 0 0 10px 3px #cacaca;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Montserrat;
  text-transform: uppercase;
}

.detailme .header {
  width: 100%;
  text-align: center;
  margin: 50px auto;
  font-family: Montserrat;
  letter-spacing: .3px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.boxme .content {
  line-height: 20px;
  margin: 10px 0 0;
  font-size: 13px;
  font-weight: 500;
}

.boxme .button {
  margin: 10px auto;
  background-color: #ff5722;
  border-radius: 100px;
  color: #fff;
  padding: 7px 15px;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.packagemainbox {
  display: flex;
  flex-direction: row;
  width:100%;
}

@media screen and (max-width: 900px) {
  .packagemainbox {
    display: flex;
    flex-direction: column;
    width:100%;
    margin-left: 15px;
  }
}
/* Absolute Center Spinner */
.loadingme {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loadingme:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
    background: -webkit-radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0, .8));
    background: radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0, .8));

  background: -webkit-radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0,.8));
}

/* :not(:required) hides these rules from IE9 and below */
.loadingme:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loadingme:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 150ms infinite linear;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
box-shadow: rgba(255,255,255, 0.75) 1.5em 0 0 0, rgba(255,255,255, 0.75) 1.1em 1.1em 0 0, rgba(255,255,255, 0.75) 0 1.5em 0 0, rgba(255,255,255, 0.75) -1.1em 1.1em 0 0, rgba(255,255,255, 0.75) -1.5em 0 0 0, rgba(255,255,255, 0.75) -1.1em -1.1em 0 0, rgba(255,255,255, 0.75) 0 -1.5em 0 0, rgba(255,255,255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.table-main {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.table-header {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.table-section {

}

.table-image {
  width: 100px;
  height:auto;
}

.section-space {
  margin-left: 20px;
  margin-right: 20px;
}

.table-section2 {
  display: flex;
  flex-direction: column;
}
.table-section3 {

}

.table-section5 {

}

.myth{
  font-weight: normal;
}

.tdtable {
  padding-top: 10px;
  padding-bottom:10px;
 }
 .modalset {
   width: 500px;
 }

 @media screen and (max-width: 480px) {
  .modalset {
    width: 417 px;
  }
}

.boxsu {
  display: flex;
  flex-wrap: wrap;
}
.boxsu>* {
  flex: 1 1 160px;
}

table td {word-wrap:break-word;}
/* .respi {
  width: 417 px !important;
}

@media screen and (max-width: 480px) {
  .respi {
    width: 417 px;
  }
} */
.BrainhubCarousel {
    overflow: hidden;
    display: flex;
    align-items: center; }
    .BrainhubCarousel .BrainhubCarousel__trackContainer {
      overflow: hidden; }
      .BrainhubCarousel .BrainhubCarousel__trackContainer .BrainhubCarousel__track {
        display: flex;
        overflow: hidden;
        list-style: none;
        margin: 0;
        padding: 0; }
        .BrainhubCarousel .BrainhubCarousel__trackContainer .BrainhubCarousel__track.BrainhubCarousel__track--transition {
          -webkit-transition: -webkit-transform;
          transition: -webkit-transform;
          transition: transform;
          transition: transform, -webkit-transform; }
  
  /* arrows */
  .BrainhubCarousel__arrows {
    cursor: pointer; }
  /* variables */
  .BrainhubCarousel__dots {
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 0;
    line-height: 0; }
    .BrainhubCarousel__dots .BrainhubCarousel__dot {
      outline: 0;
      padding: 10px;
      border: none;
      opacity: 0.5;
      cursor: pointer;
      -webkit-appearance: none; }
      .BrainhubCarousel__dots .BrainhubCarousel__dot.BrainhubCarousel__dot--selected {
        opacity: 1 !important; }
      .BrainhubCarousel__dots .BrainhubCarousel__dot:hover {
        opacity: 1; }
      .BrainhubCarousel__dots .BrainhubCarousel__dot:before {
        content: '';
        display: block;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        padding: 0;
        border: none;
        background: black; }
    .BrainhubCarousel__dots .BrainhubCarousel__thumbnail {
      outline: 0;
      padding: 10px;
      border: none;
      opacity: 0.5;
      cursor: pointer; }
      .BrainhubCarousel__dots .BrainhubCarousel__thumbnail.BrainhubCarousel__thumbnail--selected {
        opacity: 1 !important; }
      .BrainhubCarousel__dots .BrainhubCarousel__thumbnail:hover {
        opacity: 1; }
  
  .BrainhubCarousel__thumbnail[type=button] {
    -webkit-appearance: none; }
  .BrainhubCarousel__arrows {
    position: relative;
    padding: 21px;
    border: none;
    overflow: hidden;
    outline: 0;
    font-size: 0;
    line-height: 0;
    background-color: #7b59ff; }
    .BrainhubCarousel__arrows span {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      border-style: solid;
      border-color: #fff;
      border-width: 3px 3px 0 0;
      padding: 5px;
      -webkit-transition: 0.3s;
      transition: 0.3s;
      font-size: 0; }
    .BrainhubCarousel__arrows:hover {
      background-color: #8768ff; }
      .BrainhubCarousel__arrows:hover span {
        border-color: #fff;
        margin: 0; }
  
  .BrainhubCarousel__arrowLeft span {
    -webkit-transform: translate(-50%, -50%) rotate(-135deg);
            transform: translate(-50%, -50%) rotate(-135deg);
    margin-left: 2.45px; }
  
  .BrainhubCarousel__arrowRight span {
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
            transform: translate(-50%, -50%) rotate(45deg);
    margin-left: -2.45px; }
  .BrainhubCarouselItem {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative; }
    .BrainhubCarouselItem.BrainhubCarouselItem--clickable {
      cursor: pointer; }
    .BrainhubCarouselItem .debug-number {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      font-size: 2em;
      text-shadow: 0px 0px 9px white; }
.slide h1 {
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
	-webkit-transform: translateY(-20px);
					transform: translateY(-20px);
	opacity: 0;
}

.slide button {
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
	-webkit-transform: translateY(20px);
					transform: translateY(20px);
	opacity: 0;
}

.slide p {
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
	-webkit-transform: translateY(20px);
					transform: translateY(20px);
	opacity: 0;
}

.slide section * {
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.slide section img {
	-webkit-transform: translateX(-10px);
					transform: translateX(-10px);
	opacity: 0;
}

.slide section span {
	-webkit-transform: translateY(-10px);
					transform: translateY(-10px);
	opacity: 0;
}

.slide section span strong {
	-webkit-transform: translateY(10px);
					transform: translateY(10px);
	opacity: 0;
}

.slide.animateIn.previous h1,
.slide.current h1,
.slide.animateIn.next h1,
.slide.animateIn.previous button,
.slide.current button,
.slide.animateIn.next button,
.slide.animateIn.previous p,
.slide.current p,
.slide.animateIn.next p,
.slide.animateIn.previous section *,
.slide.current section *,
.slide.animateIn.next section * {
	-webkit-transform: translateX(0);
	        transform: translateX(0);
	-webkit-transition-delay: .9s;
					transition-delay: .9s;
					opacity: 1;
}

.slide.animateIn.previous p,
.slide.animateIn.next p {
	-webkit-transition-delay: 1.1s;
					transition-delay: 1.1s;
}

.slide.animateIn.previous button,
.slide.animateIn.next button {
	-webkit-transition-delay: 1.3s;
					transition-delay: 1.3s;
}

.slide.animateIn.previous section img,
.slide.animateIn.next section img {
	-webkit-transition-delay: 1.3s;
					transition-delay: 1.3s;
}

.slide.animateIn.previous section span,
.slide.animateIn.next section span {
	-webkit-transition-delay: 1.4s;
					transition-delay: 1.4s;
}

.slide.animateIn.previous section span strong,
.slide.animateIn.next section span strong {
	-webkit-transition-delay: 1.5s;
					transition-delay: 1.5s;
}

.slide.animateOut h1 {
	-webkit-transition-delay: .3s;
					transition-delay: .3s;
}

.slide.animateOut p {
	-webkit-transition-delay: .2s;
					transition-delay: .2s;
}

.slide.animateOut section span {
	-webkit-transition-delay: .1s;
					transition-delay: .1s;
}

.slide.animateOut section span strong {
	-webkit-transition-delay: 0s;
					transition-delay: 0s;
}

wrapper {
    max-width: 1335px;
    box-sizing: border-box;
    padding: 0 20px;
    margin: auto;
  }
  
  .wrapper a {
    display: inline-block;
    margin: 5px;
  }
  
  .wrapper a:first-child {
    margin-left: 0;
  }
  
  .slider-wrapper {
    position: relative;
    height: 70vh;
    overflow: hidden;
  }
  
  .slide {
    height: 70vh;
    background-size: cover !important;
  }
  
  .slide::before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(0, 0, 0, 0.9)));
      background: -webkit-linear-gradient(transparent, rgba(0, 0, 0, 0.9));
      background: linear-gradient(transparent, rgba(0, 0, 0, 0.9));
      bottom: 0;
      left: 0;
  }
  
  .previousButton, .nextButton {
          position: absolute;
          top: 50%;
          -webkit-transform: translateY(-50%);
                  transform: translateY(-50%);
          z-index: 9;
          background: url(/static/media/back.76f65152.svg) no-repeat center center / 16px;
          width: 62px;
          height: 62px;
          text-indent: -9999px;
          cursor: pointer;
  }
  
  .previousButton:hover, .nextButton:hover {
    background: url(/static/media/back.76f65152.svg) no-repeat center center / 16px;
  }
  
  .previousButton {
    left: 0;
    -webkit-transform: rotate(180deg) translateY(calc(50% + 0px));
            transform: rotate(180deg) translateY(calc(50% + 0px));
  }
  
  .previousButton:hover {
    left: -10px;
  }
  
  .nextButton {
    right: 0;
  }
  
  .nextButton:hover {
    right: -10px;
  }
  
  .slider-content {
    text-align: center;
  }
  
  .slider-content .inner {
    padding: 0 70px;
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  
  .slider-content .inner button{
    -webkit-transition-delay: 0s;
            transition-delay: 0s;
  }
  
  .slider-content .inner h1 {
    font-weight: 900;
    margin: 0 auto;
    max-width: 840px;
    color: #FFFFFF;
    font-size: 64px;
    line-height: 1;
  }
  
  .slider-content .inner p {
    color: #FFFFFF;
    font-size: 14px;
    line-height: 1.5;
    margin: 20px auto 30px;
    max-width: 640px;
  }
  
  .slider-content section {
    position: absolute;
    bottom: 20px;
    left: 20px;
  }
  
  .slider-content section span {
    color: #FFFFFF;
  }
  
  .slider-content section span {
    color: rgba(255, 255, 255, 0.5);
    font-size: 12px;
    display: inline-block;
    text-align: left;
    line-height: 1.4;
    vertical-align: middle;
    margin-left: 10px;
  }
  
  .slider-content section img {
    width: 40px;
    height: 40px;
    border: solid 2px rgba(255, 255, 255, 0.5);
    border-radius: 100%;
    vertical-align: middle;
  }
  
  .slider-content section span strong {
    color: #FFFFFF;
    font-size: 14px;
    display: block;
  }
  
  @media (max-height: 500px) {
    .slider-wrapper, .slide {
      height: calc(100vh - 75px);
    }    
  }
  
  @media (max-width: 640px) {
    .slider-wrapper, .slide {
      height: calc(80vh - 75px);
    }    
  }
  
  @media (max-height: 600px) {
    .slider-content .inner h1 {
      font-size: 32px;
    }    
  }
  
  @media (max-width: 640px) {
    .slider-content .inner h1 {
      font-size: 32px;
    }
  }
* {
  outline: none;
  font-family: "Montserrat";
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

::-webkit-scrollbar-track {
  background-color: #ffffff;
  border-radius: 10px; }

::-webkit-scrollbar {
  border-radius: 10px;
  height: 6px;
  width: 6px;
  background-color: #ffffff; }

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #919191; }

::-moz-selection {
  background-color: #FF5722;
  color: #FFFFFF; }

::selection {
  background-color: #FF5722;
  color: #FFFFFF; }

body {
  background-color: #FFFFFF;
  margin: 0px;
  padding: 0px; }

.flex-row {
  display: flex;
  flex-direction: row; }

.flex-row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; }

.flex-col {
  display: flex;
  flex-direction: column; }

.flex-col-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.bg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%; }

.button-round {
  background-color: #FF5722;
  border-radius: 200px;
  padding: 7px 15px;
  outline: none;
  border: 1px solid #FF5722;
  color: #FFFFFF;
  font-size: 13px;
  cursor: pointer;
  -webkit-transition: all 300ms;
  transition: all 300ms; }
  .button-round:hover {
    background-color: #FFFFFF;
    color: #FF5722;
    -webkit-transition: all 300ms;
    transition: all 300ms; }
  .button-round.inverted {
    border: 1px solid #FF5722;
    color: #FF5722;
    background-color: #FFFFFF;
    border-radius: 200px;
    font-weight: 600;
    font-size: 10px;
    padding: 3px 12px;
    -webkit-transition: all 200ms;
    transition: all 200ms;
    letter-spacing: 0.3px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content; }
    .button-round.inverted:hover {
      color: #FFFFFF;
      background-color: #FF5722;
      -webkit-transition: all 200ms;
      transition: all 200ms; }

.top-nav-bar {
  height: 80px;
  width: 100%;
  justify-content: space-between;
  padding: 0px 20px;
  box-sizing: border-box;
  border-bottom: 1px solid #aaaaaa; }

.logo-container .logo {
  height: 50px;
  width: 50px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAABhGlDQ1BJQ0MgcHJvZmlsZQAAKJF9kTtIw0Acxr+m1opUHMwg4pChioMFX4ijVqEIFUKt0KqDefQFTRqSFBdHwbXg4GOx6uDirKuDqyAIPkBcXJ0UXaTE/yWFFjEeHPfju/s+7r4DuHpZ0ayOMUDTbTOViAuZ7KoQfkUIPDoxgnFJsYw5UUzCd3zdI8DWuxjL8j/35+hRc5YCBATiWcUwbeIN4ulN22C8T8wrRUklPiceNemCxI9Mlz1+Y1xwmWOZvJlOzRPzxEKhjeU2VoqmRjxFHFU1nfK5jMcq4y3GWrmqNO/JXhjJ6SvLTKc5iAQWsQQRAmRUUUIZNmK06qRYSNF+3Mc/4PpFcsnkKkEhxwIq0CC5frA/+N2tlZ+c8JIicSD04jgfQ0B4F2jUHOf72HEaJ0DwGbjSW/5KHZj5JL3W0qJHQO82cHHd0uQ94HIH6H8yJFNypSBNLp8H3s/om7JA3y3Qveb11tzH6QOQpq6SN8DBITBcoOx1n3d3tff275lmfz+TOHK0bBM3WgAAAAZiS0dEAP8A/wD/oL2nkwAAAAlwSFlzAAAuIwAALiMBeKU/dgAAAAd0SU1FB+MFFAYsLTP8x10AAAAZdEVYdENvbW1lbnQAQ3JlYXRlZCB3aXRoIEdJTVBXgQ4XAAACiklEQVRo3u2YPUhbYRSGH60OSbpU69AxhaCoBLJIaEgoRKRjEbEugptVujkLgmPJVkzdpF1Egjj0R4K3lBRLaAu3BJRIKKG0m9qpiUNo0uF4SbSY2nhtb5rzjvfnvTyce77ve09b5d7NCi2kdlpMCqzACqzACqzACqzACqzACqzACqzArQjccelfCIxBMApeH3T3gMst14+KcLgP+RykDTATfwW47dJGPMFJGBmFfj98O4C9HchmIGfKfV8A+vzQOwBd12E3A8l1SD9tQuCpGISHoViAbQNW5+s/P7EIoSi4PfBmC1bmmgh4dhkiw2C+g7U45FPne88bgfEZCAxBaguWppsAeCoGd+7C29fw6D6US7/2MkB85owltBMePIZbt2Fz41Iq3WFrz4aPK3sa1vrFPVePgc/wKJfkXdcTeT47aXtP27ctjYxKz67FT8LOLkvVLdjfqVwSj2JBPB25DwfGZDXeNk72rNXPf6p8Srz6/eLtOOBgVLae2tV4KtYYrKXVefG0+t5RwF6f7LO1FQ8PX9x3b0e8HQfc3SOHitqKn7dn6ymbEW/HAbvc1ROUncqZ1aOohod/CXxUlLOx3fIFxNtxwIf7EgQspQ0ofL+4b59fvB0HnM9J6rFkJiQEXFS9A+LtOOC0IRFvYrF6bWVOQkCjmlgUz7ThQGAzIXk2FJXUY2lpujFob0S8djO2DwbsW6WT65Jnx2ck9dRCb26cv6fbO8XD7RFPm3VlYfDagi1OXzPQ5YNwFG4MwvuXUCnLvY9J+HIAPyrw+RN8eF4/Hg6F4NULePZQBwA64nEEsDUQaJkh3ums3BJj2v/6LK3ACqzACqzACqzACqzACqzACqzACqzAjtZPjcz5zAh/AfYAAAAASUVORK5CYII=); }

.logo-container .logo-text {
  font-family: "Quicksand";
  font-weight: 500;
  font-size: 15px;
  margin-left: 10px;
  letter-spacing: 0.4px; }

.body-section {
  width: 100%;
  height: calc(100vh - 80px);
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start; }

.taskbar {
  -webkit-transition: all 400ms;
  transition: all 400ms;
  margin: 0px auto;
  width: 25%;
  min-width: 280px;
  align-items: center;
  overflow-y: scroll;
  padding: 10px;
  box-sizing: border-box; }
  @media screen and (max-width: 600px) {
    .taskbar {
      width: 90%; } }
  .taskbar .item {
    width: 70%;
    margin-bottom: 5px;
    padding: 10px 10px;
    box-sizing: border-box;
    align-items: center;
    cursor: pointer;
    border-radius: 4px; }
    .taskbar .item:nth-child(1) {
      margin-top: 15px; }
    .taskbar .item.active {
      background-color: #eaeaea; }
    .taskbar .item:hover {
      background-color: #eaeaea; }
    .taskbar .item .icon {
      height: 16px;
      width: 16px;
      margin-right: 10px; }
    .taskbar .item .text {
      font-family: "Quicksand";
      font-weight: 500;
      font-size: 13px;
      letter-spacing: 0.2px; }

.body-container {
  -webkit-transition: all 400ms;
  transition: all 400ms;
  height: 100%;
  width: 75%;
  margin: auto;
  padding: 20px;
  box-sizing: border-box; }
  @media screen and (max-width: 600px) {
    .body-container {
      width: 100%;
      padding: 15px; } }
  @media screen and (max-width: 1000px) and (min-width: 601px) {
    .body-container {
      width: 100%;
      padding: 20px; } }

.table-container {
  width: 100%;
  border: 1px solid #aaaaaa;
  border-radius: 4px;
  background-color: #FFFFFF;
  overflow-x: scroll;
  margin-bottom: 20px; }
  @media screen and (max-width: 450px) {
    .table-container::-webkit-scrollbar-track {
      background-color: #ffffff;
      border-radius: 10px; }
    .table-container::-webkit-scrollbar {
      border-radius: 10px;
      height: 6px;
      width: 3px;
      background-color: #ffffff; }
    .table-container::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #919191; } }
  .table-container .header-container {
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    padding: 10px 20px;
    box-sizing: border-box;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-height: 70px; }
    .table-container .header-container .text {
      text-transform: uppercase;
      font-weight: 500;
      font-size: 14px; }
    .table-container .header-container .button-round {
      font-weight: 500; }
  .table-container .user-list-container {
    width: 100%;
    border-top: 1px solid #aaaaaa;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 50px;
    box-sizing: border-box; }
    @media screen and (max-width: 450px) {
      .table-container .user-list-container {
        padding: 10px 10px; } }
    .table-container .user-list-container .name-section {
      display: flex;
      flex-direction: row;
      align-items: center; }
      .table-container .user-list-container .name-section .icon {
        height: 30px;
        width: 30px;
        background-color: #d8d8d8;
        border-radius: 50%;
        margin: auto;
        margin-right: 10px; }
      .table-container .user-list-container .name-section .text {
        font-size: 12px;
        font-weight: 500;
        word-break: break-all;
        margin: auto; }
    .table-container .user-list-container .action-container {
      display: flex;
      flex-direction: row; }
      .table-container .user-list-container .action-container .button {
        text-align: center;
        padding: 5px 10px;
        font-size: 12px;
        font-weight: 500;
        margin-right: 10px; }
        .table-container .user-list-container .action-container .button:nth-last-child(1) {
          margin-right: 0px; }

.main-table {
  width: 100%;
  border-collapse: collapse; }
  .main-table .heading {
    border-top: 1px solid #aaaaaa;
    border-bottom: 1px solid #aaaaaa; }
    .main-table .heading td {
      padding: 10px 5px;
      box-sizing: border-box;
      font-size: 13px;
      text-align: center;
      font-weight: 500; }
  .main-table .content {
    border-bottom: 1px solid #aaaaaa; }
    .main-table .content:nth-last-child(1) {
      border-bottom: 0px solid transparent; }
    .main-table .content .package-name-click {
      cursor: pointer; }
      .main-table .content .package-name-click:hover {
        background-color: #fafafa; }
    .main-table .content td {
      padding: 20px 10px;
      box-sizing: border-box;
      font-size: 13px;
      font-weight: 500;
      text-align: center; }
      .main-table .content td.action-box {
        justify-content: space-around;
        flex-wrap: wrap; }
        .main-table .content td.action-box .button-round {
          padding: 5px 20px;
          margin-bottom: 5px; }
      .main-table .content td.proof-box {
        text-align: center;
        position: relative; }
      .main-table .content td .proof {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        height: 30px;
        width: 40px;
        border-radius: 8px;
        background-color: #d8d8d8;
        border: 1px solid #999999; }
  .main-table .package-container-div {
    width: 100%; }
    .main-table .package-container-div a {
      text-decoration: none;
      color: black; }
    .main-table .package-container-div .remove-button {
      margin: 10px auto; }
    .main-table .package-container-div .cloumn-div {
      display: flex;
      flex-direction: column; }
    .main-table .package-container-div .inner-container {
      padding: 10px;
      box-sizing: border-box;
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      border-bottom: 1px solid #aaaaaa; }
      .main-table .package-container-div .inner-container .count {
        background-color: #FF5722;
        color: #FFFFFF;
        padding: 5px 5px;
        height: 15px;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        font-size: 11px;
        font-weight: 700;
        border-radius: 100px;
        -webkit-transform: scale(0.8);
                transform: scale(0.8);
        min-width: 14px;
        text-align: center;
        margin: 0px 10px;
        margin-top: 10px; }

.package-table {
  margin: auto; }
  .package-table .content .product-image {
    height: 50px;
    width: 50px;
    border-radius: 5px;
    border: 1px solid #aaaaaa;
    background-color: #d8d8d8;
    margin: auto; }
  .package-table .content .package-name {
    font-weight: 600;
    font-size: 12px;
    margin: auto; }
  .package-table .content .description {
    font-weight: 300;
    max-width: 250px;
    word-break: break-all;
    font-size: 12px;
    font-family: "Roboto";
    color: #333333;
    letter-spacing: 0.2px;
    line-height: 18px; }

.products-section {
  width: 100%;
  padding: 20px 50px;
  box-sizing: border-box;
  border-bottom: 1px solid #aaaaaa; }
  @media screen and (max-width: 450px) {
    .products-section {
      padding: 20px 10px; } }
  .products-section .heading-section {
    width: 100%;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap; }
    .products-section .heading-section .heading_text {
      font-weight: 500;
      font-size: 13px; }
      @media screen and (max-width: 450px) {
        .products-section .heading-section .heading_text {
          margin: 10px auto; } }
  .products-section .product-list-section {
    flex-wrap: wrap;
    justify-content: space-between; }

.search-section {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative; }
  @media screen and (max-width: 450px) {
    .search-section {
      margin: auto; } }
  .search-section .input {
    border: 1px solid #FF5722;
    border-radius: 200px;
    font-size: 12px;
    font-family: "Quicksand";
    letter-spacing: 0.5px;
    padding: 5px 55px 5px 15px;
    min-width: 300px;
    font-weight: 500; }
    @media screen and (max-width: 450px) {
      .search-section .input {
        margin-bottom: 10px; } }
    .search-section .input::-webkit-input-placeholder {
      color: #555555;
      font-size: 12px;
      font-weight: 500; }
    .search-section .input::-moz-placeholder {
      color: #555555;
      font-size: 12px;
      font-weight: 500; }
    .search-section .input:-ms-input-placeholder {
      color: #555555;
      font-size: 12px;
      font-weight: 500; }
    .search-section .input::-ms-input-placeholder {
      color: #555555;
      font-size: 12px;
      font-weight: 500; }
    .search-section .input::placeholder {
      color: #555555;
      font-size: 12px;
      font-weight: 500; }
  .search-section .add_button {
    color: #FFFFFF;
    background-color: #FF5722;
    font-weight: 600;
    font-size: 11px;
    border-radius: 200px;
    position: absolute;
    right: 2px;
    top: 2px;
    padding: 4.5px 0px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer; }

.product_card {
  flex-wrap: wrap;
  background-color: #FFFFFF;
  padding: 10px;
  box-sizing: border-box;
  margin: 50px auto 0px auto;
  max-width: 45%;
  min-width: 320px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  font-size: 12px;
  font-weight: 500; }
  @media screen and (max-width: 450px) {
    .product_card {
      margin: 10px auto; } }
  @media screen and (max-width: 450px) {
    .product_card {
      width: 95%; } }
  .product_card .image {
    height: 80px;
    width: 80px;
    background-color: #d8d8d8;
    border-radius: 3px;
    border: 1px solid #999999;
    margin: auto; }
    @media screen and (max-width: 450px) {
      .product_card .image {
        margin: 20px auto; } }
  .product_card .top-section {
    align-items: center;
    justify-content: space-between; }
    .product_card .top-section .text {
      word-break: break-all;
      font-size: 12px;
      line-height: 19px;
      padding-right: 15px;
      box-sizing: border-box;
      text-transform: uppercase; }
    .product_card .top-section .remove-button {
      outline: none;
      cursor: pointer;
      -webkit-transition: all 300ms;
      transition: all 300ms;
      border: 1px solid #FF5722;
      color: #FF5722;
      background-color: #FFFFFF;
      border-radius: 200px;
      font-weight: 600;
      font-size: 10px;
      padding: 3px 12px;
      -webkit-transition: all 200ms;
      transition: all 200ms;
      letter-spacing: 0.3px;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      margin-right: 0px; }
      .product_card .top-section .remove-button:hover {
        color: #FFFFFF;
        background-color: #FF5722;
        -webkit-transition: all 200ms;
        transition: all 200ms; }
  .product_card .description {
    color: #999999;
    line-height: 18px;
    margin-bottom: 10px;
    text-align: justify; }
  .product_card .bottom-section {
    align-items: center; }
    .product_card .bottom-section .text {
      text-transform: uppercase;
      margin-right: 5px; }
    .product_card .bottom-section .price {
      font-size: 13px;
      font-weight: 600;
      margin-left: 10px; }
    .product_card .bottom-section .selection-box {
      align-items: center;
      padding: 1px 5px;
      background-color: #d8d8d8;
      border: 1px solid #999999;
      border-radius: 2px;
      font-size: 10px;
      cursor: pointer;
      margin-left: 5px; }
      .product_card .bottom-section .selection-box .count {
        margin-right: 3px; }
      .product_card .bottom-section .selection-box .icon {
        height: 5px;
        width: 5px;
        background-image: url(/static/media/down-arrow.347ce080.svg); }

.example-appear {
  opacity: 0.01; }

.example-appear.example-appear-active {
  opacity: 1;
  -webkit-transition: opacity .5s ease-in;
  transition: opacity .5s ease-in; }

.modalmain {
  display: flex;
  flex-direction: 'row';
  justify-content: space-between; }

.input_text {
  padding: 5px 10px;
  font-size: 14px;
  border: 0px;
  box-shadow: 0px 0px 2px 1px #dadada;
  font-family: "Roboto";
  letter-spacing: 0.2px;
  border-radius: 2px; }

.add_image_box {
  height: 60px;
  width: 50px;
  border-radius: 5px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px 0 0 9px;
  box-sizing: border-box;
  cursor: pointer;
  -webkit-transition: box-shadow .2s;
  transition: box-shadow .2s;
  border: 1px dotted #aaa; }

.add_button {
  height: 30px;
  width: 30px;
  background-color: #ff5722;
  border-radius: 50%;
  background-image: url(/src/assets/add-icon.svg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 50%; }

.add_button_image_img {
  box-sizing: border-box;
  border-radius: 20px;
  height: 30px;
  width: 30px;
  background: url(/src/assets/add-icon.svg);
  padding: 30px 0 0; }

.flip-box {
  background-color: transparent;
  width: 80px;
  height: 60px;
  margin-left: 10px;
  -webkit-perspective: 1000px;
  perspective: 1000px; }

.flip-box-inner {
  position: relative;
  width: 80px;
  height: 60px;
  text-align: center;
  -webkit-transition: -webkit-transform .8s;
  transition: -webkit-transform .8s;
  transition: transform .8s;
  transition: transform .8s, -webkit-transform .8s;
  transition: transform .8s,-webkit-transform .8s;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d; }

.flip-box-front {
  position: absolute;
  width: 80px;
  height: 60px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

.flip-box-back {
  background-color: #555;
  border-radius: 10px;
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box; }

.mytop_flex .item_box {
  display: flex;
  flex-direction: column;
  min-width: 310px;
  min-height: 200px;
  background-color: #fff;
  border-radius: 1px;
  box-shadow: 0 0 5px 1px rgba(20, 20, 20, 0.2);
  padding: 7px;
  box-sizing: border-box;
  margin-bottom: 20px; }

.mytop_flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap; }

.mytop_flex .item_box .header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-family: Quicksand;
  color: #888;
  font-size: 15px;
  height: 30px;
  align-items: center;
  padding-right: 10px;
  box-sizing: border-box;
  font-weight: 500; }

.mytop_flex .item_box .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 90px; }

.mytop_flex .item_box .footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-end;
  height: 70px; }

.mydashboard_container {
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: column; }

* {
  outline: none;
  font-family: Quicksand;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.mytop_flex .item_box .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 90px; }

.mytop_flex .item_box .content .text {
  color: #aaa;
  font-family: Quicksand;
  font-size: 13px;
  font-weight: 500; }

.mytop_flex .item_box .content .count {
  color: #333;
  font-family: Quicksand;
  font-size: 42px;
  margin-bottom: 5px; }

.mytop_flex .item_box .footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-end;
  height: 70px; }

.mytop_flex .item_box .footer .box {
  padding: 5px;
  box-sizing: border-box;
  text-align: center; }

.mytop_flex .item_box .footer .box .text {
  color: #aaa;
  font-weight: 500;
  font-family: Quicksand;
  font-size: 13px; }

.mytop_flex .item_box .footer .box .count {
  color: #666;
  font-weight: 500;
  font-family: Quicksand;
  font-size: 13px;
  margin-top: 4px; }

* {
  outline: none;
  font-family: "Quicksand";
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.table_main_box::-webkit-scrollbar-track {
  border-radius: 0px;
  background-color: #FFFFFF; }

.table_main_box::-webkit-scrollbar {
  width: 5px;
  background-color: #FFFFFF; }

.table_main_box::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #FF5722; }

.checkbox + label {
  display: block;
  margin: 0.2em;
  cursor: pointer;
  padding: 0.2em;
  font-family: "Montserrat Alternates";
  -webkit-transition: color 0.2s;
  transition: color 0.2s; }

.wid_50 {
  width: 50px; }

.checkbox {
  display: none; }

.checkbox + label:before {
  content: "\2714";
  box-shadow: 0px 0px 2px 1px #dadada;
  border-radius: 0.2em;
  display: inline-block;
  width: 1em;
  height: 1em;
  padding: 0.1em 0.15em 0.2em 0.19em;
  margin-right: 0.7em;
  vertical-align: bottom;
  color: transparent;
  -webkit-transition: .2s;
  transition: .2s; }

.checkbox + label:active:before {
  -webkit-transform: scale(0);
          transform: scale(0); }

.checkbox:checked + label {
  color: #FF5722;
  -webkit-transition: color 0.2s;
  transition: color 0.2s; }

.checkbox:checked + label:before {
  background-color: #FF5722;
  border-color: #FF5722;
  color: #fff;
  box-shadow: 0px 0px 0px 0px #dadada; }

.checkbox:disabled + label:before {
  -webkit-transform: scale(1);
          transform: scale(1);
  border-color: #aaa; }

.checkbox:checked:disabled + label:before {
  -webkit-transform: scale(1);
          transform: scale(1);
  background-color: #bfb;
  border-color: #bfb; }

.flex_row {
  display: flex;
  flex-direction: row;
  justify-content: center; }

.bg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%; }

body {
  background-color: #ffffff;
  margin: 0px;
  padding: 0px; }

.nav_bar {
  height: 70px;
  width: 100%;
  border-bottom: 1px solid #dadada;
  display: flex;
  padding: 0px 30px;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;
  box-shadow: 0px 0px 3px 1px #dadada;
  position: fixed;
  left: 0px;
  top: 0px;
  background-color: #ffffff;
  z-index: 10; }

.nav_bar .logout_button {
  padding: 7px 22px;
  color: #ffffff;
  background-color: #FF5722;
  font-size: 13px;
  border-radius: 17px;
  font-weight: 500;
  cursor: pointer; }

.subcat_button {
  padding: 3px 11px;
  color: #ffffff;
  background-color: #FF5722;
  font-size: 13px;
  border-radius: 17px;
  font-weight: 500;
  cursor: pointer; }

.nav_bar .logo_section {
  display: flex;
  flex-direction: row;
  align-items: center; }

.logo_section .text {
  font-family: "Roboto";
  letter-spacing: 0.2px; }

.search_bar {
  border: 0;
  box-shadow: 0 0 2px 1px #dadada;
  border-radius: 60px;
  padding: 8px 20px;
  width: 40%;
  font-weight: 500;
  display: block;
  margin-right: 20px;
  text-align: left; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.superbutton {
  background-color: #FF5722;
  border-radius: 100px;
  border: none;
  color: white;
  padding: 10px 27px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
  -webkit-transition-duration: 0.4s;
  /* Safari */
  transition-duration: 0.4s; }

.button2:hover {
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19); }

.body_container {
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: row; }

.body_container .taskbar {
  height: 100%;
  width: 250px;
  padding: 40px 30px;
  box-sizing: border-box;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll; }

.body_container .taskbar::-webkit-scrollbar-track {
  background-color: #ffffff; }

.body_container .taskbar::-webkit-scrollbar {
  width: 6px;
  background-color: #ffffff; }

.body_container .taskbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #919191; }

.taskbar .item_container {
  width: 100%;
  position: relative;
  margin: 3px 0px; }

.taskbar .item_container .sub_menu_container {
  background-color: #f1f1f1;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 3px 10px 5px 30px;
  box-sizing: border-box;
  position: relative;
  top: -2px;
  font-size: 14px; }

.taskbar .item_container .sub_menu_container .sub_item {
  box-sizing: border-box;
  padding: 3px 15px;
  font-family: "Quicksand";
  font-weight: 500;
  border-radius: 5px;
  cursor: pointer;
  -webkit-transition: all 400ms;
  transition: all 400ms; }

.taskbar .item_container .sub_menu_container .sub_item:hover {
  background-color: #FF5722;
  color: #FFFFFF;
  -webkit-transition: all 400ms;
  transition: all 400ms; }

.taskbar .item_container .sub_menu_container .sub_item.active {
  background-color: #FF5722;
  color: #FFFFFF;
  -webkit-transition: all 400ms;
  transition: all 400ms; }

.taskbar .item_container .image {
  position: absolute;
  height: 23px;
  width: 23px;
  /* top: 50%; */
  left: 20px;
  top: 17px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 2;
  background-position: center;
  background-repeat: no-repeat;
  /* background-color: yellow; */
  background-size: 80%; }

.add_button_image_img {
  box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 20px;
  height: 30px;
  width: 30px;
  background: url(/static/media/add-icon.b2fa439d.svg);
  padding: 30px 0px 0px 0px; }

.taskbar .item {
  width: 100%;
  margin-top: 3px;
  background-color: #ffffff;
  padding: 7px 0px 7px 45px;
  position: relative;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  box-sizing: border-box; }

.taskbar .item:hover {
  background-color: #f3f3f3; }

.taskbar .item.active {
  background-color: #FF5722;
  color: #ffffff; }

/* .taskbar .item.active::after {
  content: "";
  height: 6px;
  width: 6px;
  border: 2px solid #ffffff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translate(-50%, -50%);
} */
/* .taskbar .item::after {
  content: "";
  height: 6px;
  width: 6px;
  border: 2px solid black;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translate(-50%, -50%);
} */
.body_container .inner_body {
  height: calc(100vh - 70px);
  width: calc(100% - 250px);
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 30px;
  box-sizing: border-box;
  position: relative; }

.body_container .inner_body::-webkit-scrollbar-track {
  background-color: #ffffff; }

.body_container .inner_body::-webkit-scrollbar {
  width: 6px;
  background-color: #ffffff; }

.body_container .inner_body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #919191; }

.table_main_box {
  box-shadow: 0px 0px 3px 1px #cdcdcd;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  overflow-x: hidden; }

.table_main_box .pagination_container {
  box-sizing: border-box;
  width: 100%;
  padding: 30px 50px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  border-top: 1px solid #dadada; }

.pagination_container .left_button {
  height: 20px;
  width: 20px;
  background-color: #FF5722;
  border-radius: 3px;
  margin-right: 5px;
  background-image: url(/static/media/down-arrow.347ce080.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 60%;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  cursor: pointer; }

.pagination_container .right_button {
  height: 20px;
  width: 20px;
  background-color: #FF5722;
  border-radius: 3px;
  margin-left: 1px;
  background-image: url(/static/media/down-arrow.347ce080.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 60%;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  cursor: pointer; }

.pagination_container .button {
  min-height: 20px;
  min-width: 20px;
  background-color: #ffffff;
  border-radius: 50%;
  font-size: 14px;
  text-align: center;
  margin-right: 4px;
  cursor: pointer;
  padding: 5px;
  background-color: #f6f7f8;
  -webkit-transition: all 500ms;
  transition: all 500ms; }

.pagination_container .button:hover {
  background-color: #eaeaea;
  -webkit-transition: all 500ms;
  transition: all 500ms; }

.table_main_box .header_container {
  width: 100%;
  padding: 20px 30px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  border-bottom: 1px solid #dadada; }

.table_main_box .header_container .button_container {
  display: flex;
  flex-direction: row;
  align-items: center; }

.table_main_box .header_container .header {
  font-size: 14px;
  font-weight: 500; }

.table_main_box .header_container .add_button {
  padding: 5px 20px 7px 20px;
  color: #ffffff;
  background-color: #FF5722;
  font-size: 13px;
  border-radius: 17px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center; }

.table_main_box .header_container .delete_button {
  height: 27px;
  width: 27px;
  margin-right: 10px;
  /* background-image: url(../assets/waste-bin.svg); */
  background-position: center;
  background-size: 90%;
  background-repeat: no-repeat;
  cursor: pointer;
  position: relative; }

.table_main_box .header_container .delete_button .count {
  position: absolute;
  top: -4px;
  right: -4px;
  background-color: #009966;
  padding: 5px;
  border-radius: 50px;
  font-size: 10px;
  color: #FFFFFF;
  min-width: 12px;
  text-align: center;
  -webkit-transform: scale(0.7);
          transform: scale(0.7); }

.table_main_box .header_container .search_bar {
  border: 0px;
  box-shadow: 0px 0px 2px 1px #dadada;
  border-radius: 60px;
  padding: 8px 20px;
  width: 40%;
  font-weight: 500;
  display: block;
  margin-right: 20px;
  text-align: left; }

.table_main_box .header_container .search_bar::-webkit-input-placeholder {
  text-align: center; }

.table_main_box .header_container .search_bar::-moz-placeholder {
  text-align: center; }

.table_main_box .header_container .search_bar:-ms-input-placeholder {
  text-align: center; }

.table_main_box .header_container .search_bar::-ms-input-placeholder {
  text-align: center; }

.table_main_box .header_container .search_bar::placeholder {
  text-align: center; }

.table_main_box .header_container .add_button span {
  font-size: 17px;
  margin-right: 5px; }

.inner_table_container {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  font-size: 14px; }

.inner_table_container .header td {
  padding: 10px 5px;
  font-weight: 500;
  box-sizing: border-box;
  border-collapse: collapse; }

.inner_table_container .body {
  border-top: 1px solid #dadada; }

.inner_table_container .body td {
  padding: 20px 5px;
  box-sizing: border-box;
  border-collapse: collapse;
  font-family: "Roboto"; }

.inner_table_container .body:nth-child(1) td {
  border-top: 0px solid #dadada; }

.inner_table_container .body .button_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  box-sizing: border-box; }

.inner_table_container .button {
  background-color: #FF5722;
  padding: 5px 20px 7px 20px;
  margin: 0px 5px;
  color: #ffffff;
  font-weight: 500;
  border-radius: 50px;
  cursor: pointer; }

.inner_table_container .button_withicon {
  /* background-color: #FF5722; */
  padding: 0px;
  margin: 0px 5px;
  color: #ffffff;
  font-weight: 500;
  border-radius: 50px;
  cursor: pointer; }

.text-right {
  text-align: right; }

.table_main_box .input_box_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 20px;
  box-sizing: border-box;
  border-top: 1px solid #dadada; }

.half_section {
  width: 70%;
  display: flex;
  align-items: center;
  flex-wrap: wrap; }

.half_section.f {
  width: 30%; }

.flex_end {
  display: flex;
  justify-content: flex-end;
  padding-right: 5px; }

.flex_start {
  justify-content: flex-start;
  padding-left: 5px; }

.flex_col {
  display: flex;
  flex-direction: column;
  align-items: flex-start; }

.flex_row {
  display: flex;
  flex-direction: row;
  align-items: center; }

.pad-5-15 {
  padding: 5px 15px; }

.down-3 {
  position: relative;
  top: 1.5px; }

.input_box_container .text {
  margin-right: 20px;
  font-weight: 500;
  font-size: 15px; }

.input_box_container .input_text {
  padding: 5px 10px;
  font-size: 14px;
  border: 0px;
  box-shadow: 0px 0px 2px 1px #dadada;
  font-family: "Roboto";
  letter-spacing: 0.2px;
  border-radius: 2px; }

select {
  font-weight: 500;
  padding: 4px 10px; }

.publish_button {
  background-color: #FF5722;
  padding: 5px 20px 6px 20px;
  cursor: pointer;
  color: #ffffff;
  border-radius: 50px;
  font-weight: 500;
  font-size: 15px; }

.noti_container .counter {
  background-color: #009966;
  min-width: 15px;
  padding: 5px;
  border-radius: 50%;
  color: #ffffff;
  font-size: 10px;
  font-weight: 500;
  text-align: center;
  position: absolute;
  top: 0px;
  left: 0px;
  -webkit-transform: scale(0.9);
          transform: scale(0.9); }

.notification_box_container {
  position: absolute;
  width: 300px;
  background-color: #FFFFFF;
  top: 25px;
  right: 20px;
  box-shadow: 0px 0px 2px 2px #dadada;
  border-radius: 5px;
  padding-top: 15px;
  box-sizing: border-box;
  z-index: 1; }

.scroll_box {
  height: 300px;
  overflow-x: scroll; }

.scroll_box::-webkit-scrollbar-track {
  border-radius: 0px;
  background-color: #FFFFFF; }

.scroll_box::-webkit-scrollbar {
  width: 5px;
  background-color: #FFFFFF; }

.scroll_box::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #FF5722; }

.notification_box_container::after {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  background-color: #FFFFFF;
  left: calc(57% - 10px);
  top: -7px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  box-shadow: 0px 0px 2px 2px #dadada;
  -webkit-clip-path: inset(-3px 5px 5px -3px);
          clip-path: inset(-3px 5px 5px -3px);
  pointer-events: none; }

.notification_box_container .notification {
  width: 100%;
  padding: 10px 10px;
  box-sizing: border-box;
  border-bottom: 1px solid #efefef;
  font-size: 15px;
  display: flex;
  flex-direction: row;
  align-items: center; }

.notification_box_container .notification .data_box {
  display: column;
  align-items: flex-start;
  padding: 3px 10px 3px 20px;
  width: calc(100% - 80px); }

.notification_box_container .notification .data_box .title {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.3px; }

.notification_box_container .notification .data_box .user {
  font-size: 13px;
  color: #aaaaaa;
  letter-spacing: 0.1px;
  font-family: "Roboto"; }

.notification_box_container .notification .tick_box {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center; }

.notification_box_container .notification .tick_box .tick {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-image: url(/static/media/success.d284b547.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 87%;
  cursor: pointer; }

.input_box_container .add_image_box {
  height: 60px;
  width: 50px;
  border-radius: 5px;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px 0px 0px 9px;
  box-sizing: border-box;
  /* box-shadow: 0px 0px 2px 1px #dadada; */
  cursor: pointer;
  -webkit-transition: box-shadow 200ms;
  transition: box-shadow 200ms;
  border: 1px dotted #aaaaaa; }

.input_box_container .add_image_box:hover {
  box-shadow: 0px 0px 1px 1px #dadada;
  -webkit-transition: box-shadow 200ms;
  transition: box-shadow 200ms; }

.input_box_container .add_image_box .add_button {
  height: 30px;
  width: 30px;
  background-color: #FF5722;
  border-radius: 50%;
  background-image: url(/static/media/add-icon.b2fa439d.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%; }

.input_box_container .category_main_box {
  width: 90%;
  min-height: 300px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  border: 1px solid #dadada; }

.category_main_box .half_section {
  width: 50%;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 300px;
  flex-wrap: nowrap;
  box-sizing: border-box;
  padding: 5px 15px; }

.category_main_box .half_section::-webkit-scrollbar-track {
  border-radius: 0px;
  background-color: #FFFFFF; }

.category_main_box .half_section::-webkit-scrollbar {
  width: 5px;
  background-color: #FFFFFF; }

.category_main_box .half_section::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #FF5722; }

.category_main_box .half_section .search_bar {
  width: 100%;
  padding: 13px 10px;
  box-sizing: border-box;
  background-color: #FFFFFF;
  border: 0px;
  border-bottom: 1px solid #dadada;
  font-weight: 500;
  letter-spacing: 0.2px; }

.category_main_box .half_section.left {
  border-right: 1px solid #dadada;
  align-items: flex-start; }

.category_main_box .half_section .cat_box {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  background-color: #fafafa;
  font-family: "Montserrat Alternates";
  margin: 2px 0px; }

.category_main_box .half_section .cat_box .cat {
  padding: 2px 5px;
  font-size: 15px;
  box-sizing: border-box;
  display: flex;
  align-items: center; }

.category_main_box .half_section.left .cat_box .icon {
  height: 30px;
  width: 30px;
  background-color: #999999;
  cursor: pointer;
  -webkit-transition: background-color 300ms;
  transition: background-color 300ms;
  background-image: url(/static/media/add-icon.b2fa439d.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 35%; }

.category_main_box .half_section.left .cat_box .icon:hover {
  background-color: #555555;
  -webkit-transition: background-color 300ms;
  transition: background-color 300ms; }

.category_main_box .half_section.left .cat_box .icon_active {
  background-color: #555555;
  -webkit-transition: background-color 300ms;
  transition: background-color 300ms; }

.category_main_box .half_section.right .cat_box .icon {
  height: 30px;
  width: 30px;
  background-color: #999999;
  cursor: pointer;
  -webkit-transition: background-color 300ms;
  transition: background-color 300ms;
  background-image: url(/static/media/cross-icon.c0f4396b.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 35%; }

.category_main_box .half_section.right .cat_box .icon:hover {
  background-color: red;
  -webkit-transition: background-color 300ms;
  transition: background-color 300ms; }

select {
  font-weight: 500;
  padding: 4px 10px; }

.publish_button {
  background-color: #FF5722;
  padding: 5px 20px 6px 20px;
  cursor: pointer;
  color: #ffffff;
  border-radius: 50px;
  font-weight: 500;
  font-size: 15px; }

.product_table {
  /* min-height: 176px; */
  min-width: 50%;
  text-align: center; }

.product_table_tr {
  height: 45px; }

.product-image-list {
  width: 35px;
  height: 33px;
  border-radius: 50%; }

.link_underline {
  text-decoration: none;
  color: #000102; }

.radio_box {
  display: none;
  position: relative; }

.radio_box + label {
  position: relative;
  padding-left: 27px;
  cursor: pointer;
  font-size: 15px;
  font-family: "Montserrat Alternates"; }

.radio_box + label:before {
  content: "";
  height: 20px;
  width: 20px;
  background-color: #FF5722;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 50%;
  vertical-align: baseline; }

.radio_box:checked + label:after {
  content: "";
  height: 10px;
  width: 10px;
  background-color: #FFFFFF;
  position: absolute;
  top: 5px;
  left: 5px;
  border-radius: 50%; }

.radio_box:checked + label {
  color: #FF5722; }

.alert_container {
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 40%;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 10px 4px #bdbdbd;
  z-index: 10;
  border-radius: 2px;
  padding: 40px 60px;
  box-sizing: border-box; }

.alert_container .text {
  font-weight: 500;
  width: 100%; }

.alert_container .button_container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 50px; }

.alert_container .button_container .button {
  padding: 7px 15px;
  background-color: #FF5722;
  color: #FFFFFF;
  font-family: "Montserrat Alternates";
  border: 0px;
  border-radius: 2px;
  font-size: 15px;
  cursor: pointer;
  font-weight: 500; }

.alert_container .button_container .button.cancel {
  background-color: #009966;
  margin-right: 10px; }

select {
  font-weight: 500;
  padding: 4px 10px;
  background-color: #FFFFFF;
  border-radius: 3px;
  cursor: pointer; }

.order_status_icons {
  width: 32px;
  height: 24px; }

.order_status_icons_del {
  width: 32px;
  height: 18px; }

.flip-box {
  background-color: transparent;
  width: 80px;
  height: 60px;
  /* border: 1px solid #f1f1f1; */
  margin-left: 10px;
  -webkit-perspective: 1000px;
          perspective: 1000px; }

.flip-box-inner {
  position: relative;
  width: 80px;
  height: 60px;
  text-align: center;
  -webkit-transition: -webkit-transform 0.8s;
  transition: -webkit-transform 0.8s;
  transition: transform 0.8s;
  transition: transform 0.8s, -webkit-transform 0.8s;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d; }

.flip-box:hover .flip-box-inner {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg); }

.flip-box-front, .flip-box-back {
  position: absolute;
  width: 80px;
  height: 60px;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden; }

.flip-box-front {
  /* background-color: #bbb; */
  color: black;
  border-radius: 10px; }

.flip-box-back {
  background-color: #555;
  /* color: white; */
  border-radius: 10px;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box; }

.error_text {
  /* height: 30px; */
  /* line-height: 30px; */
  /* width: 100%; */
  color: red;
  /* margin-top: 15px; */
  text-align: center;
  font-family: "Quicksand";
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.3px; }

.inner_body .dashboard_container {
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: column; }

.dashboard_container .top_flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap; }

.dashboard_container .top_flex .item_box {
  display: flex;
  flex-direction: column;
  min-width: 310px;
  min-height: 200px;
  background-color: #FFFFFF;
  border-radius: 1px;
  box-shadow: 0px 0px 5px 1px rgba(20, 20, 20, 0.2);
  padding: 7px;
  box-sizing: border-box;
  margin-bottom: 20px; }

.dashboard_container .top_flex .item_box .header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-family: "Quicksand";
  color: #888888;
  font-size: 15px;
  height: 30px;
  align-items: center;
  padding-right: 10px;
  box-sizing: border-box;
  font-weight: 500; }

.dashboard_container .top_flex .item_box .header span {
  color: #FF5722;
  margin-left: 5px; }

.dashboard_container .top_flex .item_box .content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 90px; }

.dashboard_container .top_flex .item_box .content .count {
  color: #333333;
  font-family: "Quicksand";
  /* font-weight: 500; */
  font-size: 42px;
  margin-bottom: 5px; }

.dashboard_container .top_flex .item_box .content .text {
  color: #AAAAAA;
  font-family: "Quicksand";
  font-size: 13px;
  font-weight: 500; }

.dashboard_container .top_flex .item_box .footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-end;
  height: 70px; }

.dashboard_container .top_flex .item_box .footer .box {
  padding: 5px;
  box-sizing: border-box;
  text-align: center; }

.dashboard_container .top_flex .item_box .footer .box .text {
  color: #AAAAAA;
  font-weight: 500;
  font-family: "Quicksand";
  font-size: 13px; }

.dashboard_container .top_flex .item_box .footer .box .count {
  color: #666666;
  font-weight: 500;
  font-family: "Quicksand";
  font-size: 13px;
  margin-top: 4px; }

.dashboard_container .graph_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 30px; }

.dashboard_container .graph_container .box_container {
  padding: 20px;
  box-shadow: 0px 0px 10px 2px rgba(50, 50, 50, 0.1);
  box-sizing: border-box; }

.package_boxsection {
  width: 95%;
  box-shadow: 0 0 5px 1px #dadada;
  border-radius: 5px;
  margin-bottom: 20px;
  font-family: Montserrat;
  justify-content: space-around; }
  .package_boxsection .leftbox {
    width: 70%;
    min-width: 280px;
    padding: 10px;
    box-sizing: border-box; }
    .package_boxsection .leftbox .image {
      height: 130px;
      width: 130px;
      border-radius: 5px;
      border: 1px solid #979797;
      margin: auto; }
    .package_boxsection .leftbox .coldet_box {
      min-width: 200px;
      width: calc(100% - 150px);
      margin: 10px auto;
      padding-left: 10px;
      box-sizing: border-box; }

.package {
  padding-top: 80px;
  overflow-x: hidden; }
  .package .welcome_container {
    width: 100%;
    height: 250px;
    background-image: -webkit-gradient(linear, left top, right top, from(#FF5722), to(#CE3407));
    background-image: -webkit-linear-gradient(left, #FF5722, #CE3407);
    background-image: linear-gradient(to right, #FF5722, #CE3407);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px; }
    .package .welcome_container .decor {
      width: 220px;
      height: 110px;
      position: absolute;
      top: 0px;
      background-repeat: no-repeat;
      background-size: 80%;
      background-position-y: 0;
      background-position-y: initial; }
      .package .welcome_container .decor.left {
        background-position-x: 0;
        background-position-x: initial;
        background-image: url(/static/media/decore-left.8d502445.png);
        left: 0px; }
      .package .welcome_container .decor.right {
        background-position-x: right;
        background-image: url(/static/media/decore-right.2d8a112b.png);
        right: 0px; }
    .package .welcome_container .box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      .package .welcome_container .box .circle {
        background-image: url(/static/media/badge.9f54380e.png);
        height: 100px;
        width: 100px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%; }
      .package .welcome_container .box .text {
        font-family: "Quicksand";
        color: #FFFFFF;
        font-weight: bold;
        font-size: 22px;
        margin-top: 10px; }
  .package .header {
    width: 100%;
    text-align: center;
    margin: 50px auto;
    font-family: "Montserrat";
    letter-spacing: 0.3px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
    .package .header span {
      font-weight: 600; }
  .package .body {
    width: 100%;
    display: flex;
    flex-direction: row; }
    @media screen and (max-width: 768px) {
      .package .body {
        flex-direction: column; } }
    .package .body .taskbar {
      width: 250px;
      height: 100%;
      padding: 20px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: "Montserrat";
      -webkit-transition: all 500ms;
      transition: all 500ms; }
      @media screen and (max-width: 768px) {
        .package .body .taskbar {
          margin: auto; } }
      .package .body .taskbar .header {
        color: #757575;
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
        display: flex;
        flex-direction: row;
        margin: 0px; }
        .package .body .taskbar .header .icon {
          height: 14px;
          width: 14px;
          background-image: url(/static/media/right-arrow.0ad5d6e7.svg);
          background-position: center;
          background-repeat: no-repeat;
          background-size: 100%;
          position: relative;
          top: 2px;
          left: 13px; }
      .package .body .taskbar .item_box {
        display: flex;
        flex-direction: column;
        padding: 20px 0px; }
        .package .body .taskbar .item_box .item {
          text-transform: uppercase;
          font-weight: 500;
          font-size: 15px;
          line-height: 30px; }
    .package .body .data_box {
      width: calc(100% - 250px);
      display: flex;
      flex-direction: column;
      padding: 20px;
      box-sizing: border-box; }
      @media screen and (max-width: 768px) {
        .package .body .data_box {
          width: 100%; } }
      .package .body .data_box .top_section {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap; }
        .package .body .data_box .top_section .box_container {
          display: flex;
          flex-direction: column;
          width: 30%;
          min-width: 200px;
          align-items: center;
          padding: 10px;
          box-sizing: border-box; }
          @media screen and (max-width: 963px) {
            .package .body .data_box .top_section .box_container {
              flex-direction: row;
              flex-wrap: wrap;
              width: 100%; } }
          .package .body .data_box .top_section .box_container .box {
            width: 100%;
            max-width: 220px;
            min-width: 200px;
            padding: 10px;
            box-sizing: border-box;
            background-color: #FFFFFF;
            border: 1px solid #AAAAAA;
            box-shadow: 0px 0px 10px 3px #cacaca;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            align-items: center;
            font-family: "Montserrat";
            text-transform: uppercase;
            margin-bottom: 35px; }
            @media screen and (max-width: 963px) {
              .package .body .data_box .top_section .box_container .box {
                margin-right: 50px; } }
            @media screen and (max-width: 963px) {
              .package .body .data_box .top_section .box_container .box:nth-last-child(1) {
                margin-right: 0px; } }
            .package .body .data_box .top_section .box_container .box .header {
              margin: 10px auto;
              font-size: 16px;
              font-weight: 500; }
            .package .body .data_box .top_section .box_container .box .content {
              line-height: 20px;
              margin: 10px 0px; }
            .package .body .data_box .top_section .box_container .box .button {
              margin: 10px auto;
              background-color: #FF5722;
              border-radius: 100px;
              color: #FFFFFF;
              padding: 7px 15px;
              font-size: 12px;
              font-weight: 600; }
            .package .body .data_box .top_section .box_container .box.second .icon {
              height: 60px;
              width: 60px;
              margin: 5px auto;
              background-image: url(/static/media/engineerICON.48353e2b.svg);
              background-position: center;
              background-repeat: no-repeat;
              background-size: 100%; }
            .package .body .data_box .top_section .box_container .box.second .container {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              width: 100%;
              margin: 10px 0px;
              align-items: center; }
              .package .body .data_box .top_section .box_container .box.second .container .text {
                text-transform: none;
                font-size: 12px;
                width: 70%;
                font-weight: 500; }
              .package .body .data_box .top_section .box_container .box.second .container .checkbox {
                display: none; }
              .package .body .data_box .top_section .box_container .box.second .container .checkbox + label {
                font-size: 13px;
                -webkit-user-select: none;
                   -moz-user-select: none;
                    -ms-user-select: none;
                        user-select: none;
                cursor: pointer; }
              .package .body .data_box .top_section .box_container .box.second .container .checkbox + label:before {
                content: "\2714";
                border-radius: 0.2em;
                display: inline-block;
                height: 7px;
                width: 7px;
                padding: 0px 6px 10px 4px;
                margin-right: 0.7em;
                vertical-align: bottom;
                color: transparent;
                -webkit-transition: .2s;
                transition: .2s;
                background-color: #D8D8D8; }
              .package .body .data_box .top_section .box_container .box.second .container .checkbox + label:active:before {
                -webkit-transform: scale(0);
                        transform: scale(0); }
              .package .body .data_box .top_section .box_container .box.second .container .checkbox:checked + label {
                color: #FF5722;
                -webkit-transition: color 0.2s;
                transition: color 0.2s; }
              .package .body .data_box .top_section .box_container .box.second .container .checkbox:checked + label:before {
                background-color: #FF5722;
                border-color: #FF5722;
                color: #fff;
                box-shadow: 0px 0px 0px 0px #dadada; }
              .package .body .data_box .top_section .box_container .box.second .container .checkbox:disabled + label:before {
                -webkit-transform: scale(1);
                        transform: scale(1);
                border-color: #aaa; }
              .package .body .data_box .top_section .box_container .box.second .container .checkbox:checked:disabled + label:before {
                -webkit-transform: scale(1);
                        transform: scale(1);
                background-color: #bfb;
                border-color: #bfb; }
      .package .body .data_box .add_extra_products_container {
        margin: 30px 0px;
        font-family: "Montserrat";
        font-weight: 500;
        font-size: 16px; }
        .package .body .data_box .add_extra_products_container .heading {
          width: 100%;
          text-transform: uppercase; }
        .package .body .data_box .add_extra_products_container .data_box_container {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding-top: 30px;
          padding-right: 30px;
          box-sizing: border-box;
          flex-wrap: wrap; }
          .package .body .data_box .add_extra_products_container .data_box_container .data_box {
            min-width: 400px;
            width: 45%;
            height: 300px;
            margin-bottom: 50px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 5px;
            box-sizing: border-box; }
            .package .body .data_box .add_extra_products_container .data_box_container .data_box:nth-last-child(1), .package .body .data_box .add_extra_products_container .data_box_container .data_box:nth-last-child(2) {
              margin-bottom: 0px; }
            .package .body .data_box .add_extra_products_container .data_box_container .data_box .image {
              height: 80px;
              width: 80px;
              background-color: #D8D8D8;
              border-radius: 2px;
              border: 1px solid #cacaca; }
            .package .body .data_box .add_extra_products_container .data_box_container .data_box .details_box {
              display: flex;
              flex-direction: column;
              width: calc(100% - 100px);
              margin-left: 8px; }
              .package .body .data_box .add_extra_products_container .data_box_container .data_box .details_box .heading_box {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center; }
                .package .body .data_box .add_extra_products_container .data_box_container .data_box .details_box .heading_box .content {
                  display: flex;
                  flex-direction: column;
                  padding: 0px 10px;
                  box-sizing: border-box;
                  font-weight: 400;
                  color: #333333; }
                  .package .body .data_box .add_extra_products_container .data_box_container .data_box .details_box .heading_box .content .main {
                    text-transform: uppercase;
                    font-size: 15px;
                    margin: 5px 0px; }
                  .package .body .data_box .add_extra_products_container .data_box_container .data_box .details_box .heading_box .content .sub {
                    font-size: 13px; }
                .package .body .data_box .add_extra_products_container .data_box_container .data_box .details_box .heading_box .count {
                  padding: 10px;
                  box-sizing: border-box;
                  text-align: center;
                  font-size: 17px; }
              .package .body .data_box .add_extra_products_container .data_box_container .data_box .details_box .varient_container {
                display: flex;
                flex-direction: column;
                padding: 5px;
                box-sizing: border-box; }
                .package .body .data_box .add_extra_products_container .data_box_container .data_box .details_box .varient_container .varient {
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  padding: 0px 5px;
                  box-sizing: border-box;
                  margin: 8px 0px; }
                  .package .body .data_box .add_extra_products_container .data_box_container .data_box .details_box .varient_container .varient.gap {
                    margin-top: 20px; }
                  .package .body .data_box .add_extra_products_container .data_box_container .data_box .details_box .varient_container .varient .name {
                    font-size: 15px; }
                  .package .body .data_box .add_extra_products_container .data_box_container .data_box .details_box .varient_container .varient .name_count {
                    font-size: 16px;
                    font-weight: 600;
                    color: #FF5722; }
                  .package .body .data_box .add_extra_products_container .data_box_container .data_box .details_box .varient_container .varient .action_box {
                    display: flex;
                    flex-direction: row;
                    align-items: center; }
                    .package .body .data_box .add_extra_products_container .data_box_container .data_box .details_box .varient_container .varient .action_box .btn {
                      width: 20px;
                      height: 20px;
                      line-height: 20px;
                      text-align: center;
                      color: #FFFFFF;
                      border-radius: 10px;
                      background-color: #FF5722;
                      margin: 0px 4px;
                      font-weight: 700; }
                    .package .body .data_box .add_extra_products_container .data_box_container .data_box .details_box .varient_container .varient .action_box .text {
                      text-transform: uppercase;
                      margin: 0px 5px;
                      font-size: 14px; }
                    .package .body .data_box .add_extra_products_container .data_box_container .data_box .details_box .varient_container .varient .action_box .total {
                      text-transform: uppercase;
                      margin: 0px 5px;
                      font-size: 16px;
                      color: #6B6C6F;
                      font-weight: 600; }
                    .package .body .data_box .add_extra_products_container .data_box_container .data_box .details_box .varient_container .varient .action_box .button {
                      color: #FF5722;
                      font-size: 13px;
                      font-weight: 500;
                      background-color: #FFFFFF;
                      border: 1px solid #FF5722;
                      padding: 2px 10px;
                      border-radius: 10px;
                      margin-left: 15px; }

.package_container {
  display: flex;
  flex-direction: row;
  padding: 10px;
  box-sizing: border-box;
  align-items: center; }
  .package_container .image {
    height: 120px;
    width: 120px;
    min-width: 120px;
    border-radius: 4px;
    background-color: #D8D8D8;
    border: 1px solid #979797; }
  .package_container .data_container {
    display: flex;
    flex-direction: column;
    padding: 0px 20px;
    box-sizing: border-box;
    margin-top: 5px; }
    .package_container .data_container .top_section {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between; }
      .package_container .data_container .top_section .button {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 5px 10px;
        box-sizing: border-box;
        background-color: #FF5722;
        border-radius: 2px;
        color: #FFFFFF;
        font-family: "Montserrat";
        font-size: 13px; }
        .package_container .data_container .top_section .button .icon {
          height: 15px;
          width: 15px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: 90%;
          background-image: url(/static/media/user.14f55162.svg);
          margin-right: 5px; }
      .package_container .data_container .top_section .text {
        font-family: "Montserrat";
        margin-left: 10px;
        font-weight: 500; }
    .package_container .data_container .content {
      line-height: 20px;
      font-family: "Montserrat";
      font-size: 13px;
      margin-top: 20px;
      color: #333333; }
    .package_container .data_container .button_box {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      font-family: "Montserrat";
      font-size: 13px;
      align-items: center;
      margin-top: 20px; }
      .package_container .data_container .button_box .sq_button {
        padding: 8px 18px;
        border: 1px solid #979797;
        border-radius: 2px;
        color: #333333;
        width: 115px;
        margin-right: 15px; }
      .package_container .data_container .button_box .rd_button {
        padding: 7px 18px;
        border-radius: 100px;
        background-color: #FF5722;
        color: #ffffff;
        margin: 15px 0px; }

.package_details {
  display: flex;
  flex-direction: column;
  width: 70%;
  max-width: 70%;
  margin: auto;
  min-width: 380px; }
  @media screen and (max-width: 768px) {
    .package_details {
      width: 95%;
      max-width: 95%;
      margin: auto; } }
  .package_details .package_include_section {
    display: flex;
    flex-direction: column; }
    .package_details .package_include_section .header {
      text-transform: uppercase;
      width: 100%;
      display: flex;
      align-items: flex-start;
      font-weight: 500; }
    .package_details .package_include_section .body_container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-right: 15px;
      box-sizing: border-box; }
      .package_details .package_include_section .body_container .box {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0px 0px 20px 0px; }
        .package_details .package_include_section .body_container .box .image {
          height: 60px;
          width: 60px;
          min-width: 60px;
          background-color: #D8D8D8;
          border: 1px solid #6B6C6F;
          border-radius: 2px; }
        .package_details .package_include_section .body_container .box .content {
          display: flex;
          flex-direction: column; }
          .package_details .package_include_section .body_container .box .content .text {
            font-family: "Montserrat";
            line-height: 20px;
            padding: 0px 7px;
            box-sizing: border-box;
            font-size: 13px; }
            .package_details .package_include_section .body_container .box .content .text.header {
              margin: 0px; }

.add_extra_products_container {
  margin: 30px 0px;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px; }
  .add_extra_products_container .heading {
    width: 100%;
    text-transform: uppercase; }
  .add_extra_products_container .data_box_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 30px;
    padding-right: 30px;
    box-sizing: border-box;
    flex-wrap: wrap; }
    .add_extra_products_container .data_box_container .data_box {
      min-width: 400px;
      width: 45%;
      height: 300px;
      margin-bottom: 50px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 5px;
      box-sizing: border-box; }
      .add_extra_products_container .data_box_container .data_box:nth-last-child(1), .add_extra_products_container .data_box_container .data_box:nth-last-child(2) {
        margin-bottom: 0px; }
      .add_extra_products_container .data_box_container .data_box .image {
        height: 80px;
        width: 80px;
        background-color: #D8D8D8;
        border-radius: 2px;
        border: 1px solid #cacaca; }
      .add_extra_products_container .data_box_container .data_box .details_box {
        display: flex;
        flex-direction: column;
        width: calc(100% - 100px);
        margin-left: 8px; }
        .add_extra_products_container .data_box_container .data_box .details_box .heading_box {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center; }
          .add_extra_products_container .data_box_container .data_box .details_box .heading_box .content {
            display: flex;
            flex-direction: column;
            padding: 0px 10px;
            box-sizing: border-box;
            font-weight: 400;
            color: #333333; }
            .add_extra_products_container .data_box_container .data_box .details_box .heading_box .content .main {
              text-transform: uppercase;
              font-size: 15px;
              margin: 5px 0px; }
            .add_extra_products_container .data_box_container .data_box .details_box .heading_box .content .sub {
              font-size: 13px; }
          .add_extra_products_container .data_box_container .data_box .details_box .heading_box .count {
            padding: 10px;
            box-sizing: border-box;
            text-align: center;
            font-size: 17px; }
        .add_extra_products_container .data_box_container .data_box .details_box .varient_container {
          display: flex;
          flex-direction: column;
          padding: 5px;
          box-sizing: border-box; }
          .add_extra_products_container .data_box_container .data_box .details_box .varient_container .varient {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 0px 5px;
            box-sizing: border-box;
            margin: 8px 0px; }
            .add_extra_products_container .data_box_container .data_box .details_box .varient_container .varient.gap {
              margin-top: 20px; }
            .add_extra_products_container .data_box_container .data_box .details_box .varient_container .varient .name {
              font-size: 15px; }
            .add_extra_products_container .data_box_container .data_box .details_box .varient_container .varient .name_count {
              font-size: 16px;
              font-weight: 600;
              color: #FF5722; }
            .add_extra_products_container .data_box_container .data_box .details_box .varient_container .varient .action_box {
              display: flex;
              flex-direction: row;
              align-items: center; }
              .add_extra_products_container .data_box_container .data_box .details_box .varient_container .varient .action_box .btn {
                width: 20px;
                height: 20px;
                line-height: 20px;
                text-align: center;
                color: #FFFFFF;
                border-radius: 10px;
                background-color: #FF5722;
                margin: 0px 4px;
                font-weight: 700; }
              .add_extra_products_container .data_box_container .data_box .details_box .varient_container .varient .action_box .text {
                text-transform: uppercase;
                margin: 0px 5px;
                font-size: 14px; }
              .add_extra_products_container .data_box_container .data_box .details_box .varient_container .varient .action_box .total {
                text-transform: uppercase;
                margin: 0px 5px;
                font-size: 16px;
                color: #6B6C6F;
                font-weight: 600; }
              .add_extra_products_container .data_box_container .data_box .details_box .varient_container .varient .action_box .button {
                color: #FF5722;
                font-size: 13px;
                font-weight: 500;
                background-color: #FFFFFF;
                border: 1px solid #FF5722;
                padding: 2px 10px;
                border-radius: 10px;
                margin-left: 15px; }

.varientme {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 5px;
  box-sizing: border-box;
  margin: 8px 0; }
  .varientme .name {
    font-size: 15px; }
  .varientme .action_box {
    display: flex;
    flex-direction: row;
    align-items: center; }
    .varientme .action_box .btn {
      width: 20px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      color: #FFFFFF;
      border-radius: 10px;
      background-color: #FF5722;
      margin: 0px 4px;
      font-weight: 700; }
    .varientme .action_box .text {
      text-transform: uppercase;
      margin: 0px 5px;
      font-size: 14px; }
    .varientme .action_box .total {
      text-transform: uppercase;
      margin: 0px 5px;
      font-size: 16px;
      color: #6B6C6F;
      font-weight: 600; }
    .varientme .action_box .button {
      color: #FF5722;
      font-size: 13px;
      font-weight: 500;
      background-color: #FFFFFF;
      border: 1px solid #FF5722;
      padding: 2px 10px;
      border-radius: 10px;
      margin-left: 15px; }

.mybtn {
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  color: #fff;
  border-radius: 10px;
  background-color: #ff5722;
  margin: 0 4px;
  font-weight: 700;
  cursor: pointer; }


@import url('https://fonts.googleapis.com/css?family=Montserrat+Alternates:400,500,700|Montserrat:400,500,600,700|Quicksand:400,500,700|Roboto:300,400,500,700');

* {
  outline: none;
  font-family: "Montserrat";
  user-select: none;
}

::-webkit-scrollbar-track {
  background-color: #ffffff;
  border-radius: 10px;
}

::-webkit-scrollbar {
  border-radius: 10px;
  height: 6px;
  width: 6px;
  background-color: #ffffff;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #919191;
}

::selection {
  background-color: #FF5722;
  color: #FFFFFF;
}

body {
  background-color: #FFFFFF;
  margin: 0px;
  padding: 0px;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-col-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.button-round {
  background-color: #FF5722;
  border-radius: 200px;
  padding: 7px 15px;
  outline: none;
  border: 1px solid #FF5722;
  color: #FFFFFF;
  font-size: 13px;
  cursor: pointer;
  transition: all 300ms;

  &:hover {
    background-color: #FFFFFF;
    color: #FF5722;
    transition: all 300ms;
  }

  &.inverted {
    border: 1px solid #FF5722;
    color: #FF5722;
    background-color: #FFFFFF;
    border-radius: 200px;
    font-weight: 600;
    font-size: 10px;
    padding: 3px 12px;
    transition: all 200ms;
    letter-spacing: 0.3px;
    height: fit-content;

    &:hover {
      color: #FFFFFF;        
      background-color: #FF5722;
      transition: all 200ms;
    }
  }
}
//-----------------------------------------------------


@mixin small-margin($ver, $hor: false) {
  @if $hor {
    @media screen and (max-width: 450px) {
      margin: $ver $hor;
    }
  }
  @else {
    @media screen and (max-width: 450px) {
      margin: $ver;
    }
  }
}

@mixin small-width($width) {
  @media screen and (max-width: 450px) {
    width: $width;
  }
}

@mixin responsify($min, $max: false) {
  @if $max {
    @media #{ $media} and (min-width: $min) and (max-width: $max) {
      @content;
    }
  }
  @else {
    @media #{$media} and (min-width: $min) {
      @content;
    }
  }
}


//-----------------------------------------------------

.top-nav-bar {
  height: 80px;
  width: 100%;
  justify-content: space-between;
  padding: 0px 20px;
  box-sizing: border-box;
  // box-shadow: 0px 0px 4px 1px #dadada;
  border-bottom: 1px solid #aaaaaa;
}

.logo-container {
  .logo {
    height: 50px;
    width: 50px;
    background-image: url(../../backassets/logo.png);
  }

  .logo-text {
    font-family: "Quicksand";
   font-weight: 500;
   font-size: 15px;
   margin-left: 10px;
   letter-spacing: 0.4px;
  }
}

.body-section {
  width: 100%;
  height: calc(100vh - 80px);
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
}

.taskbar {
  transition: all 400ms;
  margin: 0px auto;
  width: 25%;
  min-width: 280px;
  // height: 100%;
  align-items: center;
  overflow-y: scroll;
  padding: 10px;
  box-sizing: border-box;

  @media screen and (max-width: 600px) {
    width: 90%;
  }
  
  .item {
    width: 70%;
    margin-bottom: 5px;
    padding: 10px 10px;
    box-sizing: border-box;
    align-items: center;
    cursor: pointer;
    border-radius: 4px;

    &:nth-child(1) {
      margin-top: 15px;
    }

    &.active {
      background-color: #eaeaea;
    }

    &:hover {
      background-color: #eaeaea;
    }

    .icon {
      // background-image: url(../backassets/dashboard.svg);
      height: 16px;
      width: 16px;
      margin-right: 10px;
    }

    .text {
      font-family: "Quicksand";
      font-weight: 500;
      font-size: 13px;
      letter-spacing: 0.2px;
    }
  }
}

.body-container {
  transition: all 400ms;
  height: 100%;
  width: 75%;
  margin: auto;
  padding: 20px;
  box-sizing: border-box;

  @media screen and (max-width: 600px) {
    width: 100%;
    padding: 15px;
  }

  @media screen and (max-width: 1000px) and (min-width: 601px) {
    width: 100%;
    padding: 20px;
  }
}

.table-container {
  // min-height: calc(100% - 2px);
  width: 100%;
  border: 1px solid #aaaaaa;
  border-radius: 4px;
  background-color: #FFFFFF;
  overflow-x: scroll;
  margin-bottom: 20px;

  @media screen and (max-width: 450px) {
    &::-webkit-scrollbar-track {
      background-color: #ffffff;
      border-radius: 10px;
    }
    
    &::-webkit-scrollbar {
      border-radius: 10px;
      height: 6px;
      width: 3px;
      background-color: #ffffff;
    }
    
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
      background-color: #919191;
    }
  }

  .header-container {
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    padding: 10px 20px;
    box-sizing: border-box;
    height: fit-content;
    min-height: 70px;

    .text {
      text-transform: uppercase;
      font-weight: 500;
      font-size: 14px;
    }

    .button-round {
      font-weight: 500;
    }
  }

  .user-list-container {
    width: 100%;
    border-top: 1px solid #aaaaaa;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 50px;
    box-sizing: border-box;

    @media screen and (max-width: 450px) {
      padding: 10px 10px;
    }

    .name-section {
      display: flex;
      flex-direction: row;
      align-items: center;

      .icon {
        height: 30px;
        width: 30px;
        background-color: #d8d8d8;
        border-radius: 50%;
        margin: auto;
        margin-right: 10px;
      }

      .text {
        font-size: 12px;
        font-weight: 500;
        word-break: break-all;
        margin: auto;
      }
    }

    .action-container {
      display: flex;
      flex-direction: row;

      .button {
        text-align: center;
        padding: 5px 10px;
        font-size: 12px;
        font-weight: 500;
        margin-right: 10px;

        &:nth-last-child(1) {
          margin-right: 0px;
        }
      }
    }
  }
}

.main-table {
  width: 100%;
  border-collapse: collapse;

  .heading {
    border-top: 1px solid #aaaaaa;
    border-bottom: 1px solid #aaaaaa;

    td {
      padding: 10px 5px;
      box-sizing: border-box;
      font-size: 13px;
      text-align: center;
      font-weight: 500;
    }
  }

  .content {
    border-bottom: 1px solid #aaaaaa;

    &:nth-last-child(1) {
      border-bottom: 0px solid transparent;
    }

    .package-name-click {
      cursor: pointer;

      &:hover {
        background-color: #fafafa;
      }
    }

    td {
      padding: 20px 10px;
      box-sizing: border-box;
      font-size: 13px;
      font-weight: 500;
      text-align: center;

      &.action-box {
        justify-content: space-around;
        flex-wrap: wrap;

        .button-round {
          padding: 5px 20px;
          margin-bottom: 5px;
        }
      }

      &.proof-box {
        text-align: center;
        position: relative;
      }
      
      .proof {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 30px;
        width: 40px;
        border-radius: 8px;
        background-color: #d8d8d8;
        border: 1px solid #999999;
      }
    }
  }

  .package-container-div {
    
    width: 100%;
    // background-color: yellow;
    
    a {
      text-decoration: none;
      color: black;
    }

    .remove-button {
      margin: 10px auto;
    }

    .cloumn-div {
      display: flex;
      flex-direction: column;
    }

    .inner-container {
      padding: 10px;
      box-sizing: border-box;
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      border-bottom: 1px solid #aaaaaa;

      .count {
        background-color: #FF5722;
        color: #FFFFFF;
        padding: 5px 5px;
        height: 15px;
        width: fit-content;
        font-size: 11px;
        font-weight: 700;
        border-radius: 100px;
        transform: scale(0.8);
        min-width: 14px;
        text-align: center;
        margin: 0px 10px;
        margin-top: 10px;
      }
    }
  }
}

.package-table {
  margin: auto;

  .content {

    .product-image {
      height: 50px;
      width: 50px;
      border-radius: 5px;
      border: 1px solid #aaaaaa;
      background-color: #d8d8d8;
      margin: auto;
    }

    .package-name {
      font-weight: 600;
      font-size: 12px;
      margin: auto;
    }

    .description {
      font-weight: 300;
      max-width: 250px;
      // text-align: justify;
      word-break: break-all;
      font-size: 12px;
      font-family: "Roboto";
      color: #333333;
      letter-spacing: 0.2px;
      line-height: 18px;
    }
  }
}

.products-section {
  width: 100%;
  padding: 20px 50px;
  box-sizing: border-box;
  border-bottom: 1px solid #aaaaaa;

  @media screen and (max-width: 450px) {
    padding: 20px 10px;
  }

  .heading-section {
    width: 100%;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    .heading_text {
      font-weight: 500;
      font-size: 13px;
      @include small-margin(10px, auto);
    }    
  }

  .product-list-section {
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.search-section {
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  @include small-margin(auto);

  .input {
    border: 1px solid #FF5722;
    border-radius: 200px;
    font-size: 12px;
    font-family: "Quicksand";
    letter-spacing: 0.5px;
    padding: 5px 55px 5px 15px;
    min-width: 300px;
    font-weight: 500;

    @media screen and (max-width: 450px) {
      margin-bottom: 10px;
    }

    &::placeholder {
      color: #555555;
      font-size: 12px;
      font-weight: 500;
    }
  }

  .add_button {
    color: #FFFFFF;
    background-color: #FF5722;
    font-weight: 600;
    font-size: 11px;
    border-radius: 200px;
    position: absolute;
    right: 2px;
    top: 2px;
    padding: 4.5px 0px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}

.product_card {
  flex-wrap: wrap;
  background-color: #FFFFFF;
  @include small-margin(10px, auto);
  @include small-width(95%);
  padding: 10px;
  box-sizing: border-box;
  // margin: 20px 0px 0px 0px;
  margin: 50px auto 0px auto;
  max-width: 45%;
  min-width: 320px;
  height: fit-content;
  font-size: 12px;
  font-weight: 500;

  .image {
    height: 80px;
    width: 80px;
    @include small-margin(20px, auto);
    background-color: #d8d8d8;
    border-radius: 3px;
    border: 1px solid #999999;
    margin: auto;
  }

  .top-section {
    align-items: center;
    justify-content: space-between;
    // background-color: yellow;

    .text {
      word-break: break-all;
      font-size: 12px;
      line-height: 19px;
      padding-right: 15px;
      box-sizing: border-box;
      text-transform: uppercase;
    }

    .remove-button {
      outline: none;
      cursor: pointer;
      transition: all 300ms;
      border: 1px solid #FF5722;
      color: #FF5722;
      background-color: #FFFFFF;
      border-radius: 200px;
      font-weight: 600;
      font-size: 10px;
      padding: 3px 12px;
      transition: all 200ms;
      letter-spacing: 0.3px;
      height: fit-content;
      margin-right: 0px;
  
      &:hover {
        color: #FFFFFF;        
        background-color: #FF5722;
        transition: all 200ms;
      }
    }
  }

  .description {
    color: #999999;
    line-height: 18px;
    margin-bottom: 10px;
    text-align: justify;
  }

  .bottom-section {
    align-items: center;

    .text {
      text-transform: uppercase;
      margin-right: 5px;
    }

    .price {
      font-size: 13px;
      font-weight: 600;
      margin-left: 10px;
    }

    .selection-box {
      align-items: center;
      padding: 1px 5px;
      background-color: #d8d8d8;
      border: 1px solid #999999;
      border-radius: 2px;
      font-size: 10px;
      cursor: pointer;
      margin-left: 5px;

      .count {
        margin-right: 3px;
      }

      .icon {
        height: 5px;
        width: 5px;
        background-image: url(../../backassets/down-arrow.svg);
      }
    }
  }
}


.example-appear {
  opacity: 0.01;
}

.example-appear.example-appear-active {
  opacity: 1;
  transition: opacity .5s ease-in;
}

.modalmain {
  display: flex;
  flex-direction: 'row';
  justify-content: space-between
}

.input_text {
  padding: 5px 10px;
  font-size: 14px;
  border: 0px;
  box-shadow: 0px 0px 2px 1px #dadada;
  font-family: "Roboto";
  letter-spacing: 0.2px;
  border-radius: 2px;
}
.add_image_box {
  height: 60px;
  width: 50px;
  border-radius: 5px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px 0 0 9px;
  box-sizing: border-box;
  cursor: pointer;
  -webkit-transition: box-shadow .2s;
  transition: box-shadow .2s;
  border: 1px dotted #aaa;
}
.add_button {
  height: 30px;
  width: 30px;
  background-color: #ff5722;
  border-radius: 50%;
  background-image: url(/src/assets/add-icon.svg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 50%;
}

.add_button_image_img {
  box-sizing: border-box;
  border-radius: 20px;
  height: 30px;
  width: 30px;
  background: url(/src/assets/add-icon.svg);
  padding: 30px 0 0;
}

.flip-box {
  background-color: transparent;
  width: 80px;
  height: 60px;
  margin-left: 10px;
  -webkit-perspective: 1000px;
  perspective: 1000px;
}
.flip-box-inner {
  position: relative;
  width: 80px;
  height: 60px;
  text-align: center;
  -webkit-transition: -webkit-transform .8s;
  transition: -webkit-transform .8s;
  transition: transform .8s;
  transition: transform .8s,-webkit-transform .8s;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.flip-box-front {
  position: absolute;
  width: 80px;
  height: 60px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-box-back {
  background-color: #555;
  border-radius: 10px;
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.mytop_flex .item_box {
  display: flex;
  flex-direction: column;
  min-width: 310px;
  min-height: 200px;
  background-color: #fff;
  border-radius: 1px;
  box-shadow: 0 0 5px 1px rgba(20,20,20,.2);
  padding: 7px;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.mytop_flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.mytop_flex .item_box .header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-family: Quicksand;
  color: #888;
  font-size: 15px;
  height: 30px;
  align-items: center;
  padding-right: 10px;
  box-sizing: border-box;
  font-weight: 500;
}

.mytop_flex .item_box .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 90px;
}

.mytop_flex .item_box .footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-end;
  height: 70px;
}

.mydashboard_container {
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: column;
}

* {
  outline: none;
  font-family: Quicksand;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.mytop_flex .item_box .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 90px;
}
.mytop_flex .item_box .content .text {
  color: #aaa;
  font-family: Quicksand;
  font-size: 13px;
  font-weight: 500;
}

.mytop_flex .item_box .content .count {
  color: #333;
  font-family: Quicksand;
  font-size: 42px;
  margin-bottom: 5px;
}

.mytop_flex .item_box .footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-end;
  height: 70px;
}

.mytop_flex .item_box .footer .box {
  padding: 5px;
  box-sizing: border-box;
  text-align: center;
}

.mytop_flex .item_box .footer .box .text {
  color: #aaa;
  font-weight: 500;
  font-family: Quicksand;
  font-size: 13px;
}
.mytop_flex .item_box .footer .box .count {
  color: #666;
  font-weight: 500;
  font-family: Quicksand;
  font-size: 13px;
  margin-top: 4px;
}